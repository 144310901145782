import { ReactElement } from "react"
import { useParams } from "react-router";
import { app } from "..";
import { Category } from "../models/Category";
import { ExchangeForm } from "../pages/post/ExchangeForm";
import { exchangeRequests } from "../requests/exchangeRequests";
import Loading from "react-loading";
import { useQuery } from "@tanstack/react-query";
import { exchangeKeys } from "../queryKeys/exchangeKeys";

export interface ExchangeLoaderProps {
    categories: Category[];
}

export const ExchangeLoader = (props: ExchangeLoaderProps): ReactElement => {
    const { exchangeid } = useParams();
    const { GetExchangeById } = exchangeRequests(app.apiBasePath);

    const { data: exchange, isLoading } = useQuery({
        queryKey: exchangeKeys.getExchangeById(exchangeid),
        queryFn: () => GetExchangeById(exchangeid)
    });

    if (isLoading) {
        return <div style={{ padding: "20px" }}><Loading /></div>
    }

    return <ExchangeForm {...exchange} categories={props.categories} />
}