
import { create } from "zustand";
import { Dictionary } from "../interfaces/Dictionary";

export interface GroupPageStore {
    personFilter: Dictionary<Array<string>>;
    addToPersonFilter: (key: string, value: Array<string>) => void;
    
}

export const groupPageStore = create<GroupPageStore>((set) => ({
    personFilter: {},
    addToPersonFilter: (key, value) => set((state) => ({ personFilter: { ...state.personFilter, [key]: value } }))
}));