import React, { FC, ReactElement, useContext, useEffect, useState } from "react";
import { NotificationView, NotificationType } from "../../components/NotificationView";
import { IsNullOrWhiteSpace, IsUndefinedOrNull } from "../../misc/Utilities";
import classNames from "classnames";
import { LocalizationContext } from "../../interfaces/AppContext";
import { useNavigate, useParams } from "react-router";
import style from "./usermanage.module.scss";
import axios from "axios";
import { app } from "../..";
import Loading from "react-loading";
import { groupRequests } from "../../requests/groupRequests";
import { useQuery } from "@tanstack/react-query";
import { groupKeys } from "../../queryKeys/groupKeys";


export const UserManage: FC = (): ReactElement => {
    const params = useParams();
    const { userid } = params;

    const { GetGroupUser } = groupRequests(app.apiBasePath);
    const { localization } = useContext(LocalizationContext);
    const [banEnabled, setBanEnabled] = useState(false);
    const [working, setWorking] = useState(false);
    const [text, setText] = useState("");

    const [showConfirm, setShowConfirm] = useState(false);
    const [hide, setHide] = useState(false);

    const { groupid } = useParams();

    const nav = useNavigate();

    const { data: user, isLoading } = useQuery({
        queryFn: () => GetGroupUser(groupid, userid),
        queryKey: groupKeys.groupUser(groupid, userid),
        enabled: !IsNullOrWhiteSpace(userid)
    });

    useEffect(() => {
        if (showConfirm) {
            window.setTimeout(() => {
                nav(-1);
            }, 2080);
            setHide(true);
        }
    }, [showConfirm]);

    if (IsUndefinedOrNull(user)) {
        return <div className={style.container}>
            <NotificationView type={NotificationType.Error}>
                <span>{localization["userNotFound"]}</span>
            </NotificationView>
        </div>
    }
    const requestUserExpel = (): void => {
        setWorking(true);

        axios.post(`${app.apiBasePath}/groupadmin/${groupid}/expel`, {
            personId: user.personId,
            groupid: groupid,
            reason: text
        }).then(result => {
            nav(-1);
        });
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className={style.container}>
            <div className={style.confirmation} style={{ margin: "20px 0" }}>
                <NotificationView type={NotificationType.Warning}>
                    <p>Développement de fonctionnalités en cours</p>
                </NotificationView>
            </div>

            <h3>{user.lastName} {user.firstName} ({user.displayName})</h3>
            <div className={style.text}>
                <p>{localization["expelUserDescription"]}</p>
            </div>

            {
                banEnabled === false &&
                showConfirm === false &&
                <button className="btn cancel" onClick={() => setBanEnabled(true)}>{localization["expelFromGroup"]}</button>
            }

            {
                banEnabled &&
                <React.Fragment>
                    <div className={classNames(style.option, style.note)}>
                        <label>Justification</label>
                        <textarea
                            readOnly={working}
                            value={text}
                            onChange={e => setText(e.target.value)}
                            className={"form-control"} />
                    </div>
                    {
                        !working &&
                        <div className={style.buttons}>

                            <button onClick={() => nav(-1)} className="btn cancel">{localization["cancel"]}</button>
                            <button onClick={requestUserExpel} className="btn action" disabled={IsNullOrWhiteSpace(text)}>{localization["submit"]}</button>
                        </div>
                    }
                    {
                        working &&
                        <Loading />
                    }
                </React.Fragment>
            }
            {
                showConfirm &&
                <div className={style.confirmation}>
                    <NotificationView type={NotificationType.Info}>
                        <p>Done</p>
                    </NotificationView>
                    <div className={classNames(style.bar, hide ? style.collapse : null)}></div>
                </div>
            }

        </div>
    );
}