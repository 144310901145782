
import * as axios from 'axios';

import { FC, ReactElement, useContext, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Loading from 'react-loading';
import { IsNullOrWhiteSpace } from '../../misc/Utilities';
import { LocalizationContext } from '../../interfaces/AppContext';
import { NotificationType, NotificationView } from '../../components/NotificationView';
import { app } from '../..';
import '../../styles/responsive/form.css';
import style from "./login.module.scss";
import { PasswordField } from '../../components/PasswordField/PasswordField';


type PwdType = "password" | "text";

export interface LoginProps {
    redirectTo?: string;
}

export const Login: FC<LoginProps> = ({ redirectTo }): ReactElement => {

    const [username, setUsername] = useState("");
    const [pwd, setPwd] = useState("");
    const [error, setError] = useState("");
    const [errorCode, setErrorCode] = useState<string>(null);
    const [trying, setTrying] = useState(false);

    const { localization: v } = useContext(LocalizationContext);

    const [redirecting, setRedirecting] = useState(false);

    const onLoginClick = (): void => {
        setTrying(true);

        let result = axios.default.post(`${app.apiBasePath}/auth/login`, {
            username: username,
            password: pwd
        });

        result.then(d => {

            setTrying(false);

            if (!IsNullOrWhiteSpace(d.data.error)) {
                if (d.data.error === "login-fail") {
                    setError(v["AuthFailureMsg"]);
                }
                else if (d.data.error === "email-confirm") {
                    setError(v["EmailConfirmRequired"]);
                }
                if (!IsNullOrWhiteSpace(d.data.code)) {
                    setErrorCode(d.data.code);
                }
                else {
                    setErrorCode(null);
                }

            }
            else {
                setRedirecting(true);
                setTimeout(() => window.location.href = redirectTo, 300);
            }
        });

        result.catch(e => {
            setTrying(false);
            console.warn(e);
        });

    };

    const onEnterClick = (): void => {
        if (username.length > 0 && pwd.length > 0) {
            onLoginClick();
        }
    }


    let loginDisabled = IsNullOrWhiteSpace(pwd) || IsNullOrWhiteSpace(username);

    if (redirecting) {
        return (
            <div className="page-content">
                <div className={style.ok}>
                    <Loading />
                </div>
            </div>
        );
    }

    return (
        <div className="page-content">
            <div className={classNames("form", style.login)}>

                <div className={style.registerrow}>
                    <Link className={classNames("btn", "signup", style.signupbtn)} to="/bienvenue">{v["NewAccount"]}</Link>
                </div>


                <form>

                    <div className="row">
                        <label>{v["UserName"]}</label>
                        <input
                            autoComplete="username"
                            disabled={trying}
                            onKeyDown={() => onEnterClick()}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            type="text"
                            className="form-control" />
                    </div>

                    <div className="row">
                        <div className={style.labelhelp}>
                            <label>{v["Password"]}</label>
                            <Link to="/resetpwd">{v["ForgotPwd"]}</Link>
                        </div>

                        <PasswordField
                            value={pwd}
                            onChange={p => setPwd(p)}
                            disabled={trying}
                            onEnterKeyPress={() => onEnterClick()}

                        />
                    </div>

                    <div className={style.loginrow}>
                        {
                            trying ? (<Loading />) : (
                                <button disabled={loginDisabled} className={classNames("btn", "action", style.loginbtn)} onClick={() => onLoginClick()}>
                                    <span>{v["LoginButton"]}</span>
                                </button>
                            )
                        }
                    </div>
                    {
                        error !== '' &&
                        <div className={style.warning}>
                            <NotificationView type={NotificationType.Warning}>
                                <p>{error}</p>
                                {
                                    errorCode !== null &&
                                    <p style={{ marginLeft: "15px", color: "#D81A59" }}>{v[errorCode]}</p>
                                }
                            </NotificationView>
                        </div>
                    }
                </form>
            </div>
        </div >
    );

}

