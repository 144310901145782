import { FC, ReactElement, useContext, useEffect } from "react";
import style from './info.module.scss';
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiRequests } from "../../../requests/apiRequests";
import { app } from "../../..";
import { apiKeys } from "../../../queryKeys/apiKeys";
import { GroupPageContext } from "../GroupPage";
import { useOutletContext } from "react-router";
import { GroupPost } from "../../../models/GroupPost";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { IsUndefinedOrNull, StringIsNullOrEmpty } from "../../../misc/Utilities";
import { PersonWidgetLight } from "../../../components/PersonWidget/PersonWidgetLight";
import moment from "moment";
import { LocalizationContext } from "../../../interfaces/AppContext";
import { isArray } from "lodash";
import { notificationStore } from "../../../store/notificationStore";

export const InfoPage: FC = (): ReactElement => {

    const { exchanges, groupInfo, people }: GroupPageContext = useOutletContext();

    const { GetGroupMessages, LogGroupInfoView } = apiRequests(app.apiBasePath);



    const queryClient = useQueryClient();

    const { localization: v } = useContext(LocalizationContext);

    const { data: messages, isLoading } = useQuery({
        queryFn: () => GetGroupMessages(groupInfo.id),
        queryKey: apiKeys.groupPosts(groupInfo.id)
    })

    const displayDateOrDurationFromNow = (dateValue: Date): string => {
        if (moment().diff(dateValue, 'hours') > 24) {
            return moment(dateValue).format("MMM DD, HH:mm");
        }
        else {
            return moment(dateValue).fromNow();
        }
    }


    useEffect(() => {
        if (!isLoading && isArray(messages)) {
            LogGroupInfoView(groupInfo.id).then(() => {
                queryClient.invalidateQueries({
                    queryKey: apiKeys.groupInfoNotifications
                });
            });
        }
    }, [messages, isLoading]);

    return (
        <div className={style.page}>
            <div className={style.messages}>
                {
                    !isLoading &&
                    messages.map((gp: GroupPost) => {

                        const hasAvatar = !StringIsNullOrEmpty(gp.publishedBy?.avatarFileId);

                        return <div key={gp.id} className={style.message}>
                            <h1>{gp.title}</h1>
                            <div className={style.msgheader}>
                                {
                                    hasAvatar ?
                                        <PersonWidgetLight
                                            size={40}
                                            avatarFileName={gp.publishedBy.avatarFileId}
                                            name={gp.publishedBy.displayName}
                                        /> :
                                        <SentimentSatisfiedAltIcon style={{ fontSize: '3em' }} />

                                }
                                <div>
                                    <p>{gp.publishedBy?.displayName}</p>
                                    <i>{displayDateOrDurationFromNow(gp.dateCreated)}</i>
                                    {
                                        !IsUndefinedOrNull(gp.dateUpdated) &&
                                        <i className={style.u}>({v["DateUpdated"].toLowerCase()} {displayDateOrDurationFromNow(gp.dateUpdated)})</i>
                                    }
                                </div>
                            </div>

                            <div className={style.messagebody} dangerouslySetInnerHTML={{ __html: gp.content }}></div>
                        </div>
                    })
                }
            </div>
        </div>
    );
}