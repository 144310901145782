import classNames from "classnames";
import { FC, ReactElement, useContext, useEffect, useRef, useState } from "react";
import { LocalizationContext } from "../../../interfaces/AppContext";
import { Checkbox } from "../../../components/Checkbox";
import { IsNullOrWhiteSpace, isValidEmailAddress, StringIsNullOrEmpty } from "../../../misc/Utilities";
import { app } from "../../..";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { GroupList } from "../../../components/GroupList/GroupList";
import { GroupPath } from "../../../models/GroupPath";
import { AutocompleteGroup } from "../../../components/Autocomplete/Groups/AutocompleteGroup";
import { GroupInfo } from "../../../models/JoinedGroup";
import { generate } from "generate-password-browser";
import RefreshIcon from '@mui/icons-material/Refresh';
import { OptionType } from "../../../models/OptionType";
import { apiRequests } from "../../../requests/apiRequests";
import { apiKeys } from "../../../queryKeys/apiKeys";
import Select from "react-select";
import { CreateUserModel } from "../../../models/CreateUserModel";
import { adminRequests } from "../../../requests/adminRequests";
import { AdminCreateUserResult } from "../../../models/AdminCreateUserResult";
import style from "./usermanage.module.scss";


export type ValidatorType = "email" | "firstName";
export type ValidatorFn = (value: string) => boolean;
export type Validator = {
    type: ValidatorType;
    valid: null | boolean;
    fn: ValidatorFn;
    msg?: string;
};

export const UserForm: FC = (): ReactElement => {

    const { localization } = useContext(LocalizationContext);

    const [isUpdating, setIsUpdating] = useState(false);
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [countryCode, setCountryCode] = useState<OptionType>(null);


    const [hasUserManage, setHasUserManage] = useState(false);
    const [hasGroupManage, setHasGroupManage] = useState(false);
    const [hasCategoryManage, setHasCategoryManage] = useState(false);
    const [qa, setQA] = useState(false);
    const [vocab, setVocab] = useState(false);
    const [groupSearchKey, setGroupSearchKey] = useState<string>("initial-static");
    const [groups, setGroups] = useState<GroupInfo[]>([]);

    const { GetCountries } = apiRequests(app.apiBasePath);
    const { CreateUser } = adminRequests(app.apiBasePath);

    const pwdRef = useRef();
    const nav = useNavigate();

    const { data: countries, isLoading: countriesLoading } = useQuery({
        queryFn: GetCountries,
        queryKey: apiKeys.countries,
    });

    const contryOptions: OptionType[] = countriesLoading ? [] : countries.map(c => {
        return {
            value: c.code,
            label: c.name
        };
    });

    const validatorsOptions: Validator[] = [
        {
            type: "email",
            valid: false,
            fn: (value: string): boolean => isValidEmailAddress(value)
        },
        {
            type: "firstName",
            valid: false,
            fn: (value: string): boolean => !IsNullOrWhiteSpace(value)
        }
    ];

    const [validators, setValidators] = useState(validatorsOptions);


    const runValidator = (value: string, validator: ValidatorType): void => {

        const m = validators.map(v => {
            if (v.type === validator) {
                v.valid = v.fn(value);
                v.msg = "";
            }
            return v;
        });

        setValidators(old => {
            return [...m];
        });
    }

    useEffect(() => {
        generateNewPassword();
    }, []);


    const generateNewPassword = (): void => {
        const pwd = generate({
            length: 9,
            numbers: true,
            strict: true
        });
        setPassword(pwd);
    };


    const onPwdFiledClick = (): void => {
        const html = pwdRef.current as HTMLInputElement;
        html?.select();
    };

    const submitUserCreate = (): void => {
        setIsUpdating(true);

        const model: CreateUserModel = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            countryCode: countryCode.value as string,
            joinGroups: groups.map(p => p.id),
            assignRights: []
        };

        hasUserManage && model.assignRights.push("user-management");
        hasGroupManage && model.assignRights.push("group-management");
        hasCategoryManage && model.assignRights.push("category-management");
        qa && model.assignRights.push("tester");
        vocab && model.assignRights.push("vocabulary");

        CreateUser(model).then((result: AdminCreateUserResult) => {
            setIsUpdating(false);
            if (!StringIsNullOrEmpty(result.personId)) {        
                nav("/admin/users");
            }
            else if (!StringIsNullOrEmpty(result.errorCode)) {   
                switch (result.errorCode) {
                    case "email-match":

                        setValidators(vals => {
                            const v = vals.map(p => {
                                if (p.type === "email") {
                                    p.valid = false;
                                    p.msg = localization["emailActiveAlready"]
                                }
                                return p;
                            });
                            return [...v];
                        })
                        break;
                    case "user-create-fail":
                        console.warn("User create failed.. see logs");
                        break;
                }
            }
        }).catch(e => {
            setIsUpdating(false);
            console.warn(e);
        });


    }


    return (
        <div className={classNames(style.module, "form", style.usersform)}>
            <h2 className="m">{localization["newUserTitle"]}</h2>
            <div className={classNames("row", validators.find(p => p.type === "email")?.valid === false ? style.warn : null)}>
                <label>{localization["EmailAddress"]}*</label>
                <input type="text"
                    autoComplete="chrome-off"
                    disabled={isUpdating}
                    onChange={(e) => { setEmail(e.target.value); runValidator(e.target.value, "email"); }}
                    value={email}
                    className="form-control" />
                {
                    !IsNullOrWhiteSpace(validators.find(p => p.type === "email").msg) &&
                    <p className={style.erroMsg}>{validators.find(p => p.type === "email").msg}</p>
                }

            </div>

            <div className={style.rowgroup}>
                <div className={classNames("row", validators.find(p => p.type === "firstName")?.valid === false ? style.warn : null)}>
                    <label>{localization["FirstName"]}*</label>
                    <input type="text"
                        autoComplete="chrome-off"
                        disabled={isUpdating}
                        onChange={(e) => {
                            setFirstName(e.target.value);
                            runValidator(e.target.value, "firstName");
                        }}
                        value={firstName}
                        className="form-control" />
                </div>


                <div className="row">
                    <label>{localization["LastName"]}</label>
                    <input type="text"
                        autoComplete="chrome-off"
                        disabled={isUpdating}
                        onChange={(e) => {
                            setLastName(e.target.value);
                        }}
                        value={lastName}
                        className="form-control" />
                </div>
            </div>

            <div className={classNames("row", "w50", countryCode === null ? style.warn : null)}>
                <label>{localization["countryLabel"]}*</label>
                <Select
                    styles={
                        {
                            control: (provided, state) => ({
                                ...provided,
                                borderColor: countryCode === null ? '#cc2000' : '#aaa',
                            }),
                        }
                    }

                    placeholder={localization["chooseOption"]}
                    onChange={(opt) => setCountryCode(opt)}
                    options={contryOptions}
                    value={countryCode} />
            </div>

            <div className="row" style={{ marginBottom: "20px" }}>
                <label>{localization["PasswordTemp"]}</label>
                <div className={style.inpbutton}>
                    <input ref={pwdRef} type="text" onClick={onPwdFiledClick} className="form-control" value={password} readOnly={true} />
                    <button onClick={generateNewPassword}>
                        <RefreshIcon />
                    </button>
                </div>
            </div>

            <h4>Accès administratif</h4>
            <div className={style.checkboxes}>
                <Checkbox checked={hasUserManage} label="Gestion JEUeurs" onCheckChange={() => { setHasUserManage(!hasUserManage) }} />
                <Checkbox checked={hasGroupManage} label="Gestion groupes" onCheckChange={() => { setHasGroupManage(!hasGroupManage) }} />
                <Checkbox checked={hasCategoryManage} label="Gestion categories" onCheckChange={() => { setHasCategoryManage(!hasCategoryManage) }} />
                <Checkbox checked={vocab} label="Gestion vocabulaire" onCheckChange={() => { setVocab(!vocab) }} />
                <Checkbox checked={qa} label="Assurence Qualité" onCheckChange={() => { setQA(!qa) }} />
            </div>


            <div className="row">
                <label>Ajouter des groupes</label>
                <span style={{ fontSize: '.9em', lineHeight: '1.3em' }}>{localization["groupSearchByName"]}</span>
                <AutocompleteGroup
                    key={groupSearchKey}
                    onGroupSelect={(group: GroupPath) => {
                        // reset autocomplete control to flush all values

                        if (groupSearchKey !== group.group.id) {
                            setGroupSearchKey(group.group.id);
                        }
                        else {
                            setGroupSearchKey("initial-static");
                        }
                        const groupInfo: GroupInfo = {
                            id: group.group.id,
                            name: group.group.name
                        };

                        if (!groups.some(p => p.id === groupInfo.id)) {
                            setGroups([groupInfo, ...groups]);
                        }
                    }
                    }
                />
            </div>


            <div className="row">
                <div className={style.groupheightlimit}>
                    <GroupList
                        groups={groups}
                        onRemove={(groupId) => {
                            const newGroups = groups.filter(p => p.id !== groupId);
                            setGroups(newGroups);
                        }}
                    />
                </div>
            </div>

            <div className="row buttons">
                <button
                    className="btn cancel"
                    disabled={isUpdating}
                    onClick={() => { nav("/admin/users"); }}>{localization["cancel"]}</button>
                <button
                    onClick={submitUserCreate}
                    className="btn action"
                    disabled={validators.some(p => p.valid === false) || countryCode === null}>{localization["submit"]}</button>
            </div>
        </div >

    )
};