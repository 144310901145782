import { ReactElement, useContext, useEffect, useState } from "react";
import style from "./manage.module.scss";
import axios from "axios";
import { app } from "../../..";
import { TransactionResult } from "../../../models/TransactionResult";
import { useNavigate, useParams } from "react-router";
import classNames from "classnames";
import { LocalizationContext } from "../../../interfaces/AppContext";
import { adminRequests } from "../../../requests/adminRequests";
import { useQuery } from "@tanstack/react-query";
import { adminKeys } from "../../../queryKeys/adminKeys";
import ReactLoading from "react-loading";


export const GroupForm = (): ReactElement => {

    const { regionId } = useParams();

    const { localization } = useContext(LocalizationContext);

    const { GetLocationInfo } = adminRequests(app.apiBasePath);

    const { isLoading: islocationInfoLoading, data: locationInfo } = useQuery({
        queryFn: () => GetLocationInfo(regionId),
        queryKey: adminKeys.locationInfo(regionId)
    });

  
    const [message, setMessage] = useState("");
    const [name, setName] = useState("");

    const nav = useNavigate();



    const createGroup = (): void => {
        setMessage("");

        const result = axios.post(`${app.apiBasePath}/admin/group`, {
            name: name,
            parentId: regionId
        });

        result.then(r => {
            const response = r.data as TransactionResult;
            if (response.success) {
                setMessage(name + " group created!");
                setName("");
                // refresh the group list
                nav(`/admin/groups/${regionId}`);

            }
            else {
                setMessage(localization[response.errorMessage]);
            }
        })
    };

    if(islocationInfoLoading){
        return <ReactLoading type={"spin"} color={"#5270F0"} height={16} width={16} /> 
    }

    console.log(locationInfo);
    if(!islocationInfoLoading && locationInfo === null){
       setTimeout(() => nav("/admin/groups"), 0);
        return null;
    }

    return (
        <div className={style.container}>
            <div className={classNames("form", style.content)}>

                <h2>Ajouter un groupe dans {locationInfo.name}</h2>

                <div className={style.form}>

                    <div className={style.row}>
                        <label>Nom du groupe</label>
                        <input value={name} onChange={e => setName(e.target.value)} className={style.control} type="text" />
                    </div>

                    {
                        message !== "" &&
                        <div className={style.message}>
                            <span>{message}</span>
                        </div>
                    }
                    <div className={classNames(style.row, style.buttons)}>
                        <button onClick={() => nav(`/admin/groups/${regionId}`)} className="btn cancel">{localization["cancel"]}</button>
                        <button onClick={createGroup} disabled={name.length < 2} className="btn action">{localization["submit"]}</button>
                    </div>

                </div>
            </div>
        </div>
    );
}