import axios from "axios";
import { PaginatedResult } from "../models/PaginatedResult";  
import { SearchOptions } from "../models/SearchOptions";
import { RadiusValues } from "../store/homeStore";
import { SearchResultModel } from "../models/SearchResultModel";


export interface SearchRequest {
    SearchExchanges: ({
        page, queryText, categoryId, locationIds,
        radiusControlPosition, exchangeType, includeMyExchanges,
        orderBy, IsPointsOnly }: Partial<SearchOptions> & { page: number }) => Promise<PaginatedResult>;

    GetGroupExchange: (groupId: string) => Promise<Array<SearchResultModel>>;

}
export const searchRequests = (apiPath: string): SearchRequest => {


    const searchExchanges = async ({ page, queryText, categoryId, locationIds,
        radiusControlPosition,
        exchangeType, includeMyExchanges, orderBy, IsPointsOnly }: Partial<SearchOptions> & { page: number }): Promise<PaginatedResult> => { 

        try {
            const result = await axios.post<PaginatedResult>(`${apiPath}/search/exchanges`, {
                page: page,
                categoryId: categoryId,
                query: queryText,
                groups: locationIds,
                distanceMeters: RadiusValues[radiusControlPosition] * 1000,
                exchangeType: exchangeType,
                includeMyResults: includeMyExchanges,
                order: orderBy === "date" ? 0 : 1,
                onlyInPoints: IsPointsOnly
            });

            return result.data;
        }
        catch (e) {
            console.warn(e);
        }
    }

    const getGroupExchange = async (groupId: string): Promise<Array<SearchResultModel>> => {
        try {
            const result = await axios.get<Array<SearchResultModel>>(`${apiPath}/exchange/group/${groupId}`);
            return result.data;
        }
        catch (e) {
            console.warn(e);
        }
    }

    return {
        SearchExchanges: searchExchanges,
        GetGroupExchange: getGroupExchange
    }
}


