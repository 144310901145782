import classNames from "classnames";
import { FC, ReactElement } from "react"
import { Radio } from "./Radio";
import style from "../styles/multiradio.module.scss";
import { IsUndefinedOrNull } from "../misc/Utilities";

export interface MultiRadioOption {
    label: string;
    value: string | number;
}

export interface MultiRadioProps {
    group: string;
    options: MultiRadioOption[];
    selectedOptionValue: string | number;
    onSelect: (option: MultiRadioOption) => void;

    inline?: boolean;
    required?: boolean;
}

export const MultiRadio: FC<MultiRadioProps> = ({
    group,
    options,
    selectedOptionValue,
    onSelect,
    inline = false,
    required = false
}): ReactElement => {


    const isValueSet = (value: string | number): boolean => {

        if (IsUndefinedOrNull(value)) {
            return false;
        }

        if (typeof value === "string" && value === "") return false;

        if (typeof value === "number" && isNaN(value)) return false;

        return true;
    }

    return (
        <div className={classNames(
            style.container,
            inline === true ? style.inline : null,
            required === true && !isValueSet(selectedOptionValue) ? style.required : null)}>
            {
                options.map((option, idx) => {
                    return (
                        <Radio
                            key={idx}
                            currentValue={selectedOptionValue}
                            group={group}
                            onChange={val => {
                                let opt = isNaN(+val) ? val : +val;
                                const selectedOpt = options.find(p => p.value === opt);
                                onSelect(selectedOpt);

                            }}
                            value={option.value}
                            label={option.label} />)
                })
            }
        </div>
    )
}