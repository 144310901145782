import { ReactElement, useContext, useEffect, useState } from "react";
import { AppContext, LocalizationContext } from "../../interfaces/AppContext";
import axios from "axios";
import { app } from "../..";
import { StatusCodes } from "http-status-codes";
import { Agreement } from "../../models/Agreement";

import style from "./agreement.module.scss";
import classNames from "classnames";
import Loading from "react-loading";

export const AgreementView = ({ readOnly }: { readOnly?: boolean }): ReactElement => {

    const { person } = useContext(AppContext);

    const [document, setDocument] = useState<Agreement>(null);

    const [error, setError] = useState(false);

    const { localization } = useContext(LocalizationContext);

    const [busy, setBusy] = useState(false);

    useEffect(() => {

        const request = axios.get(`${app.apiBasePath}/auth/agreement?lang=fr`);
        request.then(result => {
            if (result.status === StatusCodes.NO_CONTENT) {
                setError(true);
            }
            else if (result.status === StatusCodes.OK) {
                if (result.data.fault === true) {
                    // bypass
                }
                else {
                    setDocument(result.data);
                }
            }
        });

    }, []);

    if (error) {
        return <p>:(</p>
    }

    const acceptAgreement = (): void => {

        if (readOnly)
            return;
        setBusy(true);

        const rq = axios.post(`${app.apiBasePath}/profile/agreement/accept?agreementId=${document.id}`);
        rq.then(() => {
            setBusy(false);
            window.location.reload();
        });
    };

    return (
        <div className={classNames(style.container, readOnly ? style.readonly : null)}>
            {
                document !== null &&
                <div className={style.document}>
                    {
                        false &&
                        <div className={style.title}>
                            <h1>{document.title}</h1>
                        </div>
                    }

                    <div className={style.body} dangerouslySetInnerHTML={{ __html: document.text }}></div>
                    {
                        !readOnly &&
                        <div className={style.controls}>
                            <button disabled={busy} onClick={acceptAgreement} className="btn engage">Je m'engage</button>
                            <div>
                                <a className="right" href={app.apiBasePath + "/auth/signout"}>{localization["signout"]}</a>
                            </div>
                        </div>
                    }

                </div>
            }
            {
                document === null &&
                <Loading />
            }
        </div>
    )
}