import { ReactElement, useContext, useLayoutEffect } from "react";
import style from "./announcement.module.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { groupRequests } from "../../../requests/groupRequests";
import { app } from "../../..";
import { useQuery } from "@tanstack/react-query";
import { groupKeys } from "../../../queryKeys/groupKeys";
import { Loading } from "../../../components/Loading";
import { PersonWidgetLight } from "../../../components/PersonWidget/PersonWidgetLight";
import { IsUndefinedOrNull, StringIsNullOrEmpty } from "../../../misc/Utilities";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

import CheckIcon from '@mui/icons-material/Check';
import { GroupPost } from "../../../models/GroupPost";
import { LocalizationContext } from "../../../interfaces/AppContext";
import EditNoteIcon from '@mui/icons-material/EditNote';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import moment from "moment";
import classNames from "classnames";

export const Announcements = (): ReactElement => {

    const { groupid } = useParams();
    const nav = useNavigate();
    const { GetGroupMessages } = groupRequests(app.apiBasePath);
    const { localization: v } = useContext(LocalizationContext);
    const { data: messages, isLoading } = useQuery({
        queryFn: () => GetGroupMessages(groupid),
        queryKey: groupKeys.groupMessages(groupid)
    });

 
    return (
        <div className={style.page}>

            <Link className={classNames("btn", "action", style.createbtn)} to="create">
                <EditNoteIcon />
                {v["create"]}</Link>

            {
                isLoading &&
                <Loading />
            }
            {
                !isLoading &&
                <div className={style.messages}>
                    {
                        messages.map((gp: GroupPost) => {

                            const hasAvatar = !StringIsNullOrEmpty(gp.publishedBy?.avatarFileId);

                            return (
                                <div key={gp.id} className={style.message} onClick={()=> nav(`edit/${gp.id}`)}>
                                    {
                                        hasAvatar ?
                                            <PersonWidgetLight
                                                size={50}
                                                avatarFileName={gp.publishedBy.avatarFileId}
                                                name={gp.publishedBy.displayName}
                                            /> :
                                            <SentimentSatisfiedAltIcon style={{ fontSize: '3em' }} />
                                    }

                                    <div className={style.mc}>
                                        <h2>{gp.title}</h2>
                                        <div className={style.op}>
                                            {
                                                gp.published &&
                                                <div className={style.p}>
                                                    <div className={style.published} >
                                                        <CheckIcon />
                                                        <span>{v["published"].toLowerCase()}</span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                !gp.published &&
                                                <div className={style.p}>
                                                    <div className={classNames(style.published, style.not)} >
                                                        <ReportGmailerrorredIcon />
                                                        <span>{v["notpublished"].toLowerCase()}</span>
                                                    </div>
                                                </div>
                                            }
                                            <div className={style.p}><strong>{v["authorLabel"]}:</strong>
                                                {gp.publishedBy?.displayName}
                                            </div>
                                            {
                                                !IsUndefinedOrNull(gp.dateCreated) &&
                                                <div className={style.p}>
                                                    <strong>date:</strong>
                                                    <span>{moment(gp.dateCreated).format("MMM DD, YYYY")}</span>
                                                </div>
                                            }
                                            {
                                                !IsUndefinedOrNull(gp.dateUpdated) &&
                                                <div className={style.p}>
                                                    <strong>{v["DateUpdated"].toLowerCase()}:</strong>
                                                    <span>{moment(gp.dateUpdated).format("MMM DD, YYYY")}</span>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            }
        </div>
    );
}
