import { FC, ReactElement } from "react";
import style from './exchangepreview.module.scss';
import { Link } from "react-router-dom";
import { IsNullOrWhiteSpace } from "../../misc/Utilities";
import { app } from "../..";
import noimg from "../../images/no-image.jpg";
import { SearchResultModel } from "../../models/SearchResultModel";
import classNames from "classnames";
import { isNaN } from "lodash";

export interface ExchangePreviewProps {
    exchange: SearchResultModel;
}

export const ExchangePreview: FC<ExchangePreviewProps> = ({
    exchange
}): ReactElement => {

    const valueInPoints = isNaN(exchange?.exchange?.valueInPoints) ? 0 : exchange.exchange.valueInPoints;
    const allInPoints = exchange?.exchange?.isPointsOnly === true;

    return (
        <div className={classNames(style.component, exchange.exchangeType === "demande" && style.demande)}>
            <section>
                {
                    valueInPoints > 0 &&
                    <span className={classNames(style.tag, style.points, allInPoints ? style.onlypoints : null)}>{exchange.exchange.valueInPoints} pts</span>
                }
            </section>
            <div className={style.thumb}>
                {
                    !IsNullOrWhiteSpace(exchange.file?.fileId) &&
                    <Link to={`/exchanges/${exchange.id}`}>
                        <img draggable={false} src={`${app.apiBasePath}/assets/image/thumb/${exchange.file?.fileId}`} alt="image non trouvé" />
                    </Link>
                }
                {
                    IsNullOrWhiteSpace(exchange.file?.fileId) &&
                    <Link to={`/exchanges/${exchange.id}`}>
                        <img src={noimg} />
                    </Link>
                }
            </div>
            <h2>{exchange.exchange.title}</h2>
        </div>
    )
}