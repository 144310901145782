import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Master, MasterProps } from './pages/Master/Master';
import { Home } from './pages/Home/Home';
import { Login } from './pages/Login/Login';
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    createRoutesFromElements,
    Navigate
} from "react-router-dom";

import { AppContext, LocalizationContext } from './interfaces/AppContext';
import { app } from '.';
import { PubSubTopic } from './misc/Constants';
import { ExchangeForm } from './pages/post/ExchangeForm';
import { CategoryManagement } from './pages/admin/CategoryManagement/CategoryManagement';
import { ExchangeFilesForm } from './pages/post/ExchangeFilesForm';
import { ExchangeState, GroupPrivileges, Role } from './models/Enums';
import { ProfileView } from './pages/profile/ProfileView';
import { ProfileEdit } from './pages/profile/ProfileEdit';
import { ChangePassword } from './pages/profile/ChangePassword';
import { GroupManage } from './pages/Facilitators/GroupManage';
import { ExchangeLoader } from './components/ExchangeLoader';
import { ExchangeView } from './pages/ExchangeView/ExchangeView';
import { FavoriteExchange } from './models/FavoriteExchange';
import { FavoritesPage } from './pages/Favorites/FavoritesPage';
import { Manage } from './pages/admin/Management/Manage';
import { GroupsManagement } from './pages/admin/Management/GroupsManagement';
import { GroupForm } from './pages/admin/Management/GroupFrom';
import { UserExchanges } from './pages/UserExchanges/UserExchanges';
import { MyExchanges } from './pages/MyExchanges/MyExchanges';
import { HasPrivilege, HasPrivilegeOrDev, IsNullOrWhiteSpace } from './misc/Utilities';
import { UserManagement } from './pages/admin/UserManage/UserManagement';
import { UserForm } from './pages/admin/UserManage/UserForm';
import { EditUserForm } from './pages/admin/UserManage/EditUserForm';
import { GroupManagement } from './pages/admin/GroupManagement/GroupManagement';
import { ManageGroupForm } from './pages/admin/GroupManagement/ManageGroupForm';
import { VocabularyPage } from './pages/admin/VocabularyManagement/VocabularyPage';
import { VocabularyForm } from './pages/admin/VocabularyManagement/VocabularyForm';
import { CategoryForm } from './pages/admin/CategoryManagement/CategoryForm';
import { UpdateCategoryForm } from './pages/admin/CategoryManagement/UpdateCategoryForm';
import { LogsPage } from './pages/Developer/LogsPage';
import { UserDebugView } from './pages/Developer/UserDebugView';
import { Socket, io } from 'socket.io-client';
import { Message } from './misc/EventMessages';
import { SocketMessage } from './models/SocketMessage';
import { Tests } from './pages/Developer/Tests';
import { JoinGroup } from './pages/Join/JoinGroup';
import { AgreementView } from './pages/Agreements/AgreementView';
import moment from 'moment';
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { appStore } from './store/appStore';
import { useQuery } from '@tanstack/react-query';
import { appKeys } from './queryKeys/appKeys';
import { GetMyFavoriteExchanges } from './misc/Requests';
import { InvitationsLookup } from './pages/admin/Invitations/InvitationsLookup';
import { InvitationFormAdmin } from './pages/admin/Invitations/InvitationFormAdmin';
import { ViewInvitation } from './pages/admin/Invitations/View/ViewInvitation';
import { GroupUsersManage } from './pages/Facilitators/GroupUsersManage';
import { Invitations } from './pages/Facilitators/Invitations';
import { InvitationForm } from './pages/Facilitators/InvitationForm';
import { Publications } from './pages/Facilitators/Publications';
import { UserManage } from './pages/Facilitators/UserManage';
import './styles/app.global.scss';
import { GroupPage } from './pages/Group/GroupPage';
import { GroupExchanges } from './pages/Group/Exchanges/GroupExchanges';
import { Announcements } from './pages/Facilitators/Announcements/Announcements';
import { AnnouncementFrom } from './pages/Facilitators/Announcements/AnnouncementFrom';
import { AnnouncementEditFrom } from './pages/Facilitators/Announcements/AnnouncementEditFrom';
import { InfoPage } from './pages/Group/InfoPage/InfoPage';

const App = ({ socketsToken }: { socketsToken: string }): ReactElement => {

    const { person, groups, categories, groupPath, localization, exchanges } = appStore(state => state);


    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const [token, setToken] = useState(socketsToken);
    const lastTokenRefreshRef = useRef(new Date());


    const { data: favoriteExchanges } = useQuery<Array<FavoriteExchange>>({
        initialData: [],
        queryKey: appKeys.favorites,
        queryFn: GetMyFavoriteExchanges
    });

    /*
    useEffect(() => {
        const nav = buildNavMenu(person, groups, app.store.state.exchanges, favoriteExchanges);
        setNavigation(nav);
        const ref = PubSub.subscribe(PubSubTopic.Changes, onStoreDataChanged);

        return () => {
            PubSub.unsubscribe(ref);
        }
    }, [favoriteExchanges]);

    useEffect(() => {
        const nav = buildNavMenu(person, groups, app.store.state.exchanges, favoriteExchanges);
        setNavigation(nav);
    }, [groups]); */

    useEffect(() => {

        let socket: Socket = null;

        if (false && HasPrivilege(person.role, Role.Developper)) {

            socket = io(app.wsUrl, {
                reconnectionDelayMax: 1000,
                autoConnect: true,
                extraHeaders: {
                    "auth": `${token}`
                }
            });

            socket.on("connect_error", (err) => {
                let duration = moment.duration(moment().diff(lastTokenRefreshRef.current));
                const minutes = duration.asMinutes();

                if (minutes > 5) {
                    const tokenRequest = axios.get(`${app.apiBasePath}/auth/token`, { validateStatus: e => e > 500 });
                    tokenRequest.then(result => {
                        if (typeof result === "undefined" || result.status === StatusCodes.UNAUTHORIZED) {
                            window.location.reload();
                        }
                        else {

                            if (!IsNullOrWhiteSpace(result.data)) {
                                setToken(result.data);
                                lastTokenRefreshRef.current = new Date();
                            }
                        }
                    });
                }
            });

            socket.on('command', (response: SocketMessage) => {

                let msg = -1;

                switch (response.event) {
                    case "command":
                        msg = Message.SocketCommand;
                        break;
                    case "event":
                        msg = Message.SocketEvent;
                        break;
                    case "notification":
                        msg = Message.SocketNotification;
                        break;
                    default:
                        break;
                }
                if (msg > 0) {
                    PubSub.publish(PubSubTopic.Action, {
                        id: msg,
                        data: response.message
                    });
                }
            });
        }

    }, []);

    const masterProps: MasterProps = {
        favoriteExchanges: favoriteExchanges,
        mobileMenuVisible: mobileMenuVisible,
        onOpenMobileMenu: () => setMobileMenuVisible(true),
        onCloseMobileMenu: () => setMobileMenuVisible(false)
    };

    const hasAdminOptions = (roles: Role): boolean => {
        return HasPrivilege(roles, Role.Developper)
            || HasPrivilege(roles, Role.UserManagement)
            || HasPrivilege(roles, Role.GroupManagement)
            || HasPrivilege(roles, Role.CategoryManagement);
    };

    const hasAccessToModeratorUrls = groups.some(g => HasPrivilege(g.privilege, GroupPrivileges.Facilitator));

    const router = createBrowserRouter(
        createRoutesFromElements(

            <Route path={"/"} element={<Master {...masterProps} />}>
                {
                    HasPrivilege(person.role, Role.Developper) &&
                    <Route path={"dev"}>
                        <Route path={"logs"} element={<LogsPage />} />
                        <Route path={"userprofile"} element={
                            <UserDebugView
                                categories={categories} />} />
                        <Route path="test" element={<Tests />} />
                    </Route>
                }

                <Route path="g">
                    <Route path=":groupname" element={<GroupPage />}>
                        <Route index element={<Navigate to={"offers"} />} />
                        <Route path="offers" element={<GroupExchanges view="offer" />} />
                        <Route path="demands" element={<GroupExchanges view="demande" />} />
                        <Route path="info" element={<InfoPage />} />
                    </Route>
                </Route>

                <Route path={"join"}>
                    <Route index path=":code" element={<JoinGroup />} />
                </Route>
                <Route index element={<Home categories={categories} />} />
                <Route path={"profile"}>
                    <Route index element={<ProfileView />} />
                    <Route path="edit" element={<ProfileEdit />} />
                    <Route path="changepwd" element={<ChangePassword />} />
                </Route>

                <Route path={"/drafts"}>
                    <Route index element={
                        <MyExchanges
                            titleKey="myDrafts"
                            noItemsKey="noDraftsMessage"
                            exchanges={exchanges.filter(p => p.state === ExchangeState.Draft)}
                            categories={categories} />} />
                </Route>

                <Route path={"/exchanges"}>
                    <Route index element={
                        <MyExchanges
                            titleKey="myOffers"
                            noItemsKey="noOffersMessage"
                            exchanges={exchanges.filter(p => p.state === ExchangeState.Published && p.exchangeType === "offer")}
                            categories={categories} />} />
                </Route>
                <Route path={"/demandes"}>
                    <Route index element={
                        <MyExchanges
                            titleKey="myDemands"
                            noItemsKey="noOffersMessage"
                            exchanges={exchanges.filter(p => p.state === ExchangeState.Published && p.exchangeType === "demande")}
                            categories={categories} />} />
                </Route>

                <Route path={"/favorites"}>
                    <Route index element={
                        <FavoritesPage items={favoriteExchanges} />} />
                </Route>

                <Route path="exchanges">
                    <Route path=":id" element={<ExchangeView categories={categories} />} />
                    <Route path="u">
                        <Route path=":personid" element={<UserExchanges />} />
                    </Route>
                </Route>

                <Route path={"post"}>
                    <Route path={"exchange"} element={<ExchangeForm categories={categories} />} />
                    <Route path={"exchange/:exchangeid"} element={
                        <ExchangeLoader categories={categories} />
                    } />
                    <Route path={"exchange/:exchangeid/files"} element={
                        <ExchangeFilesForm categories={categories} />
                    } />
                </Route>

                <Route path={"group"}>
                    {
                        (hasAccessToModeratorUrls || HasPrivilege(person.role, Role.Developper)) &&
                        <Route path="manage">
                            <Route index element={<Navigate to="/" />} />
                            <Route path=":groupid" element={<GroupManage />}>
                                <Route index element={<Navigate to="users" />} />
                                <Route path="users">
                                    <Route index element={<GroupUsersManage />} />
                                    <Route path=":userid" element={<UserManage />} />
                                </Route>
                                <Route path="invitations">
                                    <Route index element={<Invitations />} />
                                    <Route path="view">
                                        <Route path={":code"} element={<ViewInvitation />} />
                                    </Route>
                                </Route>
                                <Route path="invite" element={<InvitationForm />} />
                                <Route path="publications" element={<Publications />} />

                                <Route path="announcements">
                                    <Route index element={<Announcements />} />
                                    <Route path="create" element={<AnnouncementFrom />} />
                                    <Route path="edit">
                                        <Route path={":postid"} element={<AnnouncementEditFrom />} />
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                    }
                </Route>

                <Route path={"/login"}>
                    <Route index element={<Login />} />
                </Route>

                {
                    hasAdminOptions(person.role) &&
                    <Route path="/admin">
                        {
                            HasPrivilegeOrDev(person.role, Role.UserManagement) &&
                            <Route path={"users"}>
                                <Route index element={<UserManagement />} />
                                <Route path={":personId"} element={<EditUserForm />} />
                                <Route path="create" element={<UserForm />} />
                            </Route>
                        }
                        {
                            HasPrivilegeOrDev(person.role, Role.UserManagement) &&
                            <Route path="invitations">
                                <Route index element={<InvitationsLookup />} />
                                <Route path={":groupid"} element={<InvitationFormAdmin />} />
                                <Route path="view">
                                    <Route path={":code"} element={<ViewInvitation />} />
                                </Route>
                            </Route>
                        }

                        {
                            HasPrivilegeOrDev(person.role, Role.GroupManagement) &&
                            <Route path={"groups"}>
                                <Route index element={<GroupManagement />} />
                                <Route path={":regionId"} element={<GroupManagement />} />
                                <Route path="create">
                                    <Route path=":regionId" element={<GroupForm />} />
                                </Route>
                                <Route path="manage">
                                    <Route path=":groupId" element={<ManageGroupForm />} />
                                </Route>

                            </Route>

                        }
                        {
                            HasPrivilegeOrDev(person.role, Role.CategoryManagement) &&
                            <Route path={"categories"}>
                                <Route index element={<CategoryManagement />} />
                                <Route path=":categoryId" element={<UpdateCategoryForm categories={categories} />} />
                                <Route path="add" element={<CategoryForm />} />
                            </Route>
                        }
                        {
                            HasPrivilegeOrDev(person.role, Role.VocabularyManagement) &&
                            <Route path={"vocabulary"}>
                                <Route index element={<VocabularyPage />} />
                                <Route path={":locale"} element={<VocabularyPage />} />
                                {
                                    HasPrivilege(person.role, Role.Developper) &&
                                    <Route path="add">
                                        <Route index element={<VocabularyForm />} />
                                        <Route path={":locale"} element={<VocabularyForm />} />
                                    </Route>
                                }

                            </Route>
                        }
                    </Route>
                }
                {
                    HasPrivilege(person.role, Role.Developper) &&
                    <Route path={"dev"}>
                        <Route index element={<div></div>} />
                        <Route path={"management"}>
                            <Route index element={<Manage />} />
                            <Route path={"groups"}>
                                <Route index element={<GroupsManagement />} />
                                <Route path="add" element={<GroupForm />} />
                            </Route>
                        </Route>
                    </Route>
                }

                <Route path="codeofconduct" element={<AgreementView readOnly={true} />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Route>
        )
    );

    return (
        <AppContext.Provider value={{
            person: person,
            groups: groups,
            groupPath: groupPath,
            favorites: favoriteExchanges
        }}>
            <LocalizationContext.Provider value={{
                localization: localization
            }}>
                <RouterProvider router={router} />
            </LocalizationContext.Provider>
        </AppContext.Provider>
    );
}

export { App }