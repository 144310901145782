
import { create } from "zustand";


export interface NotificationStore {
    groupsHavingNotifications: string[];

    update?: <K extends keyof Omit<NotificationStore, "update">>(key: K, value: NotificationStore[K]) => void;

}

export const notificationStore = create<NotificationStore>((set) => ({
    groupsHavingNotifications: [],
    update: (key, value) => set((state) => ({ ...state, [key]: value }))
}));