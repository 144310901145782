import { FC, ReactElement, useState } from "react"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import style from './pwdfield.module.scss';
import classNames from "classnames";

export interface PasswordFieldProps {
    value: string;
    disabled?: boolean;
    warn?: boolean;
    onChange: (value: string) => void;
    onEnterKeyPress?: () => void;
}


export const PasswordField: FC<PasswordFieldProps> = ({ value, disabled, warn, onChange, onEnterKeyPress }): ReactElement => {

    const [pwdType, setPwdType] = useState<"password" | "text">("password");

    const onKeyDown = (e: React.KeyboardEvent): void => {
        if (e.key === "Enter") {
            typeof onEnterKeyPress === "function" && onEnterKeyPress();
        }
    }

    return (
        <div className={classNames(style.control, warn === true && style.warn)}>
            <input
                disabled={disabled}
                onKeyDown={onKeyDown}
                autoComplete="current-password"
                type={pwdType}
                value={value}
                onChange={(e) => onChange(e.target.value)}
            />
            {
                pwdType === "password" &&
                <div onClick={() => setPwdType("text")} className="eye-icon">
                    <VisibilityIcon />
                </div>

            }
            {
                pwdType === "text" &&
                <div onClick={() => setPwdType("password")} className="eye-icon">
                    <VisibilityOffIcon />
                </div>
            }
        </div>
    );
}