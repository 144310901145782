import { FC, ReactElement } from "react";
import { groupPageStore } from "../../../store/groupPageStore";
import { ExchangePreview } from "../../../components/ExchangePreview/ExchangePreview";
import { PersonWidget } from "../../../components/PersonWidget/PersonWidget";
import { useOutletContext } from 'react-router-dom';
import { GroupPageContext } from "../GroupPage";
import { GroupPerson } from "../../../models/GroupPerson";
import { ExchangeType } from "../../../models/ExchangeType";
import style from "../group.module.scss";

export interface GroupExchangesPros {
    view: ExchangeType;
}

export const GroupExchanges: FC<GroupExchangesPros> = ({ view }): ReactElement => {

    const { personFilter, addToPersonFilter } = groupPageStore(store => store);

    const { exchanges, groupInfo, people }: GroupPageContext = useOutletContext();


    const typeFilteredExchanges = exchanges?.filter(p => p.exchangeType === view) ?? [];
    let filteredExchanges = typeFilteredExchanges;

    if (personFilter[groupInfo.id]?.length > 0) {
        filteredExchanges = filteredExchanges?.filter(e => personFilter[groupInfo.id]?.includes(e.postedById));
    }

    const addPersonToFilter = (person: GroupPerson): void => {
        if (!isPersonInFilter(person)) {
            const key = groupInfo!.id;
            addToPersonFilter(key, [person.personId, ...(personFilter[key] ?? [])]);
        }
        else removePersonFromFilter(person);
    }

    const removePersonFromFilter = (person: GroupPerson): void => {
        if (isPersonInFilter(person)) {
            const key = groupInfo!.id;
            addToPersonFilter(key, personFilter[key].filter(p => p !== person.personId));
        }
    }

    const isPersonInFilter = (person: GroupPerson): boolean => {
        const key = groupInfo!.id;
        return personFilter[key]?.some(p => p === person.personId);
    }

    return (
        <div className={style.central}>
            <div className={style.sections}>
                <div>
                    {
                        filteredExchanges.length > 0 &&
                        <div>
                            {
                                view === "offer" &&
                                <h1>Offres partagées dans {groupInfo.name}</h1>
                            }
                            {
                                view === "demande" &&
                                <h1>Demandes partagées dans {groupInfo.name}</h1>
                            }
                            <div className={style.tiles}>
                                {
                                    filteredExchanges.map(e => <ExchangePreview key={e.id} exchange={e} />)
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
            <aside className={style.people}>
                {
                    people.map(p => <div key={p.personId} onClick={() => addPersonToFilter(p)} className={isPersonInFilter(p) ? style.focus : null}>
                        <PersonWidget
                            view={view}
                            exchangesCount={typeFilteredExchanges.filter(e => e.postedById === p.personId).length}
                            person={p} />
                    </div>
                    )
                }
            </aside>
        </div>
    )
}
