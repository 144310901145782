import { ReactElement, useContext, useEffect } from "react";
import style from "./invitations-lookup.module.scss";
import Select from "react-select";
import { appStore } from "../../../store/appStore";
import { adminInvitationsStore } from "../../../store/adminInvitationsStore";
import { app } from "../../..";
import { groupRequests } from "../../../requests/groupRequests";
import { useQuery } from "@tanstack/react-query";
import { groupKeys } from "../../../queryKeys/groupKeys";
import { LocalizationContext } from "../../../interfaces/AppContext";
import { adminRequests } from "../../../requests/adminRequests";
import { adminKeys } from "../../../queryKeys/adminKeys";
import { InvitationsTable } from "../../../components/InvitationsTable/InvitationsTable";
import { IsUndefinedOrNull, stateToText } from "../../../misc/Utilities";
import { AvailableHeightProvider } from "../../../components/AvailableHeightProvider";
import { Invitation } from "../../../models/Invitation";
import { HistoryEntry } from "../../../models/HistoryEntry";
import { ReactMultiSelectStyles } from "../../../misc/Constants";
import { InvitationState } from "../../../models/Enums";
import { OptionType } from "../../../models/OptionType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { apiRequests } from "../../../requests/apiRequests";
import { adminStore } from "../../../store/adminStore";

export const InvitationsLookup = (): ReactElement => {

    const nav = useNavigate();

    const { localization: loc } = useContext(LocalizationContext);

    const stateOptions: OptionType[] = [
        { value: InvitationState.New, label: stateToText(InvitationState.New, loc) },
        { value: InvitationState.Opened, label: stateToText(InvitationState.Opened, loc) },
        { value: InvitationState.Redeemed, label: stateToText(InvitationState.Redeemed, loc) },
        { value: InvitationState.Cancelled, label: stateToText(InvitationState.Cancelled, loc) }
    ];

    const { countryOptions } = appStore(state => state);
    const { selectedCountry , selectedGroup, stateFilters, textSearchValue, update } = adminInvitationsStore(state => state);

    const { GetGroupsByParentLocationId } = groupRequests(app.apiBasePath);
    const { GetGroupInvitations } = adminRequests(app.apiBasePath);
    const { GetInvitationCancellationReason } = apiRequests(app.apiBasePath);

    const { data: groups, isLoading } = useQuery({
        queryKey: groupKeys.groupsByParentLocation(selectedCountry?.value as string),
        queryFn: () => GetGroupsByParentLocationId(selectedCountry?.value as string),
        enabled: selectedCountry !== null
    });

    const { data: invitations, isLoading: invitationsLoading } = useQuery({
        queryKey: adminKeys.groupInvitations(selectedGroup?.value as string),
        queryFn: () => GetGroupInvitations(selectedGroup?.value as string),
        enabled: selectedGroup !== null && selectedCountry !== null
    });

    const onGroupOptionSelect = (opt: any): void => {
        update("selectedGroup", opt);
    };

    const showCancellationMessage = (inv: Invitation): void => {
        GetInvitationCancellationReason(inv.uniqueShortTokenId).then((reason: HistoryEntry | null) => {
            if (reason) {
                alert(reason.message);
            }
        });
    }

    let filteredInvitations = invitations?.filter(inv => stateFilters.length === 0 || stateFilters.some(f => f.value === inv.state));

    if (!IsUndefinedOrNull(filteredInvitations) && textSearchValue !== "") {
        filteredInvitations = filteredInvitations.filter(inv => inv.emailAddress.includes(textSearchValue) || inv.firstName.includes(textSearchValue) || inv.lastName.includes(textSearchValue));
    }


    return (
        <div className={style.page}>
            <section className={style.controls}>
                <div>
                    <Select
                        placeholder={loc["countryLabel"]}
                        value={selectedCountry}
                        options={countryOptions}
                        onChange={opt => {
                            update("selectedCountry", opt);
                            update("selectedGroup", null);
                        }
                        }
                    />
                </div>
                <div>
                    <Select
                        placeholder={loc["allGroups"]}
                        isLoading={isLoading}
                        value={selectedGroup}
                        options={groups?.map(p => ({ value: p.id, label: p.name })) ?? []}
                        onChange={opt => onGroupOptionSelect(opt)}
                    />
                </div>


                {
                    !IsUndefinedOrNull(invitations) &&
                    invitations.length > 0 &&

                    <div className={style.selector}>
                        <input
                            value={textSearchValue}
                            onKeyDown={(e) => { e.key === "Escape" && update("textSearchValue", ""); }}
                            onChange={(e) => update("textSearchValue", e.target.value)}
                            type="text" className={style.searchfield} placeholder={loc["search"]} />
                    </div>

                }

                {
                    !IsUndefinedOrNull(invitations) &&
                    invitations.length > 0 &&
                    <div>
                        <Select
                            placeholder="filtrer"
                            styles={ReactMultiSelectStyles}
                            value={stateFilters} onChange={(f) => update("stateFilters", f as OptionType[])}
                            isMulti={true}
                            options={stateOptions} />
                    </div>
                }
                {
                    selectedGroup !== null &&
                    selectedCountry !== null &&
                    <div>
                        <Link to={`/admin/invitations/${selectedGroup?.value}`}
                            className={classNames("btn", "action", style.invitebtn)}>
                            <span>Inviter</span>
                            <FontAwesomeIcon icon={"user-plus"} />
                        </Link>
                    </div>
                }
            </section>

            <div>
                {
                    !IsUndefinedOrNull(invitations) &&
                    <AvailableHeightProvider>
                        <InvitationsTable
                            onGetCancellationHistory={(inv: Invitation) => showCancellationMessage(inv)}
                            items={filteredInvitations} />
                    </AvailableHeightProvider>
                }
            </div>
        </div>
    );
}