import classNames from "classnames";
import { FC, ReactElement, useContext, useEffect, useRef, useState } from "react";
import { LocalizationContext } from "../../../interfaces/AppContext";
import { Checkbox } from "../../../components/Checkbox";
import { HasPrivilege, IsNullOrWhiteSpace, isValidEmailAddress, StringIsNullOrEmpty } from "../../../misc/Utilities";
import { app } from "../../..";
import { useNavigate, useParams } from "react-router";
import { Validator, ValidatorType } from "./UserForm"
import { Role } from "../../../models/Enums";
import { adminRequests } from "../../../requests/adminRequests";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { adminKeys } from "../../../queryKeys/adminKeys";
import { Loading } from "../../../components/Loading";
import { GroupList } from "../../../components/GroupList/GroupList";
import { GroupPath } from "../../../models/GroupPath";
import { AutocompleteGroup } from "../../../components/Autocomplete/Groups/AutocompleteGroup";
import { GroupInfo } from "../../../models/JoinedGroup";
import { OptionType } from "../../../models/OptionType";
import Select from "react-select";
import { apiRequests } from "../../../requests/apiRequests";
import { apiKeys } from "../../../queryKeys/apiKeys";
import { CreateUserModel } from "../../../models/CreateUserModel";
import style from "./usermanage.module.scss";

export const EditUserForm: FC = (): ReactElement => {

    const params = useParams();
    const { personId } = params;

    const { GetUserProfile, UpdateUser } = adminRequests(app.apiBasePath);

    const { localization } = useContext(LocalizationContext);
    const { GetCountries } = apiRequests(app.apiBasePath);

    const queryClient = useQueryClient();

    const { data: personInfo, isPending } = useQuery({
        queryFn: () => GetUserProfile(personId),
        queryKey: adminKeys.userProfile(personId),
        enabled: !StringIsNullOrEmpty(personId),
        placeholderData: {
            person: null,
            groups: []
        }
    });

    const { person, groups: joinedGroups } = personInfo;

    const [isUpdating, setIsUpdating] = useState(false);

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [countryCode, setCountryCode] = useState<OptionType>(null);

    const [hasUserManage, setHasUserManage] = useState(false);
    const [hasGroupManage, setHasGroupManage] = useState(false);
    const [hasCategoryManage, setHasCategoryManage] = useState(false);
    const [qa, setQA] = useState(false);
    const [vocab, setVocab] = useState(false);

    const [groupSearchKey, setGroupSearchKey] = useState<string>("initial-static");

    const [groups, setGroups] = useState<GroupInfo[]>([]);

    const { data: countries, isLoading: countriesLoading } = useQuery({
        queryFn: GetCountries,
        queryKey: apiKeys.countries,
    });

    const contryOptions: OptionType[] = countriesLoading ? [] : countries.map(c => {
        return {
            value: c.code,
            label: c.name
        };
    });

    const validatorsOptions: Validator[] = [
        {
            type: "email",
            valid: false,
            fn: (value: string): boolean => isValidEmailAddress(value)
        },
        {
            type: "firstName",
            valid: false,
            fn: (value: string): boolean => !IsNullOrWhiteSpace(value)
        }
    ];

    const [validators, setValidators] = useState(validatorsOptions);

    const nav = useNavigate();

    useEffect(() => {
        runValidator(firstName, "firstName");
    }, [firstName]);

    useEffect(() => {
        runValidator(email, "email");
    }, [email]);

    useEffect(() => {
        if (person !== null) {

            setEmail(person.email);
            setFirstName(person.firstName);
            setLastName(person.lastName);
            setHasUserManage(HasPrivilege(person.role, Role.UserManagement));
            setHasGroupManage(HasPrivilege(person.role, Role.GroupManagement));
            setHasCategoryManage(HasPrivilege(person.role, Role.CategoryManagement));
            setQA(HasPrivilege(person.role, Role.SystemTester));
            setVocab(HasPrivilege(person.role, Role.VocabularyManagement));
            setCountryCode(contryOptions.find(p => p.value === person.countryCode));

            const groups = joinedGroups.map(g => g.group);
            setGroups(groups);

        }
    }, [person]);

    if (isPending) {
        return <Loading />;
    }

    const runValidator = (value: string, validator: ValidatorType): void => {
        const m = validators.map(v => {
            if (v.type === validator) {
                v.valid = v.fn(value);
                v.msg = "";
            }
            return v;
        });

        setValidators(old => {
            return [...m];
        });
    };

    const updateUser = (): void => {
        setIsUpdating(true);

        const model: CreateUserModel = {
            id: person.personId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            joinGroups: groups.map(p => p.id),
            countryCode: countryCode.value as string,
            assignRights: []
        };

        hasUserManage && model.assignRights.push("user-management");
        hasGroupManage && model.assignRights.push("group-management");
        hasCategoryManage && model.assignRights.push("category-management");
        qa && model.assignRights.push("tester");
        vocab && model.assignRights.push("vocabulary");

        UpdateUser(model).then(result => {
            setIsUpdating(false);

            if (!StringIsNullOrEmpty(result.errorCode)) {
                switch (result.errorCode) {
                    case "email-match":

                        setValidators(vals => {
                            const v = vals.map(p => {
                                if (p.type === "email") {
                                    p.valid = false;
                                    p.msg = localization["emailActiveAlready"]
                                }
                                return p;
                            });
                            return [...v];
                        })
                        break;
                    case "user-create-fail":
                        console.warn("User create failed.. see logs");
                        break;
                }
            }
            else {

                queryClient.invalidateQueries({
                    queryKey: adminKeys.userProfile(personId)
                });

                nav("/admin/users");
            }
        }).catch(error => {
            setIsUpdating(false);
            console.warn(error);
        });
    };

    return (
        <div className={classNames(style.module, "form", style.usersform)}>
            <h2 className="m">{localization["editProfile"]}</h2>
            <div className={classNames("row", validators.find(p => p.type === "email")?.valid === false ? style.warn : null)}>
                <label>{localization["EmailAddress"]}*</label>
                <input type="text"
                    autoComplete="chrome-off"
                    disabled={isUpdating}
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    className="form-control" />
                {
                    !IsNullOrWhiteSpace(validators.find(p => p.type === "email").msg) &&
                    <p className={style.erroMsg}>{validators.find(p => p.type === "email").msg}</p>
                }
            </div>

            <div className={style.rowgroup}>
                <div className={classNames("row", validators.find(p => p.type === "firstName")?.valid === false ? style.warn : null)}>
                    <label>{localization["FirstName"]}*</label>
                    <input type="text"
                        autoComplete="chrome-off"
                        disabled={isUpdating}
                        onChange={e => setFirstName(e.target.value)}
                        value={firstName}
                        className="form-control" />
                </div>

                <div className="row">
                    <label>{localization["LastName"]}</label>
                    <input type="text"
                        autoComplete="chrome-off"
                        disabled={isUpdating}
                        onChange={e => setLastName(e.target.value)}
                        value={lastName}
                        className="form-control" />
                </div>
            </div>

            <div style={{ marginBottom: "20px" }} className={classNames("row", "w50", countryCode === null ? style.warn : null)}>
                <label>{localization["countryLabel"]}*</label>
                <Select
                    styles={
                        {
                            control: (provided, state) => ({
                                ...provided,
                                borderColor: countryCode === null ? '#cc2000' : '#aaa',
                            }),
                        }
                    }

                    placeholder={localization["chooseOption"]}
                    onChange={(opt) => setCountryCode(opt)}
                    options={contryOptions}
                    value={countryCode} />
            </div>

            <h4>Accès administratif</h4>
            <div className={style.checkboxes}>
                <Checkbox checked={hasUserManage} label="Gestion JEUeurs" onCheckChange={() => { setHasUserManage(!hasUserManage) }} />
                <Checkbox checked={hasGroupManage} label="Gestion groupes" onCheckChange={() => { setHasGroupManage(!hasGroupManage) }} />
                <Checkbox checked={hasCategoryManage} label="Gestion categories" onCheckChange={() => { setHasCategoryManage(!hasCategoryManage) }} />
                <Checkbox checked={vocab} label="Gestion vocabulaire" onCheckChange={() => { setVocab(!vocab) }} />
                <Checkbox checked={qa} label="Assurence Qualité" onCheckChange={() => { setQA(!qa) }} />
            </div>

            <div className="row">
                <label>Ajouter des groupes</label>
                <span style={{ fontSize: '.9em', lineHeight: '1.3em' }}>{localization["groupSearchByName"]}</span>
                <AutocompleteGroup
                    key={groupSearchKey}
                    onGroupSelect={(group: GroupPath) => {
                        // reset autocomplete control to flush all values

                        if (groupSearchKey !== group.group.id) {
                            setGroupSearchKey(group.group.id);
                        }
                        else {
                            setGroupSearchKey("initial-static");
                        }
                        const groupInfo: GroupInfo = {
                            id: group.group.id,
                            name: group.group.name
                        };

                        if (!groups.some(p => p.id === groupInfo.id)) {
                            setGroups([groupInfo, ...groups]);
                        }
                    }
                    }
                />
            </div>

            <div className="row">
                <div className={style.groupheightlimit}>
                    <GroupList
                        groups={groups}
                        onRemove={(groupId) => {
                            const newGroups = groups.filter(p => p.id !== groupId);
                            setGroups(newGroups);
                        }}
                    />
                </div>
            </div>

            <div className="row buttons">
                <button
                    className="btn cancel"
                    disabled={isUpdating}
                    onClick={() => { nav("/admin/users"); }}>{localization["cancel"]}</button>
                <button
                    onClick={() => updateUser()}
                    className="btn action"
                    disabled={validators.some(p => p.valid === false) || isUpdating}>{localization["submit"]}</button>
            </div>
        </div>
    )
};