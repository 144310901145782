import axios from "axios";
import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProgressBar } from "../../components/ProgressBar";
import { SupportedFiles, Uploader, UploaderError, UploadResponse } from "../../components/Uploader";
import { LocalizationContext } from "../../interfaces/AppContext";
import { PubSubTopic } from "../../misc/Constants";
import { AttachmentType, StorageType } from "../../models/Enums";
import { Attachment } from '../../models/Attachment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { app } from "../..";
import { NoContentPage } from "../NoContent/NoContentPage";
import { Exchange } from "../../models/Exchange";
import { Category } from "../../models/Category";


import { Message } from "../../misc/EventMessages";
import { StatusCodes } from "http-status-codes";

import style from './exchangeform.module.scss';
import { ItemInterface, ReactSortable } from "react-sortablejs";
import { IsNullOrWhiteSpace, IsUndefinedOrNull } from "../../misc/Utilities";
import Loading from "react-loading";
import { appStore } from "../../store/appStore";

type SortableImage = Attachment & ItemInterface;

export const ExchangeFilesForm = ({ categories }: { categories: Category[] }): ReactElement => {

    const { exchanges, update } = appStore(store => store);

    const { exchangeid } = useParams();

    const nav = useNavigate();

    const { localization } = useContext(LocalizationContext);

    const [files, setFiles] = useState<Array<SortableImage>>([]);

    const filesRef = useRef<Array<SortableImage>>([]);

    const [title, setTitile] = useState(null as string);
    const [busy, setBusy] = useState(true);


    const [lastError, setLastError] = useState("");

    const progressBarId = "___dfsfdskn3232";

    const addAttachmentToOffer = (fileId: string): void => {

        const request = axios.post(`${app.apiBasePath}/exchange/add/file/${fileId}/${exchangeid}`);

        request.then(result => {
            let fileId = result.data as string;

            const newFile = {
                fileId: fileId,
                attachmentType: AttachmentType.Image
            };

            const updatedExchanges = exchanges.map(e => {
                if (e.id === exchangeid) {
                    e.attachments.push(newFile);
                }
                return e;
            });

            update("exchanges", updatedExchanges);

            setFiles(old => {
                const duplicate = old.some(p => p.fileId === fileId);
                if (duplicate) {
                    return old;
                }
                else {
                    const newImg = {
                        id: newFile.fileId, ...newFile
                    } as SortableImage;
                    const next = [...old, newImg];
                    return next;
                }
            });
        });

        request.catch(e => console.error(e));
    };

    const removeImage = (fileId: string): void => {

        const request = axios.delete(`${app.apiBasePath}/exchange/file/${fileId}/${exchangeid}`);

        request.then(result => {
            let fileId = result.data as string;

            const updatedExchanges = exchanges.map(e => {
                if (e.id === exchangeid) {
                    e.attachments = e.attachments.filter(x => x.fileId !== fileId);
                }
                return e;
            });

            update("exchanges", updatedExchanges);


            setFiles(prev => {
                const filtered = files.filter(f => f.fileId !== fileId);
                const next = [...filtered];
                return next;
            });
        });

    };

    const navigateBack = (): void => {
        nav(`/post/exchange/${exchangeid}`);
    }

    const updateFilesOrder = (reorderedFiles: Array<SortableImage>): void => {
        setFiles(reorderedFiles);
        filesRef.current = reorderedFiles;
    };

    const imageOrderChange = (a, b, c): void => {

        window.setTimeout(() => {
            const payload = {
                exchangeId: exchangeid,
                files: filesRef.current.map(p => p.fileId)
            };

            axios.post(`${app.apiBasePath}/exchange/images/reorder/${exchangeid}`, payload);
        }, 300);
    };

    useEffect(() => {

        const request = axios.get<Exchange>(`${app.apiBasePath}/exchange/${exchangeid}`);

        request.then(result => {
            setBusy(false);
            if (result.status === StatusCodes.OK) {
                 
                const images = result.data.attachments
                    .filter(att => att.attachmentType === AttachmentType.Image)
                    .map(img => {
                        return {
                            id: img.fileId,
                            fileId: img.fileId,
                            attachmentType: img.attachmentType
                        } as SortableImage;
                    });

                setFiles(images);
                filesRef.current = images;
                setTitile(result.data.title);
            }
        });

    }, [exchangeid]);


    if (busy) {
        return (
            <NoContentPage>
                <Loading />
            </NoContentPage>
        );
    }

    if (title === null) {
        return <NoContentPage />;
    }

    return (
        <div className={classNames("form", "offer", style.imagesform)}>
            <div className="row">
                <h1>{localization["ManageOfferFiles"]}</h1>
            </div>

            <div className={style.filesheading}>

                <div className={style.u}>
                    <button className={style.addimagebtn} onClick={() => PubSub.publish(PubSubTopic.Component + ".uploader", { event: "uploader-browse" })}>
                        <FontAwesomeIcon icon={"plus"} />
                        <FontAwesomeIcon icon={"image"} />
                    </button>
                </div>
            </div>

            <div className={style.imagecontainer}>

                <p>{localization["imageChangeRulesInfo"]}</p>
            
                {
                    !IsNullOrWhiteSpace(lastError) &&
                    <div className={classNames(style.notification, style.error)}>
                        <p>{lastError}</p>
                    </div>
                }

                <Uploader
                    url={`${app.apiBasePath}/upload/image`}
                    supportedFiles={SupportedFiles.Images}
                    maxFileSizeMB={22}
                    uploadMetadata={{ storageType: StorageType.OfferDemand }}
                    multiSelect={true}
                    monitorDispach={true}
                    progressBarId={progressBarId}
                    onBeforeUpload={(file: any) => { }}
                    onFileUploaded={(data: UploadResponse) => {
                        addAttachmentToOffer(data.fileId);
                    }}
                    onUploadProgress={(file: any) => { }}
                    onError={(error: UploaderError) => {
                        //console.log(error);
                        if (IsUndefinedOrNull(error.file)) {
                            setLastError(`${error.message}`);
                        }
                        else {
                            if (error.code === -600) {
                                setLastError(localization["fileSizeError"]);
                            }
                            else {
                                setLastError(`${error.message} ${error.file.name}`);
                            }
                        }
                        setTimeout(() => {
                            setLastError("");
                        }, 6000);
                    }}
                />

                <ProgressBar domId={progressBarId} />

                <div className={style.overflow}>
                    {
                        false &&
                        <div className={style.images}>
                            {
                                files.map((file: Attachment, idx: number) => {
                                    return (
                                        <div
                                            className={style.image}
                                            key={file.fileId}>
                                            <div className={style.h}>
                                                <FontAwesomeIcon onClick={() => removeImage(file.fileId)} icon={"trash"} />
                                            </div>
                                            <img src={`${app.apiBasePath}/assets/image/thumb/${file.fileId}`} alt={`loading ${file.fileId}`} />
                                        </div>);
                                })
                            }
                        </div>
                    }

                    <div className={style.images}>
                        <ReactSortable onUpdate={imageOrderChange} list={files} setList={list => updateFilesOrder(list)}>
                            {
                                files.map((file: SortableImage, idx: number) => {
                                    return (
                                        <div
                                            className={style.image}
                                            key={file.fileId}>
                                            <div className={style.h}>
                                                <FontAwesomeIcon onClick={() => removeImage(file.fileId)} icon={"trash"} />
                                            </div>
                                            <img src={`${app.apiBasePath}/assets/image/thumb/${file.fileId}`} alt={`loading ${file.fileId}`} />
                                        </div>);
                                })
                            }
                        </ReactSortable>
                    </div>
                </div>



            </div>
            <section className={style.buttons2}>
                <button className={classNames(style.returnbtn, "btn", "action")} onClick={navigateBack}>
                    <FontAwesomeIcon className="before" icon={"chevron-left"} />
                    <span>{localization["return"]}</span>
                </button>
            </section>
        </div>

    );
}
