import { ReactElement, useEffect, useState } from "react";
import Select from "react-select";
import classNames from "classnames";
import { app } from "../../..";
import { CountryOptionType, OptionType } from "../../../models/OptionType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { LocalizationContext } from "../../../interfaces/AppContext";
import { useContext } from "react"
import { apiRequests } from "../../../requests/apiRequests";
import { adminStore } from "../../../store/adminStore";
import { appStore } from "../../../store/appStore";
import { IsNullOrWhiteSpace } from "../../../misc/Utilities";
import { adminRequests } from "../../../requests/adminRequests";
import { LocationType } from "../../../models/LocationType";
import style from "./groupmanage.module.scss";
import { AutocompleteGroup } from "../../../components/Autocomplete/Groups/AutocompleteGroup";
import { GroupPath } from "../../../models/GroupPath";
import cuid from "cuid";

export const GroupManagement = (): ReactElement => {

    const {
        selectedCountry,
        selectedProvince,
        selectedRegion,
        provincesCache,
        regionsCache,
        groupsCache,

        update } = adminStore(state => state);

    const { person, countryOptions } = appStore(state => state);
    const { GetChildLocations } = apiRequests(app.apiBasePath);
    const { AddLocation } = adminRequests(app.apiBasePath);


    const [level2loading, setLevel2Loading] = useState<boolean>(false);
    const [level3loading, setLevel3Loading] = useState<boolean>(false);
    const [level4loading, setLevel4Loading] = useState<boolean>(false);

    const [addProvinceEnabled, setAddProvinceEnabled] = useState<boolean>(false);
    const [addRegionEnabled, setAddRegionEnabled] = useState<boolean>(false);
    const [newRegionName, setNewRegionName] = useState<string>("");
    const [newProvinceName, setNewProvinceName] = useState<string>("");
    const [addingProvince, setAddingProvince] = useState<boolean>(false);
    const [addingRegion, setAddingRegion] = useState<boolean>(false);

    const [autocompleteKey, setAutocompleteKey] = useState<string>("dsfds2342ff");

    const { localization } = useContext(LocalizationContext);

    const nav = useNavigate();

    const onCountryChange = async (opt: CountryOptionType) => {
        update("selectedCountry", opt);
        update("selectedProvince", null);
        update("selectedRegion", null);
        update("regionsCache", []);

        setLevel2Loading(true);
        GetChildLocations(opt.value as string).then(result => {
            setLevel2Loading(false);
            const provinces = result.map(location => {
                return {
                    value: location.id,
                    label: location.name
                } as OptionType
            });

            update("provincesCache", provinces);
            if (provinces.length === 1) {
                onProvinceChange(provinces[0]);
            }
        });
    };

    const onProvinceChange = async (opt: OptionType) => {
        update("selectedProvince", opt);
        update("selectedRegion", null);

        setLevel3Loading(true);
        GetChildLocations(opt.value as string).then(result => {
            setLevel3Loading(false);
            const regions = result.map(location => {
                return {
                    value: location.id,
                    label: location.name
                } as OptionType
            });

            update("regionsCache", regions);
            if (regions.length === 1) {
                onRegionChange(regions[0]);
            }
        });
    }

    const onRegionChange = async (opt: OptionType) => {
        update("selectedRegion", opt);
        setLevel4Loading(true);

    }

    const locationNameValid = (name: string): boolean => {
        const regex = /^[)(\]\[a-zA-ZÀ-ÿ0-9' -]{3,}$/;
        return regex.test(name.trim());
    }

    const saveProvince = async (): Promise<void> => {
        setAddingProvince(true);
        const result = await AddLocation(newProvinceName.trim(), LocationType.Province, selectedCountry.value as string);
        if (!IsNullOrWhiteSpace(result)) {
            const newProvince = {
                value: result,
                label: newProvinceName
            } as OptionType;

            update("provincesCache", [...provincesCache, newProvince]);
            setNewProvinceName("");
            setAddingProvince(false);
            setAddProvinceEnabled(false);
        }
    }

    const saveRegion = async (): Promise<void> => {
        setAddingRegion(true);
        const result = await AddLocation(newRegionName.trim(), LocationType.Region, selectedProvince.value as string);
        if (!IsNullOrWhiteSpace(result)) {
            const newRegion = {
                value: result,
                label: newRegionName
            } as OptionType;
            update("regionsCache", [...regionsCache, newRegion]);
            setNewRegionName("");
            setAddingRegion(false);
            setAddRegionEnabled(false);
        }
    }

    const preselectFormOptionsBasedOnAutocompleteResults = async (groupPath: GroupPath): Promise<void> => {
        const instanceId = cuid();
        setAutocompleteKey(instanceId);

        const countryOption = countryOptions.find(c => c.value === groupPath.country.id);

        const provinces = await GetChildLocations(countryOption.value as string);

        const provinceOptions = provinces.map(location => {
            return {
                value: location.id,
                label: location.name
            } as OptionType
        });

        const provinceOption = provinceOptions.find(p => p.value === groupPath.province.id);

        const regions = await GetChildLocations(provinceOption.value as string);

        const regionOptions = regions.map(location => {
            return {
                value: location.id,
                label: location.name
            } as OptionType
        });

        const regionOption = regionOptions.find(r => r.value === groupPath.region.id);
      
        update("provincesCache", provinceOptions);
        update("regionsCache", regionOptions);
        update("selectedCountry", countryOption);
        update("selectedProvince", provinceOption);
        onRegionChange(regionOption);
    }

    useEffect(() => {
        if (selectedCountry === null && countryOptions.length > 0) {

            const option = countryOptions.find(c => c.countryCode === person?.countryCode);
            if (option) {
                onCountryChange(option);
            }
        }
    }, []);

    useEffect(() => {

        if (selectedRegion !== null) {
            GetChildLocations(selectedRegion.value as string).then(result => {
                setLevel4Loading(false);
                const groups = result.map(location => {
                    return {
                        value: location.id,
                        label: location.name
                    } as OptionType
                });
                update("groupsCache", groups);
                update("selectedRegion", selectedRegion);
            });
        }

    }, [selectedCountry, selectedProvince, selectedRegion]);

    return (
        <div className={style.container}>
            <div className="form">
                <h1>{localization["groupManagement"]}</h1>
            </div>



            <div className={classNames("form", style.groupform)}>


                <div className="row">
                    <span>{localization["groupSearchByName"]}</span>
                    <AutocompleteGroup key={autocompleteKey} onGroupSelect={(group: GroupPath) => preselectFormOptionsBasedOnAutocompleteResults(group)} />
                </div>


                <div className="row">
                    <label>{localization["countryLabel"]}</label>
                    <Select
                        value={selectedCountry}
                        onChange={opt => onCountryChange(opt)}
                        options={countryOptions} />
                </div>
                <div className="row">
                    <div className={style.labelgroup}><label>{localization["provinceLabel"]}</label>
                        {
                            !addProvinceEnabled &&
                            <button onClick={() => setAddProvinceEnabled(true)} className="btn action sm">
                                <FontAwesomeIcon icon="plus" />
                            </button>
                        }
                    </div>
                    {
                        addProvinceEnabled &&
                        <div className={classNames("row", style.inputgroup)}>
                            <input
                                type="text"
                                disabled={addingProvince}
                                onChange={e => setNewProvinceName(e.target.value)}
                                placeholder="Nom de la province"
                                className="form-control" />
                            <button disabled={addingProvince} onClick={() => { setNewProvinceName(""); setAddProvinceEnabled(false) }} className="btn cancel sm">
                                <FontAwesomeIcon icon="x" />
                            </button>
                            <button onClick={() => saveProvince()} disabled={!locationNameValid(newProvinceName) || addingProvince} className="btn save sm">
                                <FontAwesomeIcon icon="check" />
                            </button>
                        </div>
                    }
                    <Select
                        value={selectedProvince}
                        options={provincesCache}
                        onChange={opt => onProvinceChange(opt)} />
                </div>
                <div className="row">
                    <div className={style.labelgroup}><label>{localization["regionLabel"]}</label>
                        {
                            !addRegionEnabled &&
                            <button onClick={() => setAddRegionEnabled(true)} className="btn action sm">
                                <FontAwesomeIcon icon="plus" />
                            </button>
                        }
                    </div>
                    {
                        addRegionEnabled &&
                        <div className={classNames("row", style.inputgroup)}>
                            <input
                                type="text"
                                disabled={addingRegion}
                                onChange={e => setNewRegionName(e.target.value)}
                                placeholder="Nom de la région"
                                className="form-control" />
                            <button disabled={addingRegion} onClick={() => { setNewRegionName(""); setAddRegionEnabled(false) }} className="btn cancel sm">
                                <FontAwesomeIcon icon="x" />
                            </button>
                            <button onClick={() => saveRegion()} disabled={!locationNameValid(newRegionName) || addingRegion} className="btn save sm">
                                <FontAwesomeIcon icon="check" />
                            </button>
                        </div>
                    }
                    <Select
                        value={selectedRegion}
                        onChange={g => onRegionChange(g)}
                        options={regionsCache} />
                </div>

                {
                    selectedRegion !== null &&
                    <div className={style.collection}>
                        <div className={style.h}>
                            <h3>{localization["groupsInregion"]}: {selectedRegion.label}</h3>
                            <button onClick={() => nav(`/admin/groups/create/${selectedRegion.value}`)} className="btn save2">
                                <FontAwesomeIcon icon="plus" />
                                <span>ajouter un groupe</span>
                            </button>
                        </div>

                        <ul>
                            {
                                groupsCache.map(r => {
                                    return <li key={r.value}><Link to={`/admin/groups/manage/${r.value}`}>{r.label}</Link></li>
                                })
                            }
                        </ul>
                    </div>
                }
            </div>
        </div>
    );
};