import { Outlet } from 'react-router-dom';
import { Header } from '../../components/Header';
import { Menu } from '../../components/Menu';
import { MobileMenu } from '../../components/MobileMenu';
import { NavItem } from '../../interfaces/NavItem';
import { Footer } from '../../components/Footer';
import { FC, ReactElement, useEffect, useState } from 'react';
import style from "./master.module.scss";
import { NavitaionBuilder } from '../../store/NavigationBuilder';
import { Group } from '../../models/Group';
import { Person } from '../../models/Person';
import { Exchange } from '../../models/Exchange';
import { FavoriteExchange } from '../../models/FavoriteExchange';
import { HasPrivilege } from '../../misc/Utilities';
import { Role } from '../../models/Enums';
import { appStore } from '../../store/appStore';
import { apiRequests } from '../../requests/apiRequests';
import { app } from '../..';
import { useQuery } from '@tanstack/react-query';
import { apiKeys } from '../../queryKeys/apiKeys';
import { notificationStore } from '../../store/notificationStore';
import { isArray } from 'lodash';

export interface MasterProps {
    favoriteExchanges: FavoriteExchange[];
    mobileMenuVisible: boolean;
    onOpenMobileMenu: () => void;
    onCloseMobileMenu: () => void;
}

const Master: FC<MasterProps> = ({
    favoriteExchanges,
    onOpenMobileMenu,
    mobileMenuVisible,
    onCloseMobileMenu }): ReactElement => {

    const [navigation, setNavigation] = useState<Array<NavItem>>([]);

    const { exchanges, groups, person } = appStore(state => state);

    const { GetGroupInfoNotifications } = apiRequests(app.apiBasePath);

    const { update } = notificationStore();

    const {
        HomeMenuOptions,
        MyGroupsOptions,
        MyExchangesMenuOptions,
        ModeratorMenuOptions,
        DevMenuOptions,
        AdminMenuOptions
    } = NavitaionBuilder({
        exchanges: exchanges,
        groups: groups,
        person: person,
        favorites: favoriteExchanges
    });

    const buildNavMenu = (personInfo: Person, groups: Group[], exchanges: Exchange[], favoriteExchanges: FavoriteExchange[]): NavItem[] => {

        const nav = [HomeMenuOptions(), MyExchangesMenuOptions(), MyGroupsOptions()];

        const moderatorOptions = ModeratorMenuOptions();
        if (moderatorOptions !== null) {
            nav.push(moderatorOptions);
        }

        if (!HasPrivilege(personInfo.role, Role.Developper) &&
            (HasPrivilege(personInfo.role, Role.UserManagement) ||
                HasPrivilege(personInfo.role, Role.GroupManagement) ||
                HasPrivilege(personInfo.role, Role.CategoryManagement))) {
            nav.push(AdminMenuOptions());
        }
        else if (HasPrivilege(personInfo.role, Role.Developper)) {

            nav.push(AdminMenuOptions());
            nav.push(DevMenuOptions());
        }

        return nav;
    };


    const { data: groupsHavingNotifications, isLoading } = useQuery({
        queryFn: GetGroupInfoNotifications,
        queryKey: apiKeys.groupInfoNotifications
    });

    useEffect(() => {
        const nav = buildNavMenu(person, groups, exchanges, favoriteExchanges);
        setNavigation(nav);

    }, [person, groups, exchanges, favoriteExchanges]);

    useEffect(() => {
        if (!isLoading && isArray(groupsHavingNotifications)) {
            update("groupsHavingNotifications", groupsHavingNotifications);
        }
    }, [groupsHavingNotifications, isLoading]);

    return (
        <>
            <Header
                hideNotifications={true}
                showMobileMenu={onOpenMobileMenu}
            />

            <MobileMenu
                visible={mobileMenuVisible}
                close={onCloseMobileMenu}
                links={navigation} />

            <div className={style.container}>
                <div className={style.viewport}>
                    <div className={style.split}>
                        <div className={style.left}>
                            <Menu links={navigation} />
                        </div>
                        <div className={style.center}>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );

}

export { Master }