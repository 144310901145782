import classNames from "classnames";
import { ReactElement, useContext, useEffect, useState } from "react"
import { LocalizationContext } from "../../interfaces/AppContext";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { app } from "../..";
import { InputWarning } from "../../components/InputWarning";
import Loading from "react-loading";

import style from "./pwd.module.scss";
import { PasswordField } from "../../components/PasswordField/PasswordField";

export const PasswordResetForm = (): ReactElement => {

    const param = useParams();
    const code = param["code"];

    const { localization: v } = useContext(LocalizationContext);


    const [pwd1, setPWD1] = useState("");
    const [pwd2, setPWD2] = useState("");

    const [errorCode, setErrorCode] = useState("");

    const [trying, setTrying] = useState(false);

    const [canReset, setCanReset] = useState(true);

    const nav = useNavigate();

    useEffect(() => {

        const request = axios.post(`${app.apiBasePath}/public/pwd/testcode`, {
            code: code
        });

        request.then(result => {
            if (result.data?.pass !== true) {
                nav("/resetpwd");
            }
            else setCanReset(true);
        });

        request.catch(e => {

            console.warn(e, "failure");
        });

    }, [code]);


    const resetPassword = (): void => {
        if (trying) {
            return;
        }

        if (pwd1 !== pwd2) {
            setErrorCode("0x10");
            return;
        }


        setTrying(true);

        const request = axios.post(`${app.apiBasePath}/public/pwd/changepwd`, {
            newPassword: pwd1,
            code: code
        });

        request.then(result => {

            setTrying(false);

            if (result.data?.success === true) {
                nav("/");
            }
            else {
                setErrorCode(result.data.errorCode);
            }
        });

        request.catch(e => {
            setTrying(false);
            console.warn(e);
        });
    };


    if (!canReset) {
        return (
            <div className="page-content">
                <div className={classNames("form", "pwdreset", style.resetform)}>
                    <h1>{v["PasswordResetPageTitle"]}</h1>
                    <div className={style.loading}>
                        <Loading />
                    </div>
                </div>
            </div>
        );
    }

    return (

        <div className={classNames(style.container, "page-content")}>
            <div className={classNames("form", "pwdreset", style.resetform)}>
                <h1>{v["PasswordResetPageTitle"]}</h1>

                <div className="row">
                    <label>{v["TypeNewPassword"]}</label>

                    <PasswordField
                        disabled={trying}
                        value={pwd1}
                        warn={errorCode === "0x11"}
                        onChange={value => setPWD1(value)}
                        onEnterKeyPress={() => resetPassword()}
                    />
                    {
                        errorCode === "0x11" &&
                        <div className="row">
                            <InputWarning messageKey="PasswordNotStrong" />
                        </div>
                    }
                </div>

                <div className="row">
                    <label>{v["RetypeNewPassword"]}</label>

                    <PasswordField
                        disabled={trying}
                        warn={errorCode === "0x10"}
                        value={pwd2}
                        onChange={value => setPWD2(value)}
                        onEnterKeyPress={() => resetPassword()}
                    />
                    {
                        errorCode === "0x10" &&
                        <div className="row">
                            <InputWarning messageKey="PasswordsMustMatch" />
                        </div>
                    }

                </div>
                {
                    !trying &&
                    <div className={style.buttonrow}>
                        <button className="btn cancel" onClick={() => nav("/")}>{v["cancel"]}</button>
                        <button onClick={resetPassword} className="btn action">{v["Update"]}</button>
                    </div>
                }

                <div>
                    {
                        trying &&
                        <Loading />
                    }
                </div>
            </div>

        </div >
    );
}