import { ReactElement, useContext } from "react";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { LocalizationContext } from "../../interfaces/AppContext";
import { appStore } from "../../store/appStore";
import { IsUndefinedOrNull } from "../../misc/Utilities";
import classNames from "classnames";
import style from "./groupmanage.module.scss";

export const GroupManage = (): ReactElement => {

    const { groups } = appStore();

    const params = useParams();
    const groupId = params["groupid"];

    const group = groups.find(g => g.id === groupId);

    const { localization: vocab } = useContext(LocalizationContext);

    if (IsUndefinedOrNull(group)) {
        return <div className={style.container}><p>Group not found</p></div >;
    }

    return (
        <div className={style.container}>
            <div className={classNames(style.heading, "form")}>
                <h1>{vocab["manage"]} {group.name}</h1>
            </div>

            <section className={style.options}>
                <NavLink className={({ isActive }) => {
                    return isActive ? style.linkactive : null;
                }} to={`/group/manage/${groupId}/users`}>{vocab["users"]}</NavLink>

                <NavLink className={({ isActive }) => {
                    return isActive ? style.linkactive : null;
                }} to={`/group/manage/${groupId}/invitations`}>{vocab["invited"]}</NavLink>


                <NavLink className={({ isActive }) => {
                    return isActive ? style.linkactive : null;
                }} to={`/group/manage/${groupId}/publications`}>{vocab["publishTabName"]}</NavLink>

                <NavLink className={({ isActive }) => {
                    return isActive ? style.linkactive : null;
                }} to={`/group/manage/${groupId}/announcements`}>{vocab["announcementTabName"]}</NavLink>
                {
                    false &&
                    <NavLink className={({ isActive }) => {
                        return isActive ? style.linkactive : null;
                    }} to={`/group/manage/${groupId}/settings`}>{vocab["settings"]}</NavLink>

                }
            </section>
            <Outlet />
        </div>
    );
}