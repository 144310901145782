import { Autocomplete, TextField } from "@mui/material";
import { FC, ReactElement, useRef, useState } from "react";
import { GroupPath } from "../../../models/GroupPath";
import { apiRequests } from "../../../requests/apiRequests";
import { app } from "../../..";
import { StyledPaper } from "./StyledPaper";
import { PopperWithNoEmptyOption } from "../PopperWithNoEmptyOption";
import style from "./autogroups.module.scss";

export interface AutocompleteGroupProps {
    onGroupSelect: (group: GroupPath) => void;
}

export const AutocompleteGroup: FC<AutocompleteGroupProps> = ({ onGroupSelect }): ReactElement => {

    const { SearchGroupsNameContains } = apiRequests(app.apiBasePath);

    const [options, setOptions] = useState<readonly GroupPath[]>([]);

    const timeoutRef = useRef<number | null>(null);

    const onInputChange = async (event: React.SyntheticEvent, nextValue: string) => {

        if (nextValue.length === 0) {
            setOptions([]);
            return;
        }

        if (nextValue.length > 1) {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = window.setTimeout(async () => {
                const result = await SearchGroupsNameContains(nextValue);
                setOptions(result);
            }, 300);
        }
    };

    return (
        <Autocomplete<GroupPath>
            className={style.control}
            filterOptions={(x) => x}
            clearOnBlur={false}
            autoComplete
            includeInputInList
            filterSelectedOptions
            options={options}
            PaperComponent={props => <StyledPaper {...props} />}
            PopperComponent={props => <PopperWithNoEmptyOption {...props} />}
            isOptionEqualToValue={(option, value) => option.group.id === value.group.id}
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.group.name
            }

            onChange={(event: any, newValue: GroupPath | null) => {
                if (newValue !== null) {
                    onGroupSelect && onGroupSelect(newValue);
                }
            }}

            renderInput={(params) => (
                <TextField
                    {...params}
                    label={""}
                    fullWidth />
            )}
            sx={
                {
                    '& .MuiOutlinedInput-root': {
                        padding: '1px 0',
                        border: '1px solid #bbb',
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        border: 'none',
                    }
                }
            }
            renderOption={(props, option: GroupPath) => {
                return (
                    <li className={style.option} {...props} key={props['key']}>
                        <span className="gpname">{option.group.name}</span>
                        <span className="metadata">
                            (
                            <span>{option.country.name}</span>
                            <span className="separator">|</span>
                            <span>{option.province.name}</span>
                            <span className="separator">|</span>
                            <span>{option.region.name}</span>
                            )
                        </span>


                    </li>
                );
            }}


            onInputChange={(event: React.SyntheticEvent, nextValue: string) => onInputChange(event, nextValue)}
        />
    )
}