import axios from "axios";
import classNames from "classnames";
import { ReactElement, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ErrorArg, useForm, ValidationMethods } from "../../../hooks/UseForm";
import { LocalizationContext } from "../../../interfaces/AppContext";
import { EmailValidationPattern } from "../../../misc/Constants";
import { app } from "../../..";
import { KeyValuePair } from "../../../models/KeyValuePair";
import { InputWarning } from "../../../components/InputWarning";
import { StringIsNullOrEmpty } from "../../../misc/Utilities";
import { groupRequests } from "../../../requests/groupRequests";
import { useQuery } from "@tanstack/react-query";
import { groupKeys } from "../../../queryKeys/groupKeys";

import { adminRequests } from "../../../requests/adminRequests";
import { VerifyInviteeByEmailResult } from "../../../models/VerifyInviteeByEmailResult";
import { NotificationType, NotificationView } from "../../../components/NotificationView";
import style from "./invitation-form.module.scss";
import Loading from "react-loading";

type ErrorCodeType = "user-already-member";

interface FormModel {
    email: string;
    firstName: string;
    lastName: string;
    existingAccount: boolean;
    memberOfGroup: boolean;
    InvitationCode: string;
}

enum InvitationFormValidators {
    email,
    firstName,
    lastName
}

interface InviteMemberResult extends ExistingUserInfo {
    errorCode: string | null;
    shortCode: string;
}

type ExistingUserInfo = {
    groupNames?: KeyValuePair[];
    firstName?: string;
    lastName?: string;
    personId?: string;
};

export const InvitationFormAdmin = (): ReactElement => {

    const params = useParams();
    const groupId = params["groupid"];
    const nav = useNavigate();
    const { localization: vocab } = useContext(LocalizationContext);

    const { GetGroupById } = groupRequests(app.apiBasePath);

    const { VerifyInviteeByEmail } = adminRequests(app.apiBasePath);

    const { data: groupInfo, isLoading: groupInfoLoading } = useQuery({
        queryKey: groupKeys.byId(groupId),
        queryFn: () => GetGroupById(groupId),
        enabled: groupId !== null
    });

    const [emailCheckPass, setEmailCheckPass] = useState<boolean | null>(null);
    const [duplicateInvitation, setDuplicateInvitation] = useState<boolean | null>(null);

    const [errorCode, setErrorCode] = useState<ErrorCodeType | null>(null);

   
    const [sending, setSending] = useState(false);

    const initModel: FormModel = {
        email: "",
        firstName: "",
        lastName: "",
        existingAccount: false,
        memberOfGroup: false,
        InvitationCode: ""
    };

    const formValidators: ValidationMethods<FormModel>[] = [
        {
            validator: InvitationFormValidators.email,
            use: (model: FormModel): ErrorArg => {
                return EmailValidationPattern.test(model.email) ?
                    { isValid: true, message: "" } :
                    { isValid: false, message: "email" };
            }
        },
        {
            validator: InvitationFormValidators.firstName,
            use: (model: FormModel): ErrorArg => {

                return model.firstName.trim().length === 0 ?
                    { isValid: false, message: "first-name" }
                    : { isValid: true, message: "" };
            }
        },
        {
            validator: InvitationFormValidators.lastName,
            use: (model: FormModel): ErrorArg => {

                return model.lastName.trim().length === 0 ?

                    { isValid: false, message: "last-name" } : { isValid: true, message: "" };
            }
        }
    ];

    const { values, isValid, valueSetter, error } =
        useForm<FormModel, InvitationFormValidators>(initModel, formValidators);

    const checkAccountExists = (): void => {
        if (!StringIsNullOrEmpty(values.email?.trim())) {
            VerifyInviteeByEmail(groupId, values.email).then((result: VerifyInviteeByEmailResult) => {

                valueSetter([
                    {
                        name: "lastName",
                        value: result.lastName ?? ""
                    },
                    {
                        name: "firstName",
                        value: result.firstName ?? ""
                    },
                    {
                        name: "existingAccount",
                        value: result.userExist
                    },
                    {
                        name: "memberOfGroup",
                        value: result.userMemberOfGroup
                    },
                    {
                        name: "InvitationCode",
                        value: result.invitationCode ?? ""
                    }
                ]);

            });
        }
    };

    const inviteUser = (): void => {

        setSending(true);

        const dto = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            groupId: groupId
        };

        const request = axios.post(`${app.apiBasePath}/admin/users/invitations/${groupId}/invitememeber`, dto);

        request.then(result => {

            setSending(false);

            const { errorCode } = result.data as InviteMemberResult;

            if (!StringIsNullOrEmpty(errorCode)) {
                setErrorCode(errorCode as ErrorCodeType);
            }
        });
    };

    if (groupInfoLoading) {
       <Loading />
    }

    return (

        <div className={style.container}>
            <h3>{vocab["makeInvitation"]}  [{groupInfo?.name}]</h3>

            <div className={classNames(style.form, "form")}>

                <div className="row">
                    <label>{vocab["EmailAddress"]}</label>
                    <input
                        value={values.email}
                        onBlur={() => checkAccountExists()}
                        onChange={e => {
                            valueSetter([{ name: "email", value: e.target.value }, { name: "existingAccount", value: false }])
                        }}
                        className={classNames(
                            "form-control",
                            error(InvitationFormValidators.email).isValid ? null : "validation-warning",
                            duplicateInvitation === false ? "validation-warning" : null)
                        }
                        type="text" />

                    {
                        emailCheckPass === false &&
                        <InputWarning messageKey="emailActiveAlready" />
                    }
                    {
                        duplicateInvitation === false &&
                        <InputWarning messageKey="userAlreadyInvited" />
                    }
                </div>

                {
                    values.existingAccount &&
                    !values.memberOfGroup &&
                    StringIsNullOrEmpty(values.InvitationCode) &&
                    <div className={style.match}>
                        <p>Compte existe déjà.</p>
                        <button onClick={inviteUser} className="btn action">Inviter</button>
                        <span>{values.firstName} {values.lastName} à joindre {groupInfo?.name}</span>
                    </div>
                }
                {
                    values.existingAccount &&
                    !values.memberOfGroup &&
                    !StringIsNullOrEmpty(values.InvitationCode) &&
                    <NotificationView type={NotificationType.Warning}>
                        <p>{values.firstName} {values.lastName} déjà invité: {values.InvitationCode}</p>
                    </NotificationView>
                }
                {
                    values.existingAccount &&
                    values.memberOfGroup &&
                    StringIsNullOrEmpty(values.InvitationCode) &&

                    <div className={style.match}>
                        <NotificationView type={NotificationType.Warning}>
                            <p>{values.firstName} {values.lastName} déjà membre de {groupInfo?.name}</p>
                        </NotificationView>
                    </div>
                }

                {
                    !values.existingAccount &&
                    <>
                        <div className="row">
                            <label>{vocab["FirstName"]}</label>
                            <input
                                value={values.firstName}
                                onChange={e => valueSetter({ name: "firstName", value: e.target.value })}
                                className={classNames("form-control", error(InvitationFormValidators.firstName).isValid ? null : "validation-warning")}
                                type="text" />

                            {
                                !error(InvitationFormValidators.firstName).isValid &&
                                <InputWarning messageKey="firstNameRequired" />
                            }
                        </div>
                        <div className="row">
                            <label>{vocab["LastName"]}</label>
                            <input
                                value={values.lastName}
                                onChange={e => valueSetter({ name: "lastName", value: e.target.value })}
                                className={classNames("form-control", error(InvitationFormValidators.lastName).isValid ? null : "validation-warning")}
                                type="text" />

                            {
                                !error(InvitationFormValidators.lastName).isValid &&
                                <InputWarning messageKey="lastNameRequired" />
                            }
                        </div>
                        <hr />
                        <div className="row buttons right">
                            <button onClick={() => nav(-1 as any)} className="btn cancel">
                                <span>{vocab["cancel"]}</span>
                            </button>
                            <button disabled={!isValid || sending} onClick={inviteUser} className="btn action">
                                <span>{vocab["Send"]}</span>
                            </button>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}