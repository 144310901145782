import axios, { HttpStatusCode } from "axios";
import { ExchangeDetails } from "../models/ExchangeDetails";
import { StringIsNullOrEmpty } from "../misc/Utilities";
import { Exchange } from "../models/Exchange"; 

export interface ExchangeRequests {
    GetExchangeDetailsById(id: string): Promise<ExchangeDetails | null>;
    GetExchangeById(id: string): Promise<Exchange | null>;
    DeleteExchange(exchangeId: string): Promise<boolean>;
    UnpublishExchange(exchangeId: string): Promise<boolean>;
    PublishExchange(exchangeId: string): Promise<boolean>;
}

export const exchangeRequests = (apiPath: string): ExchangeRequests => {

    const getExchangeDetailsById = async (id: string): Promise<ExchangeDetails | null> => {

        if (StringIsNullOrEmpty(id)) {
            return null;
        }

        const request = await axios.get<ExchangeDetails>(`${apiPath}/exchange/view/${id}`);

        if (request.status === HttpStatusCode.Ok) {
            return request.data;
        }
        else return null;
    }

    const getExchangeById = async (id: string): Promise<Exchange | null> => {

        if (StringIsNullOrEmpty(id)) {
            return null;
        }

        const request = await axios.get<Exchange>(`${apiPath}/exchange/${id}`);

        if (request.status === HttpStatusCode.Ok) {
            return request.data;
        }
        else return null;
    }

    const deleteExchange = async (exchangeId: string): Promise<boolean> => {
        const deleteRequest = await axios.delete(`${apiPath}/exchange/${exchangeId}`);

        try {
            if (deleteRequest.status === HttpStatusCode.Ok) {
                return true;
            }
            else return false;
        }
        catch {
            return false;
        }
    }

    const unpublishExchange = async (exchangeId: string): Promise<boolean> => {
        if(StringIsNullOrEmpty(exchangeId)) {
            return false;
        }
        const result = await axios.post(`${apiPath}/exchange/unpublish`, { exchangeId: exchangeId });
        return result.status === HttpStatusCode.Ok; 
    }

    const publishExchange = async (exchangeId: string): Promise<boolean> => {
        const response = await axios.post(`${apiPath}/exchange/setpublished`, {
            exchangeid: exchangeId
        });

        return response.status === HttpStatusCode.Ok;
    }

    return {
        GetExchangeDetailsById: getExchangeDetailsById,
        GetExchangeById: getExchangeById,
        DeleteExchange: deleteExchange,
        UnpublishExchange: unpublishExchange,
        PublishExchange: publishExchange
    }
}

