import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FC, ReactElement, useState } from "react";

import { StringIsNullOrEmpty } from "../../misc/Utilities";
import { faStar as star1 } from '@fortawesome/free-regular-svg-icons';
import { faStar as star2 } from '@fortawesome/free-solid-svg-icons';
import style from "./favorites.module.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addFavorite, removeFavorite } from "../../requests/favoriteRequests";
import { appKeys } from "../../queryKeys/appKeys";
import ReactLoading from "react-loading";

export interface FavoritesProps {
    isSet: boolean;
    resourceId: string;
    title?: string;
}

export const Favorites: FC<FavoritesProps> = ({ isSet, resourceId, title }): ReactElement => {

    const updater = isSet ? removeFavorite : addFavorite;

    const queryClient = useQueryClient();

    const favoritesUpdater = useMutation({
        mutationFn: () => updater(resourceId),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: appKeys.favorites
            });
        }
    });

    if (favoritesUpdater.isPending) {
        return <ReactLoading type={"spin"} color={"#5270F0"} height={16} width={16} />
    }

    return (
        <FontAwesomeIcon
            title={StringIsNullOrEmpty(title) ? "favoris" : title}
            onClick={() => favoritesUpdater.mutate()}
            className={classNames(style.fav, isSet ? style.set : null)}
            icon={isSet ? star2 : star1} />
    );
};