import { Paper } from "@mui/material";
import { ReactElement } from "react";

export const StyledPaper = (props): ReactElement => <Paper sx={{
    'ul': {
        padding: 0
    },
    'li': {
        padding: '10px',
        borderBottom: '1px solid #e5e5e5',
    },
    'li:last-child': {
        borderBottom: 'none',
    },
    'li:hover': {
        backgroundColor: '#D8F1F1',
    },
    'span.gpname': {
        color:'#1E4586',
        fontWeight: 'bold',
    },
    'span.metadata' : {
        fontStyle: 'italic',
        fontSize: '0.8em',
        color: '#777',
        marginLeft: '10px',
    },
    'span.separator': {
        margin: '0 3px'
    },
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
}} {...props} />