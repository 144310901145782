import { ReactElement, useMemo } from "react";
import { Outlet, useParams } from "react-router";
import { appStore } from "../../store/appStore";
import { NormalizeToUrlName } from "../../misc/Utilities";
import { Group } from "../../models/Group";
import { searchRequests } from "../../requests/searchRequests";
import { app } from "../..";
import { useQuery } from "@tanstack/react-query";
import { exchangeKeys } from "../../queryKeys/exchangeKeys";
import { Loading } from "../../components/Loading";
import { apiRequests } from "../../requests/apiRequests";
import { apiKeys } from "../../queryKeys/apiKeys";
import { GroupPerson } from "../../models/GroupPerson";

import { NavLink } from "react-router-dom";
import style from "./group.module.scss";
import { SearchResultModel } from "../../models/SearchResultModel";
import classNames from "classnames";
import { notificationStore } from "../../store/notificationStore";


export interface GroupPageContext {
    exchanges: SearchResultModel[];
    groupInfo: Group;
    people: GroupPerson[];
}

export const GroupPage = (): ReactElement => {

    const { groupname } = useParams();

    const { groups } = appStore(store => store);

    const { GetGroupExchange } = searchRequests(app.apiBasePath);
    const { GetGroupPeople } = apiRequests(app.apiBasePath);
    const { groupsHavingNotifications } = notificationStore(store => store);

    const findGroupByParametrizedName = (groups: Group[], name: string): Group | null => {
        return groups.find(g => NormalizeToUrlName(g.name) === name) || null;
    }

    const group = useMemo(() => {
        return findGroupByParametrizedName(groups, groupname);
    }, [groups, groupname]);


    const { data: exchanges, isLoading: exchangesLoading } = useQuery({
        queryKey: exchangeKeys.getExchangesByGroupId(group?.id),
        queryFn: () => GetGroupExchange(group?.id),
        enabled: !!group
    });

    const { data: people, isLoading: isPeopleLoading } = useQuery({
        queryKey: apiKeys.groupPeople(group?.id),
        queryFn: () => GetGroupPeople(group?.id),
        enabled: !!group
    });

    const offerCount = useMemo(() => exchanges?.filter(e => e.exchangeType === "offer").length, [exchanges]);
    const demandCount = useMemo(() => exchanges?.filter(e => e.exchangeType === "demande").length, [exchanges]);

    if (!group) {
        return <div>Group not found</div>;
    }

    if (exchangesLoading || isPeopleLoading) {
        return <Loading />;
    }

    const childPageContext = {
        exchanges: exchanges,
        groupInfo: group,
        people: people
    } as GroupPageContext;



    return (
        <div className={style.page}>
            <div className={style.heading}>
                <nav>
                    <NavLink className={({ isActive }) => isActive ? classNames(style.active, style.o) : style.o}
                        to={`/g/${groupname}/offers`}>
                        <div><span>Offres</span> <mark><span>{offerCount}</span></mark></div></NavLink>

                    <NavLink className={({ isActive }) => isActive ? classNames(style.active, style.d) : style.d}
                        to={`/g/${groupname}/demands`}>
                        <div><span>Demandes</span> <mark><span>{demandCount}</span></mark></div></NavLink>

                    <NavLink className={({ isActive }) => isActive ? style.active : null}
                        to={`/g/${groupname}/info`}>
                        <div>
                            <span>Info</span>
                            {
                                groupsHavingNotifications.includes(group.id) &&
                                <mark className={style.dot}></mark>
                            }
                        </div></NavLink>
                </nav>
            </div>
            <Outlet context={childPageContext} />
        </div>
    );
}