import { create } from "zustand";
import { Location } from "../models/Location";
import { CountryOptionType, OptionType } from "../models/OptionType";

export type AdminUsersStore = {

    searchQuery: string;
    selectedCountry: CountryOptionType | null;
    selectedProvince: OptionType | null;
    selectedRegion: OptionType | null;
    selectedGroup: OptionType | null;
    stateFilters: OptionType[];
    textSearchValue: string;    
    provincesCache: Array<OptionType>;
    regionsCache: Array<OptionType>;
    groupsCache: Array<OptionType>;

    update?: <K extends keyof Omit<AdminUsersStore, "update">>(key: K, value: AdminUsersStore[K]) => void;
}

export const adminUsersStore = create<AdminUsersStore>((set) => ({
    searchQuery: "",
    selectedCountry: null,
    selectedProvince: null,
    selectedRegion: null,
    selectedGroup: null,
    stateFilters: [],
    textSearchValue: "",
    provincesCache: [],
    regionsCache: [],
    groupsCache: [],
    update: (key, value) => set((state) => ({ [key]: value }))
}));