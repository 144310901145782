import axios from "axios";
import { StatusCodes } from "http-status-codes";
import { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { app } from "../..";
import { LocalizationContext } from "../../interfaces/AppContext";
import classNames from "classnames";
import { InputWarning } from "../../components/InputWarning";
import { PwdValidState } from "../../models/Enums";
import { IsNullOrWhiteSpace, TestPasswordComplexity } from "../../misc/Utilities";
import { NotificationType, NotificationView } from "../../components/NotificationView";
import { Link } from "react-router-dom";
import { CodeCheckResult } from "../Welcome/WelcomePage";
import Loading from "react-loading";

import style from "./setup.module.scss";



export const SetupAccount = (): ReactElement => {

    const nav = useNavigate();
    const [busy, setBusy] = useState(false);
    const [pwd, setPWD] = useState("");
    const [pwd2, setPWD2] = useState("");


    const [newPwdValState, setNewPwdValState] = useState(PwdValidState.InitialState);
    const [newPwd2ValState, setNewPwd2ValState] = useState(PwdValidState.InitialState);

    const { code } = useParams();


    const [error, setError] = useState("");
    const [errorCode, setErrorCode] = useState<string>(null);

    const [inv, setINV] = useState(null as CodeCheckResult);

    const { localization } = useContext(LocalizationContext);
    const v = localization;

    useEffect(() => {

        verifyCode();

    }, [code]);


    const verifyCode = async (): Promise<void> => {

        setBusy(true);

        try {
            const result = await axios.post(`${app.apiBasePath}/public/i/codecheck`, {
                code: code
            });

            setBusy(false);

            if (result.status === StatusCodes.BAD_REQUEST || result.data.pass === false) {
                nav("/bienvenue");
            }

            else {
                setINV(result.data);
            }
        }
        catch (e: any) {
            setBusy(false);
            nav("/bienvenue");
        }
    };


    const onPwdSet = (pw: string): void => {

        setPWD(pw);

        const pass = TestPasswordComplexity(pw);
        if (!pass) {
            setNewPwdValState(PwdValidState.LowComplexity);
        }
        else {
            setNewPwdValState(PwdValidState.Ok);
        }
    };

    const onPwdSet2 = (pw: string): void => {

        setPWD2(pw);

        if (pw !== pwd) {
            setNewPwd2ValState(PwdValidState.MustNotEqualOld);
        }
        else {
            setNewPwd2ValState(PwdValidState.Ok);
        }
    };

    const onContinue = (): void => {

        if (inv.existingAccount) {
            login();
        }
        else {
            setBusy(true);

            const response = axios.post(`${app.apiBasePath}/public/i/coderedeem`, {
                password: pwd,
                code: code
            }, {
                validateStatus: s => s < 401
            });

            response.then(e => {
                setBusy(false);

                if (e.status === StatusCodes.BAD_REQUEST) {
                    nav(-1);
                }
                else {
                    window.location.href = "/profile/edit";
                }

            });
        }
    };

    const login = (): void => {

    
        let result = axios.post(`${app.apiBasePath}/auth/login`, {
            username: inv.email,
            password: pwd
        });

        result.then(d => {

    

            if (!IsNullOrWhiteSpace(d.data.error)) {
                if (d.data.error === "login-fail") {
                    setError(v["AuthFailureMsg"]);
                }
                else if (d.data.error === "email-confirm") {
                    setError(v["EmailConfirmRequired"]);
                }
                if (!IsNullOrWhiteSpace(d.data.code)) {
                    setErrorCode(d.data.code);
                }
                else {
                    setErrorCode(null);
                }

            }
            else {

                // auth ok
                window.location.href = "/join/" + code
            }
        });



    };


    const continueButtonEnabled = (existingAccount: boolean): boolean => {
        if (busy) return true;
        if (!existingAccount) {
            return newPwdValState !== PwdValidState.Ok || newPwd2ValState !== PwdValidState.Ok;
        }
        else return pwd.trim().length === 0;
    }

    return (
        <div className={style.container}>
            {
                busy &&
                <Loading />
            }
            {
                inv !== null &&
                <div className={classNames("form", style.form)}>
                    <h1>Bonjour {inv.firstName}</h1>
                    <h5>Tu es invité à te joindre au groupe <strong>{inv.groupName}</strong></h5>

                    {
                        inv.existingAccount &&

                        <div className={style.row}>
                            <label>{v["Password"]}</label>
                            <input
                                disabled={busy}
                                type="password"
                                value={pwd}
                                onChange={e => onPwdSet(e.target.value)}
                                className={"form-control"} />

                            {
                                error !== '' &&
                                <div className={style.warning}>
                                    <NotificationView type={NotificationType.Warning}>
                                        <p>{error}</p>
                                        {
                                            errorCode !== null &&
                                            <p style={{ marginLeft: "15px", color: "#D81A59" }}>{v[errorCode]}</p>
                                        }
                                    </NotificationView>
                                </div>
                            }
                        </div>
                    }
                    
                    {
                        !inv.existingAccount &&
                        <>
                            <div className={style.row}>
                                <label>{v["choosePassword"]}</label>
                                <p>{v["PasswordNotStrong"]}</p>
                                <input
                                    disabled={busy}
                                    type="password"
                                    value={pwd}
                                    onChange={e => onPwdSet(e.target.value)}
                                    className={classNames("form-control", newPwdValState === PwdValidState.LowComplexity ? "validation-warning" : null)} />

                                {
                                    false &&
                                    <div className={style.row}>
                                        <InputWarning messageKey="PasswordNotStrong" />
                                    </div>
                                }
                            </div>
                            <div className={style.row}>
                                <label>{v["RetypeNewPassword"]}</label>
                                <input
                                    value={pwd2}
                                    onChange={e => onPwdSet2(e.target.value)}
                                    disabled={busy}
                                    type="password"
                                    className="form-control" />
                                {
                                    newPwd2ValState === PwdValidState.MustNotEqualOld &&
                                    <InputWarning messageKey="PasswordsMustMatch" />
                                }
                            </div>
                        </>
                    }


                    <div className={classNames(style.row, style.buttons)}>
                        <Link className="btn action" to="/">{localization["cancel"]}</Link>
                        <button
                            disabled={continueButtonEnabled(inv.existingAccount)}
                            onClick={onContinue}
                            className="btn action">
                            {v["NextButton"]}
                        </button>
                    </div>
                </div>
            }

        </div>
    );
};