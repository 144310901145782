import { FC, ReactElement, useContext } from "react";
import { SearchResultModel } from "../../models/SearchResultModel";
import { LocalizationContext } from "../../interfaces/AppContext";
import { Result } from "./Result";
import style from "./search-results.module.scss";

export interface SearchResultsProps {
    results: SearchResultModel[];
    radiusAvailable: boolean;
}

export const SearchResults: FC<SearchResultsProps> = ({ results, radiusAvailable }): ReactElement => {

    const { localization } = useContext(LocalizationContext);
    return (
        <div className={style.results}>
            {
                results?.map(p => {
                    return <Result radiusAvailable={radiusAvailable} key={p.exchange.uniqueId} model={p} />
                })
            }
            {
                results !== null &&
                results.length === 0 &&
                <div className={style.nothing}>
                    <p>{localization["noResults"]}</p>
                </div>
            }
        </div>
    );
};