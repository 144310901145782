import { ReactElement, useState } from "react";
import { app } from "../..";
import { UserManifestReport } from "../../models/UserManifestReport";
import { UserManifestView } from "./UserManifestView";
import { Category } from "../../models/Category";
import ReactLoading from "react-loading";
import style from "./userdebug.module.scss"
import { adminRequests } from "../../requests/adminRequests";
import { AutocompleteUsers } from "../../components/Autocomplete/Groups/AutocompleteUsers";
import ClearIcon from '@mui/icons-material/Clear';

export interface UserDebugViewProps {
    categories: Category[];
}

export const UserDebugView = (props: UserDebugViewProps): ReactElement => {

    const { categories } = props;
    const { GetUserSnapshotByEmail } = adminRequests(app.apiBasePath);

    const [usersSearchKey, setUsersSearchKey] = useState<string>("initial-static");

    const [report, setReport] = useState<UserManifestReport | null>(null);
    const [working, setWorking] = useState(false);

    const [email, setEmail] = useState<string>("");

    const getManifest = async (email: string): Promise<void> => {
        setWorking(true);
        const info = await GetUserSnapshotByEmail(email);
        setWorking(false);
        setReport(info);
    };

    return (
        <div className={style.container}>
            <h1>Get user manifest</h1>
            {
                email === "" &&
                <div className={style.input}>
                    <AutocompleteUsers
                        disabled={working}
                        key={usersSearchKey}
                        onUserSelect={async (user) => {
                            await getManifest(user.email);
                            setEmail(user.email);
                            setUsersSearchKey(user.personId);
                        }}
                    />
                </div>
            }
            {
                email !== "" &&
                <div className={style.info}>
                    <h3>{email}</h3>
                    <button className={style.clear} onClick={() => { setEmail(""); setReport(null); }}>
                        <ClearIcon />
                    </button>
                </div>
            }


            {
                working &&
                <ReactLoading height={28} type={"bars"} color={"#5270F0"} width={28} />
            }

            {
                report !== null &&
                <UserManifestView model={report} categories={categories} />
            }
        </div >
    )
};