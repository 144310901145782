import { OptionType } from "../models/OptionType";

export const EmailValidationPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export var UploaderBrowse = 'uploader-browse';
export var BackgroundClick = 'background-click';

export const SelectStyleFix = {
    menu: provided => ({ ...provided, zIndex: 9999 })
};


const CanadaTimeZones: OptionType[] = [
    { label: "(-8) Vancouver, Victoria", value: -8 },
    { label: "(-7) Calgary, Edmonton", value: -7 },
    { label: "(-6) Winnipeg", value: -6 },
    { label: "(-5) Montréal, Toronto, Québec", value: -5 },
    { label: "(-4) Halifax", value: -4 },
    { label: "(-3:30) St. John's", value: -3.5 }
];

export { CanadaTimeZones }

const FranceTimeZones: OptionType[] = [
    { label: "(+1) Heure d'Europe centrale", value: 1 },
    { label: "(+2) Heure d'été d'Europe centrale", value: 2 }
];

export { FranceTimeZones }

const BelgiumTimeZones: OptionType[] = [
    { label: "(+1) Heure d'Europe centrale", value: 1 }
];

export { BelgiumTimeZones }

export enum PubSubTopic {
    Action = "action",
    Changes = "changes",
    Component = "component",
    DBErrors = "database.error",
    DBEvents = "database.event"
}

const CKConfig = {
    mediaEmbed: {
        previewsInData: true
    },
    toolbar: {
        items: ['heading', '|', 'bold', 'italic',
            'link', 'underline', 'strikethrough', 'bulletedList',
            'numberedList', '|', 'outdent', 'indent', '|', 'insertTable',
            'undo', 'redo', 'alignment', '-', 'fontSize', 'highlight', 'mediaEmbed',
            'fontBackgroundColor', 'fontColor'],
        shouldNotGroupWhenFull: true
    }
};
export { CKConfig }

export const SelectStylesFilter = {

    control: (provided, state) => ({
        ...provided,
        borderColor: '#aaa',
        backgroundColor: '#ffff',
        minHeight: '31px',
        height: '31px',
        border: 'none'
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        height: '31px',
        padding: '0 3px 2px',
        fontSize: '10pt'
    }),

    singleValue: (provided, state) => ({
        ...provided,
        color: '#1E4586',
        fontSize : '1.1em'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px'
    }),

    indicatorSeparator: state => ({
        display: 'none',
    }),

    menuPortal: (provided, state) => ({
        ...provided,
        zIndex: 9999,
        fontSize: '13px'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#555'
    })
};



export const ReactSelectStyles = {

    control: (provided, state) => ({
        ...provided,
        borderColor: '#aaa',
        backgroundColor: '#F7F7F7',
        minHeight: '31px',
        height: '31px',
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        height: '31px',
        padding: '0 6px 2px',
        fontSize: '10pt'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),

    indicatorSeparator: state => ({
        display: 'none',
    }),

    menuPortal: (provided, state) => ({
        ...provided,
        zIndex: 9999,
        fontSize: '13px'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#555'
    })
};

export const ReactSelectStylesCompactOptions = {
    option: (provided, state) => ({
        ...provided,
        padding: "5px 10px"
    })
};

export const ReactMultiSelectStyles = {

    control: (provided, state) => ({
        ...provided,
        borderColor: '#aaa',
        backgroundColor: '#F7F7F7'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),

    indicatorSeparator: state => ({
        display: 'none',
    }),


    menuPortal: (provided, state) => ({
        ...provided,
        zIndex: 9999,
        fontSize: '13px'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#555'
    }),
    multiValue: (provided, state) => ({
        ...provided,
        color: '#B21E1E'
    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        fontSize: '10pt',
        whiteSpace: "normal"
    }),
    option: (provided, state) => ({
        ...provided,
        borderBottom: state.isSelected ? 'solid 1px #B4C0C6' : 'none',
        backgroundColor: state.isSelected ? "#20638f" : "#fff"
    }),
};


