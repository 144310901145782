
import { FC, ReactElement, useContext, useEffect, useState } from "react";
import style from "./publications.module.scss";
import axios from "axios";
import { app } from "../..";
import { StatusCodes } from "http-status-codes";
import { useNavigate, useParams } from "react-router";
import { Group } from "../../models/Group";
import classNames from "classnames";
import { LocalizationContext } from "../../interfaces/AppContext";
import { dateSortDesc, } from "../../misc/Utilities";
import { Document } from "../../models/Document";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { faDownload, faLink, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { ContextMenu } from "../../interfaces/ContextMenu";
import { Checkbox } from "../../components/Checkbox";
import Loading from "react-loading";

type DocumentWithContext = Document & ContextMenu;

export const Publications : FC = (): ReactElement => {

    const nav = useNavigate();
    const params = useParams();
    const groupId = params["groupid"];
    
    const [group, setGroup] = useState<Group>(null);

    const [fileName, setFileName] = useState<string>("");
    const [includePublicExchanges, setIncludePublicExchanges] = useState<boolean>(false);
    const [minutesLeft, publishMinutesLeft] = useState<number>(null);

    const [docs, setDocs] = useState<DocumentWithContext[]>([]);
    const [optX, setOptX] = useState(0);
    const [optY, setOptY] = useState(0);

    const { localization } = useContext(LocalizationContext);


    const closeContextMenu = (): void => {
        setDocs(current => {
            const update = current.map(p => {
                p.menuVisible = false;
                return p;
            });
            return [...update];
        })
    };

    const fetchDocuments = (): void => {
        let docResult = axios.get(`${app.apiBasePath}/groupadmin/${groupId}/documents`);
        docResult.then(result => {
            setDocs(result.data.map(p => {
                let q: DocumentWithContext = p;
                q.menuVisible = false;
                return q;
            }));
        });

    };

    const checkThrottle = (): void => {
        let publishCheck = axios.get(`${app.apiBasePath}/groupadmin/${groupId}/documents/checkpublish`);
        publishCheck.then(result => {
            publishMinutesLeft(result.data);
        });
    };

    useEffect(() => {

        let result = axios.get(`${app.apiBasePath}/groupadmin/${groupId}/info`);

        result.then(response => {
            if (response.status === StatusCodes.NO_CONTENT) {
                nav("/");
            }
            else {
                setGroup(response.data);
            }
        });

        fetchDocuments();

        checkThrottle();
    }, []);


    useEffect(() => {
        window.addEventListener("click", closeContextMenu);
        return () => {
            window.removeEventListener("click", closeContextMenu);
        };
    }, []);


    if (group === null) {
        return <Loading />;
    }

    const togglePublicExports = (): void => {
        setIncludePublicExchanges(!includePublicExchanges);
    };

    const generatePdf = (): void => {
        const request = axios.post(`${app.apiBasePath}/groupadmin/${groupId}/generateexchangesdocument`, {
            includePublicExchanges: includePublicExchanges,
            groupId: groupId
        });

        request.then(() => {
            publishMinutesLeft(60);
            window.setTimeout(() => {
                fetchDocuments();
            }, 3000);
        });

    };

    const showContextMenu = (d: DocumentWithContext, e: React.MouseEvent<HTMLDivElement>): void => {
        e.stopPropagation();

        const alreadyOpen = docs.some(p => p.id === d.id && p.menuVisible);
        if (alreadyOpen) {
            closeContextMenu();
            return;
        }

        const menuButtonRect = e.currentTarget.getBoundingClientRect();
        setOptX(menuButtonRect.x - 115);
        setOptY(menuButtonRect.y);
        setDocs(current => {
            const update = current.map(p => {
                p.menuVisible = p.id === d.id;
                return p;
            });
            return [...update];
        })
    };

    const requestDownload = (doc: DocumentWithContext): void => {
        const path = `${app.apiBasePath}/groupadmin/${groupId}/document/download/${doc.id}`;
        window.open(path);
    };

    const deleteDocument = (doc: DocumentWithContext): void => {
        if (window.confirm(localization["confimDelete"])) {
            const path = `${app.apiBasePath}/groupadmin/${groupId}/document/${doc.id}`;

            axios.delete(path).then((result) => {
                if (result.data?.success) {
                    fetchDocuments();
                }
                else {
                    console.warn(result.data?.errorCode)
                }
            });
        };
    };

    const buildPublicUrl = (doc: DocumentWithContext): void => {
        window.prompt(doc.name, `${app.apiBasePath}/public/doc/${groupId}/${doc.id}`);
    };

    const exchangeDocuments = docs.filter(d => d.type === "exchange-export");

    return (
        <div className={classNames(style.container, "form")}>

            {
                minutesLeft === 0 &&
                <div className={style.heading}>
                    <p>{localization["generateExchangeDocument"]}</p>
                </div>
            }

            {
                minutesLeft === 0 &&

                <div className="row">
                    <div className={style.radio}>
                        <Checkbox checked={includePublicExchanges} onCheckChange={togglePublicExports} label={localization["usePublicExchanges"]} />
                    </div>
                </div>

            }

            {
                false &&
                <div className="row">
                    <label>{localization["fileName"]}</label>
                    <input type="text" className="form-control" />
                </div>
            }

            <div className="row">
                {
                    minutesLeft === 0 &&
                    <button onClick={generatePdf} className="btn action">
                        <span>Générer</span>
                    </button>
                }
                {
                    minutesLeft > 0 &&
                    <p className={style.warn}>{localization["exportThrottleWarning"]} {minutesLeft} minute(s)</p>
                }
            </div>

            <hr />

            <div className="row">
                <h3>{localization["generatedDocuments"]}</h3>

                {
                    exchangeDocuments.length === 0 &&
                    <p className={style.warn}>{localization["noDocumentsFound"]}</p>
                }

                <div className={style.docs}>
                    {
                        exchangeDocuments.length > 0 &&
                        exchangeDocuments
                            .sort((a, b) => dateSortDesc(a.dateCreated, b.dateCreated))
                            .map(d => {
                                return (
                                    <div key={d.id} className={style.document}>

                                        <div className={style.m}>
                                            <div className={style.t}>
                                                <FontAwesomeIcon icon={faFilePdf} className={d.fileType === "pdf" ? style.pdf : null} />
                                                <span>{d.name}</span>
                                            </div>
                                            <div className={style.opt} onClick={(e) => showContextMenu(d, e)}>
                                                {
                                                    d.menuVisible &&
                                                    <div style={{
                                                        top: optY,
                                                        left: optX
                                                    }} className={style.controls}>
                                                        <button className={style.download} onClick={() => requestDownload(d)}>
                                                            <FontAwesomeIcon icon={faDownload} />
                                                            <span>{localization["download"]?.toLowerCase()}</span>
                                                        </button>
                                                        <button onClick={() => buildPublicUrl(d)} className={style.link}>
                                                            <FontAwesomeIcon icon={faLink} />
                                                            <span>{localization["publicLink"]}</span>
                                                        </button>
                                                        <button onClick={() => deleteDocument(d)} className={style.delete}>
                                                            <FontAwesomeIcon icon={"trash"} />
                                                            <span>{localization["delete"]?.toLowerCase()}</span>
                                                        </button>
                                                    </div>
                                                }
                                                <FontAwesomeIcon icon={faEllipsisVertical} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                    }
                </div>

            </div>
        </div>
    );
};