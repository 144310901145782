import { Context, ReactElement, useContext, useEffect } from "react";
import style from "./announcement.module.scss";
import classNames from "classnames";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { facilitatorsStore } from "../../../store/facilitatorsStore";
import { CKConfig } from "../../../misc/Constants";
import Editor from 'ckeditor5-custom-build';
import { Checkbox } from "../../../components/Checkbox";
import { LocalizationContext } from "../../../interfaces/AppContext";
import { groupRequests } from "../../../requests/groupRequests";
import { app } from "../../..";
import { GroupMessageEntry } from "../../../models/GroupMessageEntry";
import { useNavigate, useParams } from "react-router";
import { useMutation, useQuery } from "@tanstack/react-query";
import { InputWarning } from "../../../components/InputWarning";
import { groupKeys } from "../../../queryKeys/groupKeys";
import { StringIsNullOrEmpty } from "../../../misc/Utilities";
import Loading from "react-loading";


export const AnnouncementEditFrom = (): ReactElement => {

    const { title, content, published, update } = facilitatorsStore(state => state);

    const { localization: v } = useContext(LocalizationContext);

    const { UpdateGroupMessage, GetGroupMessageById } = groupRequests(app.apiBasePath);

    const { groupid, postid } = useParams();

    const { data: message, isLoading } = useQuery({
        queryFn: () => GetGroupMessageById(groupid, postid),
        queryKey: groupKeys.groupMessage(groupid, postid),
        enabled: !StringIsNullOrEmpty(postid) && !StringIsNullOrEmpty(groupid)
    });


    const nav = useNavigate();

    const cleanAndLeave = (): void => {
        update("title", "");
        update("content", "");
        update("published", false);
        nav(`/group/manage/${groupid}/announcements`);
    };

    const updateGroupPostHandler = useMutation({
        mutationFn: (arg: GroupMessageEntry) => UpdateGroupMessage(arg),
        onSuccess: () => cleanAndLeave()
    });

    const saveChanges = (): void => {
        const entry: GroupMessageEntry = {
            postId: postid,
            title: title,
            content: content,
            publish: published,
            groupId: groupid
        };

        updateGroupPostHandler.mutate(entry);
    };

    const validateTitle = (): boolean => {
        return title.trim().length > 0;
    }

    const validateContent = (): boolean => {
        return content.trim().length > 0;
    }

    const isTitleValid = validateTitle();
    const isContentValid = validateContent();
    const isFormValid = isTitleValid && isContentValid;

    useEffect(() => {
        if (message) {
            update("title", message.title);
            update("content", message.content);
            update("published", message.published);
        }
    }, [message]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className={style.page}>

            <h1>Modifier une communiqué</h1>

            <div className={classNames("form", style.form)}>
                <div className={classNames("row", style.row)}>
                    <label>{v["commTitle"]}</label>
                    <input
                        disabled={updateGroupPostHandler.isPending}
                        value={title}
                        onChange={(e) => update("title", e.target.value)}
                        className="form-control" type="text" />
                    {
                        isTitleValid ? null : <InputWarning messageKey={v["OfferTitleMissingErrorMsg"]} />
                    }

                </div>
                <div className={style.maxheight}>
                    <div className={classNames("row", style.row)}>
                        <label>{v["commContent"]}</label>
                        <CKEditor
                            disabled={updateGroupPostHandler.isPending}
                            data={content}
                            config={CKConfig}
                            editor={Editor}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                update("content", data);
                            }}
                            onBlur={(event, editor) => {

                            }}
                            onFocus={(event, editor) => {

                            }}
                        />
                        {
                            isContentValid ? null : <InputWarning messageKey={v["commBodyRequired"]} />
                        }
                    </div>
                </div>

                <div className={classNames("row", style.row, style.controls)}>
                    <button
                        disabled={updateGroupPostHandler.isPending}
                        onClick={() => cleanAndLeave()}
                        className="btn cancel">{v["cancel"]}</button>
                    <div>

                        <div className={style.controls}>
                            <Checkbox
                                disabled={updateGroupPostHandler.isPending}
                                checked={published}
                                onCheckChange={() => update("published", !published)} label="Publier" />
                            <button
                                style={{ marginLeft: "10px" }}
                                disabled={updateGroupPostHandler.isPending || !isFormValid}
                                onClick={() => saveChanges()}
                                className="btn save2">{v["save"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}