import { FC, ReactElement, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {  StringIsNullOrEmpty } from "../../misc/Utilities";
import { GroupUser } from "../../models/GroupUser";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./groupmanage.module.scss";
import { LocalizationContext } from "../../interfaces/AppContext";
import { groupRequests } from "../../requests/groupRequests";
import { app } from "../..";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { groupKeys } from "../../queryKeys/groupKeys";
import Loading from "react-loading";

export default interface GroupUsersManageProps {
    groupId: string;
    groupName: string;
    subOption: string;
}

enum SortType {
    date,
    count,
    name
};

export const GroupUsersManage: FC = (): ReactElement => {

    const { localization } = useContext(LocalizationContext);

    const params = useParams();
    const groupId = params["groupid"];

    const { GetGroupUsers } = groupRequests(app.apiBasePath);

    const queryClient = useQueryClient();

    const { data: users, isLoading } = useQuery({
        queryFn: () => GetGroupUsers(groupId),
        queryKey: groupKeys.groupUsers(groupId),
        enabled: !StringIsNullOrEmpty(groupId)
    });

 
    const [sortType, setSortType] = useState(SortType.name);

    const dateSorter = (a: GroupUser, b: GroupUser): number => {
        return moment(b.dateJoined).valueOf() - moment(a.dateJoined).valueOf();
    };

    const exchangeCountSorter = (a: GroupUser, b: GroupUser): number => {
        return b.publishedExchanges - a.publishedExchanges;
    };

    const nameSorter = (a: GroupUser, b: GroupUser): number => {
        return a.firstName.localeCompare(b.firstName);
    };

    let sorter = nameSorter;

    if (sortType === SortType.date) {
        sorter = dateSorter;
    }
    else if (sortType === SortType.count) {
        sorter = exchangeCountSorter;
    }

    const refreshUsers = (): void => {
        queryClient.removeQueries({ queryKey: groupKeys.groupUsers(groupId) });
    }

    if (isLoading) {
        return <Loading />;
    }

    return (

        <div className={style.innerContainer}>
            <div>
                <div className={style.groupUsers}>
                    <div className={style.heading}>
                        <h3>JEUeurs <span>({users.length})</span> <FontAwesomeIcon icon="refresh" onClick={() => refreshUsers()} /></h3>
                        <div className={style.sorter}>
                            <div>
                                <button onClick={() => setSortType(SortType.name)} className={sortType === SortType.name ? style.active : null}>prénom</button>
                                <button onClick={() => setSortType(SortType.date)} className={sortType === SortType.date ? style.active : null}>date</button>
                                <button onClick={() => setSortType(SortType.count)} className={sortType === SortType.count ? style.active : null}>{localization["countSort"]}</button>
                            </div>
                        </div>
                    </div>
                    {
                        users.length > 0 &&
                        users.sort(sorter).map(user => {
                            return (
                                <div className={style.u} key={user.personId}>
                                    <div>
                                        <Link to={`${user.personId}`} className={style.n}>
                                            <p>{user.firstName} {user.lastName} ({user.displayName})</p>
                                            <span>{user.emailAddress}</span>
                                        </Link>
                                    </div>

                                    <div className={style.join}>
                                        <span className={style.d}>{localization["joinDate"]}</span>
                                        <p>{moment(user.dateJoined).format("MMM DD, YYYY")}</p>
                                    </div>
                                    <div className={style.exchanges}>
                                        <p>{localization["exchangeCount"]}: {user.publishedExchanges}</p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div >
        </div>

    );
}