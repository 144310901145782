import React, { useEffect, useRef, useState } from "react";
import { FC, PropsWithChildren } from "react";
import _ from "lodash";
import { ContentPasteOffSharp } from "@mui/icons-material";
import { on } from "events";

export interface AvailableHeightProviderProps extends PropsWithChildren {
    initialHeight?: number;
    heightCorrection?: number;
}

export const AvailableHeightProvider: FC<AvailableHeightProviderProps> = ({ children, initialHeight, heightCorrection }) => {
    const divRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(initialHeight || 0);

    useEffect(() => {

        function onResize(): void {
            // calculate height distance between to top of the window and the top of the div
            // determine max height of the div
            const rect = divRef.current?.getBoundingClientRect();
            const footerElem = document.getElementsByTagName("footer");
            const footerHeight = footerElem.length > 0 ? footerElem[0].clientHeight : 0;
            if (rect) {
                setHeight(window.innerHeight - rect.top - footerHeight + (heightCorrection || 0));
            }
        }

        const callback = _.debounce(onResize, 100);
        window.addEventListener("resize", callback);

        onResize();

        return () => {
            window.removeEventListener("resize", callback);
        }
    }, []);

    return <div ref={divRef}>
        {React.Children.map(children, (child) =>
            React.cloneElement(child as any, { height: height })
        )}
    </div>
};