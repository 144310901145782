import { Popper } from "@mui/material";
import { ReactElement } from "react";

export const PopperWithNoEmptyOption = (props): ReactElement => (<Popper
    sx={
        {
            '& .MuiAutocomplete-noOptions': {
                display: 'none'
            }
        }
    }
    {...props} disablePortal />);