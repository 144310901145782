import { FC, ReactElement } from "react";
import style from "./grouplist.module.scss";
import { GroupInfo } from "../../models/JoinedGroup";
import CloseIcon from '@mui/icons-material/Close';
import buttonStyle from "../../styles/button.module.scss";
import classNames from "classnames";

export interface GroupListProps {
    groups: GroupInfo[];
    onRemove?: (groupId: string) => void;
}
export const GroupList: FC<GroupListProps> = ({ groups, onRemove }): ReactElement => {

    return (
        <div className={style.control}>
            {
                groups.map((group, idx) => (
                    <div key={idx} className={style.group}>
                        <span>{group.name}</span>
                        {
                            onRemove &&
                            <button
                                className={classNames(buttonStyle.button, buttonStyle.icon, buttonStyle.sm, buttonStyle.cancel)}
                                onClick={() => onRemove(group.id)}>
                                <CloseIcon />
                            </button>
                        }
                    </div>
                ))
            }
        </div>
    );
}