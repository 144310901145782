import { components, OptionProps } from "react-select";
import { OptionType } from "../../models/OptionType";
import style from "./option.module.scss";
import classNames from "classnames";
import { IsNullOrWhiteSpace } from "../../misc/Utilities";

export type OptionTypeDescription = OptionType & { description: string, example?: string };

export const Option = ({ children, ...props }: OptionProps<OptionTypeDescription>) => {

    return (
        <components.Option {...props}>
            <div className={classNames(style.item, props.isSelected ? style.selected : null)}>
                <p>{children}</p>
                <div className={style.subitem}>
                    {
                        !IsNullOrWhiteSpace(props.data.description) &&
                        <div><span>{props.data.description}</span></div>
                    }

                    {
                        !IsNullOrWhiteSpace(props.data.example) &&
                        <div><span className={style.example}>{props.data.example}</span></div>
                    }
                </div>
            </div>
        </components.Option>
    );
}