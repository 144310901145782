import { create } from "zustand";
import { Location } from "../models/Location";
import { CountryOptionType, OptionType } from "../models/OptionType";

export type AdminInvitationsStore = {



    selectedCountry: CountryOptionType | null;
    selectedProvince: OptionType | null;
    selectedRegion: OptionType | null;
    selectedGroup: OptionType | null;
    stateFilters: OptionType[];

    textSearchValue: string;

    update?: <K extends keyof Omit<AdminInvitationsStore, "update">>(key: K, value: AdminInvitationsStore[K]) => void;
}

export const adminInvitationsStore = create<AdminInvitationsStore>((set) => ({

    selectedCountry: null,
    selectedProvince: null,
    selectedRegion: null,
    selectedGroup: null,
    stateFilters: [],
    textSearchValue: "",
    update: (key, value) => set((state) => ({ [key]: value }))
}));