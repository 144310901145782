import { create } from "zustand";
import { Location } from "../models/Location";
import { CountryOptionType, OptionType } from "../models/OptionType";
export type AdminStore = {

    provincesCache: Array<OptionType>;
    regionsCache: Array<OptionType>;
    groupsCache: Array<OptionType>;

    selectedCountry: CountryOptionType | null;
    selectedProvince: OptionType | null;
    selectedRegion: OptionType | null;

    update?: <K extends keyof Omit<AdminStore, "update">>(key: K, value: AdminStore[K]) => void;
}

export const adminStore = create<AdminStore>((set) => ({
    provincesCache: [],
    regionsCache: [],
    groupsCache: [],
    selectedCountry: null,
    selectedProvince: null,
    selectedRegion: null,
    update: (key, value) => set((state) => ({ [key]: value }))
}));