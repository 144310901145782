import { Link } from "react-router-dom";
import { AppContext, LocalizationContext } from "../../interfaces/AppContext";
import { Favorites } from "../Favorites/Favorites";
import { IsNullOrWhiteSpace } from "../../misc/Utilities";
import { app } from "../..";
import noimg from "../../images/no-image.jpg";
import classNames from "classnames";
import moment from "moment";
import { SearchResultModel } from "../../models/SearchResultModel";
import { ReactElement, useContext } from "react";
import style from "./search-results.module.scss";

export const Result = ({ model, radiusAvailable }: { model: SearchResultModel, radiusAvailable: boolean }): ReactElement => {

    const { favorites } = useContext(AppContext);
    const { localization } = useContext(LocalizationContext);
    
    return (
        <div className={classNames(style.result, model.exchangeType === "demande" ? style.demande : null)}>

            <div className={style.thumb}>
                {
                    !IsNullOrWhiteSpace(model.file?.fileId) &&
                    <Link to={`/exchanges/${model.id}`}>
                        <img draggable={false} src={`${app.apiBasePath}/assets/image/thumb/${model.file?.fileId}`} alt="image non trouvé" />
                    </Link>
                }
                {
                    IsNullOrWhiteSpace(model.file?.fileId) &&
                    <Link to={`/exchanges/${model.id}`}>
                        <img src={noimg} />
                    </Link>
                }
            </div>

            <div className={style.exchange}>
                <Link to={`/exchanges/${model.id}`}>
                    {
                        model.exchangeType === "offer" &&
                        <h5>{model.exchange.title}</h5>
                    }
                    {
                        model.exchangeType === "demande" &&
                        <h5>{model.exchange.title} (demande)</h5>
                    }

                </Link>
                <div className={style.b}>
                    <strong>{localization["publishBy"]} </strong><Link to={`exchanges/u/${model.postedById}`}>{model.postedByName}</Link>
                </div>
                <div className={style.b}>
                    <strong>publié</strong><span>{moment.utc(model.exchange.publishedOn).local().fromNow()}</span>
                </div>

                {
                    !IsNullOrWhiteSpace(model.exchange.updatedOn) &&
                    <div className={style.b}>
                        <strong>{localization["lastUpdated"]}</strong><span>{moment.utc(model.exchange.updatedOn).local().fromNow()}</span>
                    </div>
                }

                <div className={classNames(style.b, style.f)}>
                    {
                        model.exchange.isPointsOnly &&
                        <span className={classNames(style.tag, style.p100)}>100%<div>points</div></span>
                    }


                    {
                        model.exchange.valueInPoints &&
                        <span className={classNames(style.tag, style.points)}>{model.exchange.valueInPoints} points</span>
                    }
                    {
                        radiusAvailable &&
                        <span className={classNames(style.tag, style.distance)}>{Math.round((model.distanceMeters / 1000) * 10) / 10} km</span>
                    }

                    <Favorites isSet={favorites.some(p => p.exchangeId === model.id)} resourceId={model.id} />
                </div>
            </div>
        </div >
    );
};
