import { FC, ReactElement, useContext } from "react";
import { LocalizationContext } from "../../../../interfaces/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { groupManagementRequests } from "../../../../requests/groupManagementRequests";
import { app } from "../../../..";
import { useQuery } from "@tanstack/react-query";
import { StringIsNullOrEmpty } from "../../../../misc/Utilities";

import style from "./viewinvitation.module.scss";
import moment from "moment";
import Loading from "react-loading";

export interface ViewInvitationProps {
}

export const ViewInvitation: FC<ViewInvitationProps> = (): ReactElement => {

    const params = useParams();
    const code = params["code"];
    const { localization } = useContext(LocalizationContext);
    const nav = useNavigate();

    const { GetInvitationByCode } = groupManagementRequests(app.apiBasePath);

    const { data: invitationInfo, isLoading } = useQuery({
        queryFn: () => GetInvitationByCode(code),
        queryKey: ["invitation", code],
        enabled: !StringIsNullOrEmpty(code)
    });

    if (isLoading) {
        return <Loading />
    }

    if (!invitationInfo) {
        return <div>resouce not foud</div>
    }

    return (
        <div className={style.container}>
            <div className={style.success}>
                <h2><FontAwesomeIcon icon={"check"} />{localization["invitationCreated"]} {invitationInfo.emailAddress}</h2>

                <h5>{moment(invitationInfo.dateCreated).toString()}</h5>

                <p>Code: <strong>{code}</strong></p>
                <div className={style.return}>
                    <button className="btn action" onClick={() => nav(-1)}>{localization["return"]}</button>
                </div>
            </div>
        </div>
    )
};