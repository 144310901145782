import { Group } from "../models/Group";
import { SearchOptions } from "../models/SearchOptions";

export interface DefaultsProvider {
    getDefaultSearchSettings(groups: Group[]): SearchOptions
}

export const DefaultsProviderService = (): DefaultsProvider => {

    const getDefaultSearchSettings = (groups: Group[]): SearchOptions => {

        return {
            locationIds: groups.map(g => g.id),
            exchangeType: "offer",
            categoryId: 'all',
            radiusControlPosition: 3,
            orderBy: "date",
            queryText: '',
            includeMyExchanges: false,
            IsPointsOnly: false
        };
    }

    return {
        getDefaultSearchSettings: getDefaultSearchSettings
    }
};