import { create } from "zustand";

export type FacilitatorStore = {
    // announcement form
    title: string;
    content: string;
    published: boolean;

    update?: <K extends keyof Omit<FacilitatorStore, "update">>(key: K, value: FacilitatorStore[K]) => void;
}


export const facilitatorsStore = create<FacilitatorStore>((set) => ({
    title: "",
    content: "",
    published: false,

    update: (key, value) => set((state) => ({ ...state, [key]: value }))
}));