import { ReactElement, useState } from "react";

import DatePicker from "react-datepicker";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.module.css";
import { ReactSelectStyles } from "../../misc/Constants";
import { OptionType } from "../../models/OptionType";
import { Radio } from "../../components/Radio";
import { ApiLogProperties, LogEntry } from "./LogEntry";
import axios from "axios";
import { app } from "../..";
import moment from "moment";
import { ApiLogsTable } from "./ApiLogsTable";
import style from "./logspage.module.scss";
import { useNavigate } from "react-router";
import ReactLoading from "react-loading";

enum SearchType {
    Range = 0,
    PastHours = 1
}

export const LogsPage = (): ReactElement => {

    const levels: OptionType[] = [
        {
            value: "*",
            label: "All"
        },
        {
            value: "Debug",
            label: "Debug"
        },
        {
            value: "Information",
            label: "Information"
        },
        {
            value: "Warning",
            label: "Warning"
        },
        {
            value: "Error",
            label: "Error"
        }
    ];

    const systemOptions: OptionType[] = [
        
        {
            value: "api",
            label: "API"
        },
        {
            value: "background",
            label: "Background task"
        },
        {
            value: "ns",
            label: "Notifications"
        }
    ];

    const hours = [1, 2, 3, 6, 24].map(p => {
        return {
            value: p,
            label: p === 1 ? "Last hour" : `Last ${p} hours`
        } as OptionType
    });

    const [startDate, setStartDate] = useState<Date>(null);
    const [endDate, setEndDate] = useState<Date>(null);
    const [level, setLevel] = useState<OptionType>(levels[2]);
    const [searchType, setSearchType] = useState(SearchType.PastHours);
    const [queryText, setQueryText] = useState("");
    const [hourRange, setHourRange] = useState<OptionType>(hours[0]);
    const [system, setSystem] = useState<OptionType>(systemOptions[0]);
    const [logs, setLogs] = useState<Array<LogEntry<ApiLogProperties>>>([]);
    const [working, setWorking] = useState(false);

    const nav = useNavigate();

    const getLogs = (): void => {

        setLogs([]);
        setWorking(true);
        if (searchType === SearchType.PastHours) {
            getPastHoursLogs().then(logData => { setLogs(logData); setWorking(false); });
        }
        else if (searchType === SearchType.Range) {
            getRangeLogs().then(logData => { setLogs(logData); setWorking(false); });
        }
    };

    const getPastHoursLogs = (): Promise<Array<LogEntry<ApiLogProperties>>> => {

        return new Promise<Array<LogEntry<ApiLogProperties>>>((resolve, reject) => {

            const lvl = level.value === "*" ? "" : level.value;

            const request = axios.get(`${app.apiBasePath}/admin/logs/pasthours?source=${system.value}&hours=${hourRange.value}&level=${lvl}&q=${queryText}`);
            request.then(result => {
                resolve(result.data);
            })
            request.catch(e => {
                reject(e);
            });
        });
    };

    const getRangeLogs = (): Promise<Array<LogEntry<ApiLogProperties>>> => {

        const from = moment(startDate).format("yyyy-MM-DD hh:mm");
        const to = moment(endDate).format("yyyy-MM-DD hh:mm");
        const lvl = level.value === "*" ? "" : level.value;

        return new Promise<Array<LogEntry<ApiLogProperties>>>((resolve, reject) => {
            const request = axios.get(`${app.apiBasePath}/admin/logs/range?source=${system.value}&startDate=${from}&endDate=${to}&level=${lvl}&q=${queryText}`);
            request.then(result => {
                resolve(result.data);
            })
            request.catch(e => {
                reject(e);
            });
        });
    };
 
    return (

        <div className={style.container}>
            <div className={style.radio}>
                <Radio currentValue={searchType} label="search recent" onChange={() => { setSearchType(SearchType.PastHours) }} group="filter" value={SearchType.PastHours} />
                <Radio currentValue={searchType} label="range search" onChange={() => { setSearchType(SearchType.Range) }} group="filter" value={SearchType.Range} />
            </div>

            <div className={style.filters}>
                {
                    searchType === 0 &&
                    <>
                        <div className={style.picker}>
                            <label>from</label>
                            <DatePicker
                                showTimeSelect
                                className="form-control"
                                dateFormat={"yyyy-MM-dd hh:mm"}
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                            />
                        </div>
                        <div className={style.picker}>
                            <label>to</label>
                            <DatePicker
                                showTimeSelect
                                className="form-control"
                                dateFormat={"yyyy-MM-dd hh:mm"}
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                            />
                        </div>
                    </>
                }
                {
                    searchType === 1 &&
                    <div className={style.hourinput}>
                        <label>logs for past hours</label>


                        <Select
                            value={hourRange}
                            onChange={opt => setHourRange(opt)}
                            options={hours}
                            placeholder="time range"
                            styles={ReactSelectStyles} />
                    </div>
                }


                <div className={style.selector}>
                    <Select
                        value={level}
                        onChange={opt => setLevel(opt)}
                        options={levels}
                        placeholder="log level"
                        styles={ReactSelectStyles} />
                </div>
                
                <div className={style.selector}>
                    <Select
                        value={system}
                        onChange={opt => setSystem(opt)}
                        options={systemOptions }
                        placeholder="system"
                        styles={ReactSelectStyles} />
                </div>

                <div className={style.query}>
                    <input type="text" value={queryText} placeholder="search text" className="form-control" onChange={e => setQueryText(e.target.value)} />
                </div>

                <div className={style.btn}>
                    <button disabled={working} onClick={getLogs} className="btn action">Search</button>
                    {
                        working &&
                        <ReactLoading type={"spin"} color={"#5270F0"} height={28} width={28} />
                    }
                </div>
            </div>

            <div className={style.results}>
                <ApiLogsTable logs={logs} />
            </div>
        </div>
    );
};