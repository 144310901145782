import { OptionType, OptionTypeGeneric } from "../models/OptionType";
import { ExchangeType } from "../models/ExchangeType";
import { OrderType } from "../models/SearchOptions";

export const ExchangeOptions: OptionTypeGeneric<ExchangeType | "*">[] = [
    {
        value: "offer",
        label: "Offres"
    },
    {
        value: "demande",
        label: "Demandes"
    }, {
        value: "*",
        label: "Tous les types"
    }
];

export const ExchangeOrdering: OptionTypeGeneric<OrderType>[] = [
    {
        value: "distance",
        label: "par distance"
    },
    {
        value: "date",
        label: "par date"
    }
];