import axios from "axios";
import { PersonWithOrphanInfo } from "../models/Person";
import { IsUndefinedOrNull } from "../misc/Utilities";
import { StatusCodes } from "http-status-codes";

export type UserPreferenceOptions = "search-options";

export interface UserRequests {
    GetUsersForLocation: (locationId: string) => Promise<Array<PersonWithOrphanInfo>>;

    UpsertPreferences: (key: UserPreferenceOptions, obj: any) => Promise<void>;

    GetUserPreference: <T>(key: UserPreferenceOptions) => Promise<T | null>;
}

export const userRequests = (apiPath: string): UserRequests => {

    const getUsersForLocation = async (locationId: string): Promise<Array<PersonWithOrphanInfo>> => {
        try {
            const result = await axios.get<Array<PersonWithOrphanInfo>>(`${apiPath}/admin/users/list?locationReference=${locationId}`);
            return result.data;
        }
        catch (e) {
            console.warn(e);
            return [];
        }
    }

    const upsertPreferences = async (key: UserPreferenceOptions, obj: any): Promise<void> => {
        if (IsUndefinedOrNull(obj)) {
            return;
        }
        await axios.post(`${apiPath}/profile/preferences/add`, {
            key: key,
            preferences: JSON.stringify(obj)
        });
    }

    const getUserPreference = async <T>(key: UserPreferenceOptions): Promise<T | null> => {
        const result = await axios.get(`${apiPath}/profile/preferences/${key}`);
        if (result.status === StatusCodes.NOT_FOUND) {
            return null;
        }

        try {
            return JSON.parse(result.data?.preferences) as T;
        }
        catch(e) {
            return null;
        }
    }

    return {
        GetUsersForLocation: getUsersForLocation,
        UpsertPreferences: upsertPreferences,
        GetUserPreference: getUserPreference
    };
}