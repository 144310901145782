import axios, { HttpStatusCode } from "axios";
import { Location } from "../models/Location";
import { Invitation } from "../models/Invitation";
import { VerifyInviteeByEmailResult } from "../models/VerifyInviteeByEmailResult";
import { LocationType } from "../models/LocationType";
import { StringIsNullOrEmpty } from "../misc/Utilities";
import { UserProfileResult } from "../models/UserProfileResult";
import { StatusCodes } from "http-status-codes";
import { CreateUserModel } from "../models/CreateUserModel";
import { AdminCreateUserResult } from "../models/AdminCreateUserResult";
import { UserManifestReport } from "../models/UserManifestReport";
import { PersonLight } from "../models/Person";

export interface AdminRequests {
    GetLocationInfo: (locationId: string) => Promise<Location | null>;
    GetGroupInvitations: (groupId: string) => Promise<Array<Invitation>>;
    VerifyInviteeByEmail: (groupId: string, email: string) => Promise<VerifyInviteeByEmailResult>;
    AddLocation: (name: string, type: LocationType, parentLocationId: string | null) => Promise<string>;
    GetUserProfile: (personId: string) => Promise<UserProfileResult | null>;
    CreateUser: (model: CreateUserModel) => Promise<AdminCreateUserResult>;
    UpdateUser: (model: CreateUserModel) => Promise<AdminCreateUserResult>;
    GetUserSnapshotByEmail: (email: string) => Promise<UserManifestReport | null>;
    SearchUsers: (query: string) => Promise<Array<PersonLight>>;
}



export const adminRequests = (apiPath: string): AdminRequests => {

    const getLocationInfo = async (locationId: string): Promise<Location | null> => {

        const result = await axios.get<Location>(`${apiPath}/admin/getlocationinfo?locationId=${locationId}`);
        if (result.status === HttpStatusCode.NoContent) {
            return null;
        }
        else return result.data;
    }

    const getGroupInvitations = async (groupId: string): Promise<Array<Invitation>> => {
        let path = `${apiPath}/admin/users/invitations/${groupId}/`

        try {
            const result = await axios.get<Array<Invitation>>(path);
            return result.data;
        }
        catch (e) {
            console.warn(e);
            return [];
        }
    }

    const verifyInviteeByEmail = async (groupId: string, email: string): Promise<VerifyInviteeByEmailResult> => {
        const result = await axios.get<VerifyInviteeByEmailResult>(`${apiPath}/admin/users/invitations/${groupId}/verify/${email}`);
        return result.data;
    }

    const addLocation = async (name: string, type: LocationType, parentLocationId: string | null): Promise<string | null> => {
        if (StringIsNullOrEmpty(name)) {
            throw new Error("Name is required");
        }
        const model = {
            name: name,
            type: type,
            parentLocationId: parentLocationId
        }

        try {
            const result = await axios.post<string>(`${apiPath}/admin/location/add`, model);
            return result.data;
        }
        catch (e) {
            console.warn(e);
            return null;
        }
    }

    const getUserProfile = async (personId: string): Promise<UserProfileResult | null> => {

        const result = await axios.get<UserProfileResult>(`${apiPath}/admin/users/getprofile?personId=${personId}`);

        if (result.status === StatusCodes.NO_CONTENT) {
            return null;
        }
        else return result.data;
    }


    const createUser = async (model: CreateUserModel): Promise<AdminCreateUserResult> => {
        const result = await axios.post<AdminCreateUserResult>(`${apiPath}/admin/users/add`, model);
        return result.data;
    }

    const updateUser = async (model: CreateUserModel): Promise<AdminCreateUserResult> => {
        const result = await axios.post<AdminCreateUserResult>(`${apiPath}/admin/users/updateprofile`, model);
        return result.data;
    }

    const getUserSnapshotByEmail = async (email: string): Promise<UserManifestReport | null> => {
        const result = await axios.get<UserManifestReport>(`${apiPath}/admin/logs/report/user?emailAddress=${email}`);
        return result.status === StatusCodes.NO_CONTENT ? null : result.data;
    }

    const searchUsers = async (query: string): Promise<Array<PersonLight>> => {
        const result = await axios.get<Array<PersonLight>>(`${apiPath}/admin/users/search/${query}`);
        return result.data ?? [];
    }

    return {
        GetLocationInfo: getLocationInfo,
        GetGroupInvitations: getGroupInvitations,
        AddLocation: addLocation,
        VerifyInviteeByEmail: verifyInviteeByEmail,
        GetUserProfile: getUserProfile,
        CreateUser: createUser,
        UpdateUser: updateUser,
        GetUserSnapshotByEmail: getUserSnapshotByEmail,
        SearchUsers: searchUsers
    };
}