import { ReactElement, useContext, useState } from "react";
import style from "./categorymanagement.module.scss";
import classNames from "classnames";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LocalizationContext } from "../../../interfaces/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Category } from "../../../models/Category";
import { app } from "../../..";
import { IsNullOrWhiteSpace, IsUndefinedOrNull } from "../../../misc/Utilities";
import { NotificationView, NotificationType } from "../../../components/NotificationView";
import { PubSubTopic } from "../../../misc/Constants";
import { Message } from "../../../misc/EventMessages";
import { appStore } from "../../../store/appStore";

export const UpdateCategoryForm = ({ categories }: { categories: Category[] }): ReactElement => {

    const { categoryId } = useParams();
    const { localization } = useContext(LocalizationContext);
    const editCategory = categories.find(c => c.id === categoryId);
    const nav = useNavigate();

    const { refreshVocabulary } = appStore(state => state);

    const safeValue = (val: string | undefined | null): string => {
        if (IsUndefinedOrNull(val)) return "";
        return localization[val];
    };

    const [name, setName] = useState(safeValue(editCategory?.labelKey));
    const [description, setDescription] = useState(safeValue(editCategory?.descriptionKey));
    const [example, setExample] = useState(safeValue(editCategory?.exampleKey));
    const [error, setError] = useState("");
    const [working, setWorking] = useState(false);

    const updateCategory = (): void => {

        const model = {
            name: name,
            description: description,
            example: example,
            id: categoryId
        };

        setWorking(true);

        axios.post(`${app.apiBasePath}/admin/updatecategory`, model)
            .then(result => {
                setWorking(false);
                if (!IsNullOrWhiteSpace(result.data)) {
                    setError(result.data.errorMessage);
                }
                refreshVocabulary("fr");
                nav("/admin/categories/");
            }).catch(e => {
                console.warn(e);
                setWorking(false);
            });
    };
    const deleteCategory = (): void => {
        if (window.confirm("delete ?")) {
            const model = {
                id: categoryId
            };

            setWorking(true);

            axios.post(`${app.apiBasePath}/admin/deletecategory`, model)
                .then(result => {
                    setWorking(false);
                    refreshVocabulary("fr");
                    nav("/admin/categories/");
                }).catch(e => {
                    console.warn(e);
                    setWorking(false);
                });
        }

    };

    if (IsUndefinedOrNull(editCategory)) {
        return (
            <div className={style.container}>category not found</div>
        );
    }

    return (
        <div className={style.container}>
            <div className={classNames("form", style.form)}>
                <h1>{localization["categoryManagement"]}</h1>

                <div className="row">
                    <label>{localization["categoryName"]}</label>
                    <input disabled={working} value={name} onChange={e => setName(e.target.value)} type="text" className="form-control" />
                </div>
                <div className="row">
                    <label>{localization["categoryDescription"]}</label>
                    <input disabled={working} value={description} onChange={e => setDescription(e.target.value)} type="text" className="form-control" />
                </div>
                <div className="row">
                    <label>{localization["categoryExample"]}</label>
                    <input disabled={working} value={example} onChange={e => setExample(e.target.value)} type="text" className="form-control" />
                </div>

                <div className="row buttons">
                    {
                        !working &&
                        <Link className="btn action" to="/admin/categories">{localization["cancel"]}</Link>
                    }
                    <button disabled={working} className="btn cancel" onClick={deleteCategory}>
                        <FontAwesomeIcon className="before" icon="trash" />
                        <span>{localization["delete"]}</span>
                    </button>
                    <button disabled={working} className="btn save" onClick={updateCategory}>
                        <FontAwesomeIcon className="before" icon="floppy-disk" />
                        <span>{localization["save"]}</span>
                    </button>
                </div>

                {
                    !IsNullOrWhiteSpace(error) &&
                    <NotificationView type={NotificationType.Error}>
                        <p>{error}</p>
                    </NotificationView>
                }
            </div>
        </div>
    )
};