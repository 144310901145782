import { FC, ReactElement } from 'react';
import style from './personwidget.module.scss';
import { app } from '../..';

export interface PersonWidgetLightProps {
    avatarFileName: string;
    name: string;
    size?: number;
}
export const PersonWidgetLight: FC<PersonWidgetLightProps> = ({ avatarFileName, name, size }): ReactElement => {


    const overrideStyles: React.CSSProperties = {};

    if (size) {
        overrideStyles.width = `${size}px`;
        overrideStyles.height = `${size}px`;
    }

    return (
        <div className={style.controllight}>
            <div className={style.icon} style={overrideStyles}>
                <img src={`${app.apiBasePath}/assets/image/${avatarFileName}`} alt={name} />
            </div>
        </div>
    )
}
