import { create } from "zustand";
import { Person } from "../models/Person";
import { Dictionary } from "../interfaces/Dictionary";
import { Group } from "../models/Group";
import { Category } from "../models/Category";
import { Locale } from '../models/Locale';
import { GroupPath } from "../models/GroupPath";
import { CountryOptionType } from "../models/OptionType";
import { Exchange } from "../models/Exchange";
import { GetCategories, GetLocalization } from '../misc/Requests';
export type AppStore = {

    person: Person | null;
    localization: Dictionary<string>;
    groups: Array<Group>;
    categories: Array<Category>;
    //localGroups: Array<GroupInfo>;
    groupPath: Array<GroupPath>;
    exchanges: Array<Exchange>;
    countryOptions: Array<CountryOptionType>;
    refreshVocabulary: (locale: Locale) => void;
    update?: <K extends keyof Omit<AppStore, "update">>(key: K, value: AppStore[K]) => void;
}

const handleVocabularyRefresh = async (locale : Locale, store: AppStore) : Promise<void> => {
    const vocabulary = await GetLocalization(locale);
    const categories = await GetCategories();
    store.update("localization", vocabulary);
    store.update("categories", categories);
}


export const appStore = create<AppStore>((set) => ({
    person: null,
    localization: {},
    groups: [],
    categories: [],
    //localGroups: [],
    groupPath: [],
    exchanges: [],
    countryOptions: [],
    update: (key, value) => set((state) => ({ [key]: value })),
    refreshVocabulary: async (locale: Locale) => {
        await handleVocabularyRefresh(locale, this);
    }
}));
