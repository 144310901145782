import axios from "axios";
import { Group } from "../models/Group";
import { app } from "..";
import { GroupUser } from "../models/GroupUser";
import { Invitation } from "../models/Invitation";
import { VerifyInviteeByEmailResult } from "../models/VerifyInviteeByEmailResult";
import { StatusCodes } from "http-status-codes";
import { GroupMessageEntry } from "../models/GroupMessageEntry";
import { StringIsNullOrEmpty } from "../misc/Utilities";
import { GroupPost } from "../models/GroupPost";

export interface GroupRequests {
    GetGroupById: (groupId: string) => Promise<Group | null>;
    GetGroupUsers: (groupId: string) => Promise<Array<GroupUser | null>>;
    GetGroupUser: (groupId: string, userId: string) => Promise<GroupUser | null>;
    GetGroupsByParentLocationId: (locationId: string) => Promise<Array<Group | null>>;
    GetGroupInvitations: (groupId: string) => Promise<Array<Invitation>>;
    VerifyInviteeByEmail: (groupId: string, email: string) => Promise<VerifyInviteeByEmailResult>;

    CreateGroupMessage: (message: GroupMessageEntry) => Promise<string>;
    UpdateGroupMessage: (message: GroupMessageEntry) => Promise<string>;
    GetGroupMessages: (groupId: string) => Promise<Array<GroupPost>>;
    GetGroupMessageById: (groupId: string, messageId: string) => Promise<GroupPost | null>;
}

export const groupRequests = (apiPath: string): GroupRequests => {

    const getGroupById = async (groupId: string): Promise<Group | null> => {
        try {

            const result = await axios.get<Group>(`${apiPath}/groupadmin/${groupId}/info`);
            if (result.status === StatusCodes.NOT_FOUND) {
                return null;
            }
            return result.data;
        }
        catch (e) {
            console.warn(e);
            return null;
        }
    }

    const getGroupUsers = async (groupId: string): Promise<Array<GroupUser | null>> => {
        try {
            const result = await axios.get(`${app.apiBasePath}/groupadmin/${groupId}/listusers`);
            return result.data;
        }
        catch (e) {
            console.warn(e);
            return null;
        }
    }

    const getGroupsByParentLocationId = async (locationId: string): Promise<Array<Group | null>> => {
        try {
            const result = await axios.get<Array<Group>>(`${apiPath}/admin/groups?locationId=${locationId}`);
            return result.data;
        }
        catch (e) {
            console.warn(e);
            return null;
        }
    }

    const getGroupInvitations = async (groupId: string): Promise<Array<Invitation>> => {
        let path = `${apiPath}/groupadmin/${groupId}/invitations`;

        try {
            const result = await axios.get<Array<Invitation>>(path);
            return result.data;
        }
        catch (e) {
            console.warn(e);
            return [];
        }
    }

    const verifyInviteeByEmail = async (groupId: string, email: string): Promise<VerifyInviteeByEmailResult> => {
        const result = await axios.get<VerifyInviteeByEmailResult>(`${apiPath}/groupadmin/${groupId}/invitations/verify/${email}`);
        return result.data;
    }

    const getGroupUser = async (groupId: string, userId: string): Promise<GroupUser> => {
        try {
            const result = await axios.get(`${app.apiBasePath}/groupadmin/${groupId}/getuser/${userId}`);
            return result.data;
        }
        catch (e) {
            console.warn(e);
            return null;
        }
    }

    const createGroupMessage = async (message: GroupMessageEntry): Promise<string> => {
        if (StringIsNullOrEmpty(message?.groupId)) {
            throw new Error("groupId is required");
        }

        try {

            const result = await axios.post(`${app.apiBasePath}/groupadmin/${message.groupId}/message/create`, message);
            return result.data;
        }
        catch (e) {
            console.warn(e);
            return null;
        }
    }

    const updateGroupMessage = async (message: GroupMessageEntry): Promise<string> => {
        if (StringIsNullOrEmpty(message?.groupId)) {
            throw new Error("groupId is required");
        }

        if (StringIsNullOrEmpty(message?.postId)) {
            throw new Error("id is required");
        }

        try {

            const result = await axios.put(`${app.apiBasePath}/groupadmin/${message.groupId}/message/update`, message);
            return result.data;
        }
        catch (e) {
            console.warn(e);
            return null;
        }
    }

    const getGroupMessages = async (groupId: string): Promise<Array<GroupPost>> => {
        try {
            const result = await axios.get(`${app.apiBasePath}/groupadmin/${groupId}/posts`);
            return result.data;
        }
        catch (e) {
            console.warn(e);
            return null;
        }
    }

    const getGroupMessageById = async (groupId: string, messageId: string): Promise<GroupPost | null> => {
        
        const result = await axios.get(`${app.apiBasePath}/groupadmin/${groupId}/showpost/${messageId}`);
        if(result.status === StatusCodes.NOT_FOUND){
            return null;
        }
        
        return result.data;
    }


    return {
        GetGroupById: getGroupById,
        GetGroupUsers: getGroupUsers,
        GetGroupsByParentLocationId: getGroupsByParentLocationId,
        GetGroupInvitations: getGroupInvitations,
        VerifyInviteeByEmail: verifyInviteeByEmail,
        GetGroupUser: getGroupUser,
        CreateGroupMessage: createGroupMessage,
        UpdateGroupMessage: updateGroupMessage,
        GetGroupMessages: getGroupMessages,
        GetGroupMessageById: getGroupMessageById
    };
}
