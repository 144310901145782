import axios from "axios";
import { Location } from "../models/Location";
import { Country } from "../models/Country";
import { AccountInfo } from "../models/AccountInfo";
import { StatusCodes } from "http-status-codes";
import { HistoryEntry } from "../models/HistoryEntry";
import { GroupPath } from "../models/GroupPath";
import { GroupInfo } from "../models/JoinedGroup";
import { Dictionary } from "lodash";
import { GroupPerson } from "../models/GroupPerson";
import { GroupPost } from "../models/GroupPost";

export interface ApiRequests {
    GetCountries: () => Promise<Country[]>;
    GetChildLocations: (parentLocationId: string) => Promise<Location[]>;
    GetAccountInfoByEmail: (email: string) => Promise<AccountInfo | null>;
    GetInvitationCancellationReason: (invitationId: string) => Promise<HistoryEntry | null>;
    SearchGroupsNameContains: (search: string) => Promise<Array<GroupPath>>;
    GetGroupsByIds: (groupIds: string[]) => Promise<Dictionary<GroupInfo>>;
    GetGroupPeople: (groupId: string) => Promise<Array<GroupPerson>>;
    GetGroupMessages: (groupId: string) => Promise<Array<GroupPost>>;
    GetGroupInfoNotifications : () => Promise<string[]>;
    LogGroupInfoView: (groupId: string) => Promise<void>;
}
export const apiRequests = (apiPath: string): ApiRequests => {

    const getCountries = async (): Promise<Country[]> => {

        const result = await axios.get<Country[]>(`${apiPath}/api/getcountries`);
        return result.data ?? [];
    }

    const getChildLocations = async (parentLocationId: string): Promise<Location[]> => {

        const result = await axios.get<Location[]>(`${apiPath}/api/getchildlocations?parentLocation=${parentLocationId}`);
        return result.data ?? [];
    }

    const getInvitationCancellationReason = async (invitationId: string): Promise<HistoryEntry | null> => {
        const result = await axios.get<HistoryEntry>(`${apiPath}/history/entry?id=${invitationId}`);

        if (result.status === StatusCodes.NO_CONTENT) {
            return null;
        }
        else return result.data;
    }

    const getAccountInfoByEmail = async (email: string): Promise<AccountInfo | null> => {
        const result = await axios.get<AccountInfo>(`${apiPath}/search/account?email=${email}`);

        if (result.status === StatusCodes.NO_CONTENT) {
            return null;
        }
        else return result.data;
    };

    const searchGroupsNameContains = async (search: string): Promise<Array<GroupPath>> => {
        const urlEncoded = encodeURIComponent(search);
        const result = await axios.get<Array<GroupPath>>(`${apiPath}/api/searchgroup/${urlEncoded}`);
        return result.data;
    }

    const getGroupsByIds = async (groupIds: string[]): Promise<Dictionary<GroupInfo>> => {
        const result = await axios.post(`${apiPath}/api/getgroups`, groupIds);
        return result.status === StatusCodes.NO_CONTENT ? {} : result.data;
    }

    const getGroupPeople = async (groupId: string): Promise<Array<GroupPerson>> => {
        const result = await axios.get<Array<GroupPerson>>(`${apiPath}/api/group/${groupId}/people`);
        return result.data;
    }

    const getGroupMessages = async (groupId: string): Promise<Array<GroupPost>> => {
        const result = await axios.get<Array<GroupPost>>(`${apiPath}/api/group/${groupId}/posts`);
        return result.data;
    }

    const getGroupInfoNotifications = async (): Promise<string[]> => {
        const result = await axios.get<string[]>(`${apiPath}/api/getgroupinfonotifications`);
        return result.data;
    }

    const LogGroupInfoView = async (groupId: string): Promise<void> => {
        await axios.post(`${apiPath}/api/${groupId}/loggroupinfoviews`);
    }
    

    return {
        GetCountries: getCountries,
        GetChildLocations: getChildLocations,
        GetInvitationCancellationReason: getInvitationCancellationReason,
        GetAccountInfoByEmail: getAccountInfoByEmail,
        SearchGroupsNameContains: searchGroupsNameContains,
        GetGroupsByIds: getGroupsByIds,
        GetGroupPeople: getGroupPeople,
        GetGroupMessages: getGroupMessages,
        GetGroupInfoNotifications: getGroupInfoNotifications,
        LogGroupInfoView: LogGroupInfoView
    }
}