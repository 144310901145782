import { ReactElement, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { GroupUser } from "../../../models/GroupUser";
import { HasPrivilege } from "../../../misc/Utilities";
import { GroupPrivileges } from "../../../models/Enums";
import { LocalizationContext } from "../../../interfaces/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import { groupKeys } from "../../../queryKeys/groupKeys";
import { groupManagementRequests } from "../../../requests/groupManagementRequests";
import { app } from "../../..";
import Loading from "react-loading";
import { GetContext } from "../../../misc/Requests";
import { appStore } from "../../../store/appStore";
import style from "./groupmanage.module.scss";

export const ManageGroupForm = (): ReactElement => {

    const queryClient = useQueryClient();
    const { groupId } = useParams();
    const nav = useNavigate();
    const { localization } = useContext(LocalizationContext);

    const [isEdit, setIsEdit] = useState(false);
    const [tempGroupName, setTempGroupName] = useState("");

    const { update } = appStore();

    const {
        GetGroupById,
        GetGroupUsers,
        RenameGroup,
        MakeFacilitator,
        RemoveFacilitator,
        DeleteGroup
    } = groupManagementRequests(app.apiBasePath);

    const [{ data: group, isLoading: groupsLoading }, { data: people, isLoading: usersLoading }] = useQueries({
        queries: [
            {
                queryFn: () => GetGroupById(groupId),
                queryKey: groupKeys.byId(groupId),
                staleTime: 1000 * 30,
            },
            {
                queryFn: () => GetGroupUsers(groupId),
                queryKey: groupKeys.groupUsers(groupId),
                staleTime: 1000 * 30,
            }
        ]
    });

    const groupRename = useMutation({
        mutationFn: () => RenameGroup(groupId, tempGroupName),
        onSuccess: () => {
            setIsEdit(false);
            queryClient.invalidateQueries({
                queryKey: groupKeys.byId(groupId)
            });
        }
    });

    const makeFacilitatorHandler = useMutation({
        mutationFn: (user: GroupUser) => MakeFacilitator(groupId, user),
        onSuccess: () => {
            updateContext();
            queryClient.invalidateQueries({
                queryKey: groupKeys.groupUsers(groupId)
            });
        }
    });

    const removeFacilitatorHandler = useMutation({
        mutationFn: (user: GroupUser) => RemoveFacilitator(groupId, user),
        onSuccess: () => {
            updateContext();
            queryClient.invalidateQueries({
                queryKey: groupKeys.groupUsers(groupId)
            });
        }
    });

    const updateContext = (): void => {
        GetContext("groupInfo").then((res) => {
            if (res !== null) {
                update("groups", res.context.groups);
                update("groupPath", res.context.groupPath);
            }
        });
    }

    const deleteGroupHandler = useMutation({
        mutationFn: () => DeleteGroup(groupId),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: groupKeys.byId(groupId)
            });
            nav("/admin/groups");
        }
    });

    const deleteGroupCheck = (): void => {
        if (window.confirm(`Supprimer ${group.name}?`)) {
            deleteGroupHandler.mutate();
        }
    };

    const editGroupTitle = (): void => {
        setTempGroupName(group.name);
        setIsEdit(true);
    };

    const validGroupName = (value: string): boolean => {
        return value.trim().length >= 3;
    }

    if (groupsLoading || usersLoading || deleteGroupHandler.isPending) {
        return <Loading />;
    }

    return (
        <div className={style.container}>
            <div className="form">
                {
                    group !== null &&
                    <div className="row">
                        {
                            !isEdit &&
                            <div className={style.groupname}>
                                <h2>{group.name}</h2>
                                <button onClick={editGroupTitle}>
                                    <FontAwesomeIcon icon={"pen"} />
                                </button>
                            </div>
                        }
                        {
                            isEdit &&
                            <div className={style.groupnameedit}>
                                <h2>Renommer {group.name}</h2>
                                <input
                                    disabled={groupRename.isPending}
                                    onKeyDown={key => key.code === "Escape" && setIsEdit(false)}
                                    type="text"
                                    maxLength={120}
                                    className="form-control"
                                    value={tempGroupName}
                                    onChange={e => setTempGroupName(e.target.value)}
                                />

                                <div className={style.buttons}>
                                    <button disabled={groupRename.isPending} onClick={() => setIsEdit(false)} className="btn cancel">{localization["cancel"]}</button>
                                    <button disabled={!validGroupName(tempGroupName) || groupRename.isPending} onClick={() => groupRename.mutate()} className="btn save">{localization["save"]}</button>
                                </div>
                            </div>
                        }
                    </div>
                }

                <div>
                    <button onClick={() => nav(-1)} className="btn action">
                        {localization["return"]}
                    </button>
                    {
                        people.length === 0 &&
                        !isEdit &&
                        <button style={{ marginLeft: '5px' }} onClick={() => deleteGroupCheck()} className="btn cancel">{localization["delete"]} {group.name}</button>
                    }
                </div>

                <div className={style.list}>
                    {
                        people.length < 1 &&
                        <div>
                            <p>Aucun utilisateur</p>
                        </div>
                    }
                    {
                        people.map(p => {
                            return (
                                <div className={style.user} key={p.personId}>
                                    <div className={style.ug}>
                                        <span>{p.displayName}</span>

                                        {
                                            !HasPrivilege(p.privileges, GroupPrivileges.Facilitator) &&
                                            <a onClick={(e) => { e.preventDefault(); makeFacilitatorHandler.mutate(p) }}>{localization["promoteLabel"]}</a>
                                        }
                                        {
                                            HasPrivilege(p.privileges, GroupPrivileges.Facilitator) &&
                                            <a className={style.remove} onClick={(e) => { e.preventDefault(); removeFacilitatorHandler.mutate(p) }}>retirer</a>
                                        }
                                    </div>
                                    <div className={style.email}>
                                        <span>{p.emailAddress}</span>
                                    </div>
                                    <div className={style.tags}>
                                        {
                                            HasPrivilege(p.privileges, GroupPrivileges.Facilitator) &&
                                            <span className={style.g}>{localization["facilitatorLabel"]}</span>
                                        }
                                        {
                                            HasPrivilege(p.privileges, GroupPrivileges.CanPost) &&
                                            <span>{localization["publish"]?.toLowerCase()}</span>
                                        }

                                        {
                                            HasPrivilege(p.privileges, GroupPrivileges.CanSearch) &&
                                            <span>{localization["search"]?.toLowerCase()}</span>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>


            </div>
        </div >
    );
};