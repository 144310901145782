import { ReactElement, useState, useContext } from "react"
import { app } from "../..";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { IsUndefinedOrNull, StringIsNullOrEmpty, stateToText } from "../../misc/Utilities";
import { ReactMultiSelectStyles } from "../../misc/Constants";
import Select from "react-select";
import { OptionType } from "../../models/OptionType";
import { InvitationState } from "../../models/Enums";
import { LocalizationContext } from "../../interfaces/AppContext";
import Loading from "react-loading";
import { groupRequests } from "../../requests/groupRequests";
import { useQuery } from "@tanstack/react-query";
import { groupKeys } from "../../queryKeys/groupKeys";
import style from "./invitations.module.scss";
import { AvailableHeightProvider } from "../../components/AvailableHeightProvider";
import { InvitationsTable } from "../../components/InvitationsTable/InvitationsTable";
import { Invitation } from "../../models/Invitation";
import { apiRequests } from "../../requests/apiRequests";
import { HistoryEntry } from "../../models/HistoryEntry";

export const Invitations = (): ReactElement => {

    const params = useParams();
    const groupId = params["groupid"];

    const { GetInvitationCancellationReason } = apiRequests(app.apiBasePath);
    const { GetGroupInvitations } = groupRequests(app.apiBasePath);

    const { data: invitations, isLoading } = useQuery({
        queryFn: () => GetGroupInvitations(groupId),
        queryKey: groupKeys.groupInvitations(groupId),
        enabled: !StringIsNullOrEmpty(groupId)
    });

    const [filter, setFilter] = useState("");

    const [stateFilters, setStateFilters] = useState([] as OptionType[]);
    const { localization } = useContext(LocalizationContext);

    if (isLoading) {
        return (
            <div className={style.container}>
                <Loading />
            </div>
        );
    }

    const stateOptions: OptionType[] = [
        { value: InvitationState.New, label: stateToText(InvitationState.New, localization) },
        { value: InvitationState.Opened, label: stateToText(InvitationState.Opened, localization) },
        { value: InvitationState.Redeemed, label: stateToText(InvitationState.Redeemed, localization) },
        { value: InvitationState.Cancelled, label: stateToText(InvitationState.Cancelled, localization) }
    ];

    const filteredList = invitations.filter(p => {
        return p.emailAddress.toLowerCase().includes(filter.toLowerCase()) ||
            p.firstName.toLowerCase().includes(filter.toLowerCase()) ||
            p.lastName.toLowerCase().includes(filter.toLowerCase());
    });

    const showCancellationMessage = (inv: Invitation): void => {
        GetInvitationCancellationReason(inv.uniqueShortTokenId).then((reason: HistoryEntry | null) => {
            if (reason) {
                alert(reason.message);
            }
        });
    }

    let filteredInvitations = invitations?.filter(inv => stateFilters.length === 0 || stateFilters.some(f => f.value === inv.state));

    return (
        <div className={style.container}>
            <div style={{ margin: "0 0 15px" }}>
                <div className={style.controls}>
                    <div className={style.selector}>
                        <input
                            value={filter}
                            onKeyDown={(e) => { e.key === "Escape" && setFilter(""); }}
                            onChange={(e) => setFilter(e.target.value)}
                            type="text" className={style.searchfield} placeholder={localization["search"]} />
                    </div>
                    <div className={style.selector}>
                        <Select
                            placeholder="filtrer"
                            styles={ReactMultiSelectStyles}
                            value={stateFilters} onChange={(f) => setStateFilters(f as OptionType[])}
                            isMulti={true}
                            options={stateOptions} />
                    </div>
                    <div>
                        <Link className={classNames("btn", "action", style.invitebtn)} to={`/group/manage/${groupId}/invite`}>
                            <span>Inviter</span>
                            <FontAwesomeIcon icon={"user-plus"} />
                        </Link>
                    </div>
                </div>
            </div>
            <div>
                {
                    !IsUndefinedOrNull(invitations) &&
                    <AvailableHeightProvider>
                        <InvitationsTable
                            onGetCancellationHistory={(inv: Invitation) => showCancellationMessage(inv)}
                            items={filteredInvitations} />
                    </AvailableHeightProvider>
                }
            </div>
        </div>
    );
}