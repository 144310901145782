import React, { FC, PropsWithChildren, ReactElement } from 'react';
import style from '../styles/notification.module.scss';
import classnames from 'classnames';

export enum NotificationType {
    Info,
    Warning,
    Error
}
export interface NotificationProps extends PropsWithChildren {
    type: NotificationType;
}

const NotificationView: FC<NotificationProps> = ({ type, children }): ReactElement => {

    let css = classnames(style.notice, style.info);

    if (type === NotificationType.Warning) {
        css = classnames(style.notice, style.warn);
    }
    if (type === NotificationType.Error) {
        css = classnames(style.notice, style.error);
    }
    return <div className={css}>{children}</div>;
}

export { NotificationView }