import { ReactElement, useState } from "react";

import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import { AnonymousMaster } from "./AnonymousMaster";
import { AgreementView } from "./Agreements/AgreementView";
import { app } from "..";
import { AppContext, LocalizationContext } from "../interfaces/AppContext";
import { Dictionary } from "../interfaces/Dictionary";
import { appStore } from "../store/appStore";

export const AgreementApp = ({ localization }: { localization: Dictionary<string> }): ReactElement => {
    const { person } = appStore();

    return (

        <AppContext.Provider value={{
            person: person,
            groups: [],
            groupPath: [],
            favorites: []
        }}>
            <LocalizationContext.Provider value={{
                localization: localization
            }}>

                <BrowserRouter>
                    <Routes>
                        <Route path={"*"} element={<AnonymousMaster />}>
                            <Route path="*" element={<AgreementView />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </LocalizationContext.Provider>
        </AppContext.Provider>
    );
};