import { FC, ReactElement } from "react"
import { StringIsNullOrEmpty } from "../../misc/Utilities"
import { GroupPerson } from "../../models/GroupPerson"
import { app } from "../..";
import style from './personwidget.module.scss';
import SettingsIcon from '@mui/icons-material/Settings';


import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { ExchangeType } from "../../models/ExchangeType";

export interface PersonWidgetProps {
    person: GroupPerson;
    exchangesCount: number;
    view: ExchangeType;
}

export const PersonWidget: FC<PersonWidgetProps> = ({ person, exchangesCount, view }): ReactElement => {


    return (
        <div className={style.control} title={person.displayName}>
            {
                person.isFacilitator &&
                <div className={style.f}>
                    <SettingsIcon />
                </div>
            }
            {
                !StringIsNullOrEmpty(person.avatarFileId) &&
                <div className={style.icon}>
                    <img src={`${app.apiBasePath}/assets/image/${person.avatarFileId}`} alt={person.displayName} />
                </div>
            }
            {
                StringIsNullOrEmpty(person.avatarFileId) &&
                <div className={style.unknown}>
                    <SentimentSatisfiedAltIcon />
                </div>
            }
            {
                exchangesCount > 0 &&
                <mark>
                    <span className={view === "demande" && style.d}><span>{exchangesCount}</span></span>
                </mark>
            }

            <span>{person.displayName}</span>
        </div >
    )
}