import axios from "axios";
import { GroupPrivileges } from "../models/Enums";
import { GroupUser } from "../models/GroupUser";
import { Invitation } from "../models/Invitation";
import { StatusCodes } from "http-status-codes";
import { Group } from "../models/Group";

export interface GroupManagementRequests {
    GetGroupById: (groupId: string) => Promise<Group | null>;
    RenameGroup: (groupId: string, name: string) => Promise<void>;
    MakeFacilitator: (groupId: string, user: GroupUser) => Promise<void>;
    RemoveFacilitator: (groupId: string, user: GroupUser) => Promise<void>;
    DeleteGroup: (groupId: string) => Promise<void>;
    GetInvitationByCode: (code: string) => Promise<Invitation | null>;
    GetGroupUsers: (groupId: string) => Promise<Array<GroupUser | null>>;
}



export const groupManagementRequests = (apiPath: string) => {

    const getGroupById = async (groupId: string): Promise<Group | null> => {
        try {
            const result = await axios.get<Group>(`${apiPath}/admin/group/info?groupId=${groupId}`);
            return result.data;
        }
        catch (e) {
            console.warn(e);
            return null;
        }
    }

    const renameGroup = async (groupId: string, name: string): Promise<void> => {
        try {
            await axios.post(`${apiPath}/admin/group/rename`, {
                groupId: groupId,
                groupName: name
            });
        }
        catch (e) {
            console.warn(e);
        }

    }

    const makeFacilitator = async (groupId: string, user: GroupUser): Promise<void> => {
        try {
            await axios.post(`${apiPath}/admin/group/updateprivileges`, {
                personId: user.personId,
                groupId: groupId,
                justification: '',
                privileges: user.privileges | GroupPrivileges.Facilitator
            });
        }
        catch (e) {
            console.warn(e);
        }
    }

    const removeFacilitator = async (groupId: string, user: GroupUser): Promise<void> => {
        try {
            await axios.post(`${apiPath}/admin/group/updateprivileges`, {
                personId: user.personId,
                groupId: groupId,
                justification: '',
                privileges: user.privileges & ~GroupPrivileges.Facilitator
            });
        }
        catch (e) {
            console.warn(e);
        }
    }

    const deleteGroup = async (groupId: string): Promise<void> => {
        try {
            await axios.post(`${apiPath}/admin/group/delete`, {
                groupId: groupId
            });
        }
        catch (e) {
            console.warn(e);
        }
    }

    const getInvitationByCode = async (code: string): Promise<Invitation | null> => {
        try {
            const result = await axios.get<Invitation>(`${apiPath}/admin/invitationinfo/${code}`);
            return result.status === StatusCodes.NOT_FOUND ? null : result.data;
        }
        catch (e) {
            console.warn(e);
            return null;
        }
    }

    const getGroupUsers = async (groupId: string): Promise<Array<GroupUser | null>> => {
        try {
            const result = await axios.get<Array<GroupUser>>(`${apiPath}/admin/group/listusers?groupId=${groupId}`);
            return result.data;
        }
        catch (e) {
            console.warn(e);
            return null;
        }
    }



    return {
        GetGroupById: getGroupById,
        RenameGroup: renameGroup,
        MakeFacilitator: makeFacilitator,
        RemoveFacilitator: removeFacilitator,
        DeleteGroup: deleteGroup,
        GetInvitationByCode: getInvitationByCode,
        GetGroupUsers: getGroupUsers
    };
}