import { ReactElement, useContext, useState } from "react";
import { LocalizationContext } from "../../interfaces/AppContext";
import { Checkbox } from "../Checkbox";

import style from "./search-settings.module.scss";


export interface SearchSettingsProps {
    includedMyExchangesInResults: boolean;
    onSettingsChange: (settings: SearchSettings) => void;
}

export type SearchSettings = {
    includedMyExchangesInResults: boolean;
}

export const SearchSettings = (props: SearchSettingsProps): ReactElement => {

    const { localization } = useContext(LocalizationContext);

    const { includedMyExchangesInResults, onSettingsChange } = props;

    const [a, setA] = useState(includedMyExchangesInResults);
 

    const onApply = (): void => {
        typeof onSettingsChange === "function" && onSettingsChange({
            includeResultsFromIndependentUsers: a,
                        includedMyExchangesInResults: a
        } as SearchSettings);
    };

    return (
        <div className={style.control}> 
            <div className={style.row}>
                <Checkbox checked={a} onCheckChange={() => { setA(!a) }} label={localization["useMyExchangesInResults"]} />
            </div>
            <div className={style.footer}>
                <button onClick={onApply} className="btn action">Appliquer</button>
            </div>
        </div>
    );
};