import { FC } from "react";
import { StringIsNullOrEmpty } from "../misc/Utilities";
import style from "../styles/radio.module.scss";

export interface RadioProps {
    label?: string;
    value: string | number;
    group: string;
    disabled?: boolean;
    currentValue: string | number;
    onChange: (value: string) => void;
}

const Radio: FC<RadioProps> = ({
    label = "",
    value,
    currentValue,
    group,
    disabled,
    onChange
}) => {
    return (

        <div className={style.container}>
            <label>
                <input
                    onChange={() => null}
                    onClick={(e) => onChange(e.target["value"])}
                    disabled={disabled}
                    type="radio"
                    checked={currentValue === value}
                    name={group}
                    value={value} />
                {

                }
                {
                    !StringIsNullOrEmpty(label) &&
                    <span>{label}</span>
                }

            </label>
        </div>
    );
}

export { Radio }