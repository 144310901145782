import { FC, ReactElement, useContext, useState } from "react";
import { GroupInfo } from "../../models/JoinedGroup";
import classNames from "classnames";
import { AppContext, LocalizationContext } from "../../interfaces/AppContext";
 
import { AutocompleteGroup } from "../Autocomplete/Groups/AutocompleteGroup";
import { GroupPath } from "../../models/GroupPath";
import { isEmpty, isFunction, set } from "lodash";
import GroupIcon from '@mui/icons-material/Group';
import ClearIcon from '@mui/icons-material/Clear';
import style from "./groupselector.module.scss";
import { homeStore } from "../../store/homeStore";

export interface GroupSelectorProps {
    selected?: Array<GroupInfo>;
    onSelectionChange?: (collection: Array<GroupInfo>) => void;
}

export const GroupSelector: FC<GroupSelectorProps> = ({ selected, onSelectionChange }): ReactElement => {


    const [groupSearchKey, setGroupSearchKey] = useState<string>("initial-static");

    const { groups: myGroups } = useContext(AppContext);

    const { localization } = useContext(LocalizationContext);

    const { locations } = homeStore(state => state);

    const [groupList, setGroupList] = useState<Array<GroupInfo>>(isEmpty(selected) ? [] : selected);  

    const alphaSort = (a: string, b: string): number => {
        return a.localeCompare(b);
    };

    const selectMyGroups = (): void => {
        setGroupList([...myGroups.map(g => {
            return {
                id: g.id,
                name: g.name
            };
        })]);
    };

    const unselectAllGroups = (): void => {
        setGroupList([]);
    };


    const onApply = (): void => {
        typeof isFunction(onSelectionChange) && onSelectionChange(groupList);
    };

    return (
        <div className={style.control}>
            <div>
                <label>tapez quelque chose</label>
                <AutocompleteGroup
                    key={groupSearchKey}
                    onGroupSelect={(group: GroupPath) => {
                        // reset autocomplete control to flush all values

                        if (groupSearchKey !== group.group.id) {
                            setGroupSearchKey(group.group.id);
                        }
                        else {
                            setGroupSearchKey("initial-static");
                        }
                        const groupInfo: GroupInfo = {
                            id: group.group.id,
                            name: group.group.name
                        };

                        if (!groupList.some(p => p.id === groupInfo.id)) {
                            setGroupList([groupInfo, ...groupList]);
                        }
                    }
                    }
                />

                <div className={style.quickbtns}>
                    <button onClick={selectMyGroups}>{localization["myGroups"]}</button>
                    <button onClick={unselectAllGroups}>Recherche partout</button>
                </div>


            </div>
            <div className={style.overflow}>
                <div className={style.groupcollection}>
                    {

                        groupList.sort((a, b) => alphaSort(a.name, b.name))
                            .map(g => {
                                return (
                                    <div key={g.id} className={classNames(style.group, myGroups.some(p => p.id === g.id) ? style.mine : null)}>
                                        <div className={style.left}>
                                            <GroupIcon />
                                            <span>{g.name}</span>
                                        </div>
                                        <button className={style.clear} onClick={() => setGroupList(groupList.filter(p => p.id !== g.id))}>
                                            <ClearIcon />
                                        </button>
                                    </div>
                                )
                            })
                    }
                </div>
            </div>
            <div className={style.footer}>
                {
                    groupList.length === 0 &&
                    <span>{localization["searchAllInfo"]}</span>
                }
                {
                    groupList.length > 0 &&
                    <span>{groupList.length} groupes sélectionnés</span>
                }
              
                <button onClick={onApply} className="btn action">Appliquer</button>
            </div>
        </div>
    );
}