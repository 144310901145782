import { ReactElement, useContext, useEffect, useState } from "react";
import { app } from "../../..";
import { PersonWithOrphanInfo } from "../../../models/Person";
import { Link, useNavigate } from "react-router-dom";
import { LocalizationContext } from "../../../interfaces/AppContext";
import classNames from "classnames";
import style from "./usermanage.module.scss";
import { useQuery } from "@tanstack/react-query";
import { userKeys } from "../../../queryKeys/userKeys";
import Loading from "react-loading";
import { userRequests } from "../../../requests/userRequests";
import { appStore } from "../../../store/appStore";
import { adminUsersStore } from "../../../store/adminUsersStore";
import { CountryOptionType, OptionType } from "../../../models/OptionType";
import { apiRequests } from "../../../requests/apiRequests";
import Select from "react-select";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const UserManagement = (): ReactElement => {

    const nav = useNavigate();
    const { localization } = useContext(LocalizationContext);

    const { GetUsersForLocation } = userRequests(app.apiBasePath);
    const { GetChildLocations } = apiRequests(app.apiBasePath);

    const { person, countryOptions } = appStore(state => state);

    const [level2loading, setLevel2Loading] = useState<boolean>(false);
    const [level3loading, setLevel3Loading] = useState<boolean>(false);
    const [level4loading, setLevel4Loading] = useState<boolean>(false);

    const {
        searchQuery,
        selectedCountry,
        selectedProvince,
        selectedRegion,
        provincesCache,
        regionsCache,
        groupsCache,
        update } = adminUsersStore(state => state);



    let userLookupLocation: string | null = null;

    if (selectedProvince !== null) {
        if (selectedRegion !== null) {
            userLookupLocation = selectedRegion.value as string;
        }
        else {
            userLookupLocation = selectedProvince.value as string;
        }
    }

    const { data: people, isLoading: usersLoading } = useQuery({
        queryKey: userKeys.usersForLocation(userLookupLocation),
        queryFn: () => GetUsersForLocation(userLookupLocation),
        enabled: userLookupLocation !== null
    });

    const exp = new RegExp(searchQuery, "i");

    const finder = (person: PersonWithOrphanInfo) => {
        if (searchQuery.length < 2) return person;
        else {
            return exp.test(person.firstName) || exp.test(person.lastName) || exp.test(person.email);
        }
    };

    const onCountryChange = (opt: CountryOptionType) => {
        update("selectedCountry", opt);
        update("selectedProvince", null);
        update("selectedRegion", null);
        update("regionsCache", []);

        setLevel2Loading(true);
        GetChildLocations(opt.value as string).then(result => {
            setLevel2Loading(false);
            const provinces = result.map(location => {
                return {
                    value: location.id,
                    label: location.name
                } as OptionType
            });

            update("provincesCache", provinces);
            if (provinces.length === 1) {
                onProvinceChange(provinces[0]);
            }
        });
    };

    const onProvinceChange = (opt: OptionType) => {
        update("selectedProvince", opt);
        update("selectedRegion", null);

        setLevel3Loading(true);
        GetChildLocations(opt.value as string).then(result => {
            setLevel3Loading(false);
            const regions = result.map(location => {
                return {
                    value: location.id,
                    label: location.name
                } as OptionType
            });

            update("regionsCache", regions);

            if (regions.length === 1) {
                onRegionChange(regions[0]);
            }
        });
    }

    const onRegionChange = async (opt: OptionType) => {
        update("selectedRegion", opt);
        setLevel4Loading(true);
    }

    useEffect(() => {
        if (selectedCountry === null && countryOptions.length > 0) {

            const option = countryOptions.find(c => c.countryCode === person?.countryCode);
            if (option) {
                onCountryChange(option);
            }
        }
    }, []);

    if (usersLoading) {
        return <Loading />;
    }


    const filteredPeople = people?.filter(finder) ?? [];

    return (
        <div className={classNames(style.container, "form")}>
            <h2>{localization["userAdministration"]}</h2>
            <div className={style.filters}>
                <div className={style.country}>

                    <Select
                        placeholder={localization["countryLabel"]}
                        value={selectedCountry}
                        onChange={opt => onCountryChange(opt)}
                        options={countryOptions} />
                </div>
                <div className={style.province}>
                    <Select
                        placeholder={localization["provinceLabel"]}
                        value={selectedProvince}
                        options={provincesCache}
                        onChange={opt => onProvinceChange(opt)} />
                </div>
                <div className={style.region}>
                    <Select
                        isClearable={true}
                        placeholder={localization["regionLabel"]}
                        value={selectedRegion}
                        onChange={g => onRegionChange(g)}
                        options={regionsCache} />
                </div>
                <div className={style.separator}></div>
                <div>
                    <button className={classNames("btn", "save2", style.b)} onClick={() => nav("create")}>
                        <PersonAddIcon />
                    </button>
                </div>
            </div>

            <div className={style.filterfield}>
                <input type="text"
                    onChange={e => update("searchQuery", e.target.value)}
                    placeholder={localization["search"]}
                    value={searchQuery} />
                <div>
                    {
                        searchQuery.length > 0 &&
                        <button onClick={() => update("searchQuery", "")}>
                            <HighlightOffIcon />
                        </button>
                    }
                </div>
            </div>


            <div className={style.userlist}>
                <ul>
                    {
                        filteredPeople
                            .sort((a: PersonWithOrphanInfo, b: PersonWithOrphanInfo) => { return a.firstName.localeCompare(b.firstName) })
                            .map(p => {
                                return (
                                    <li key={p.personId}>
                                        <Link to={`/admin/users/${p.personId}`}><span className={style.name}>{p.firstName} {p.lastName}</span>
                                            <span className={style.email}>{p.email}</span></Link>
                                        {
                                            p.isOrphan &&
                                            <div className={style.info} title={localization["partOfNoGroup"]}>
                                                <WarningAmberIcon style={{ color: '#da5f17' }} />
                                            </div>
                                        }
                                    </li>
                                )
                            })
                    }
                </ul>
            </div>
        </div >
    );
}