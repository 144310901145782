import { NavItem } from "../interfaces/NavItem";
import { HasPrivilege, NormalizeToUrlName } from "../misc/Utilities";
import { ExchangeState, GroupPrivileges, Role } from "../models/Enums";
import { FavoriteExchange } from "../models/FavoriteExchange";
import { Group } from "../models/Group";
import { Exchange } from "../models/Exchange";
import { useContext } from "react";
import { LocalizationContext } from "../interfaces/AppContext";
import { Person } from "../models/Person";
import GroupsIcon from '@mui/icons-material/Groups';

export interface NavitaionBuilderProps {
    person: Person;
    exchanges: Exchange[];
    groups: Group[];
    favorites: FavoriteExchange[];
}

export interface AppNavitaion {
    HomeMenuOptions: () => NavItem;
    MyGroupsOptions: () => NavItem;
    MyExchangesMenuOptions: () => NavItem;
    ModeratorMenuOptions: () => NavItem | null;
    DevMenuOptions: () => NavItem;
    AdminMenuOptions: () => NavItem;
}

export const NavitaionBuilder = ({
    person,
    exchanges,
    groups,
    favorites
}: NavitaionBuilderProps): AppNavitaion => {



    const { localization: v } = useContext(LocalizationContext);

    const homeMenuOptions = (): NavItem => {

        return {
            label: "Recherche",
            fontAwesomeName: "search",
            color: "#2f688d",
            visible: true,
            disabled: false,
            path: "/",
            items: null
        }
    };

    const myGroupsOptions = (): NavItem => {
        return {
            label: "Mon groupe",
            visible: true,
            disabled: false,
            color: '#3b82b1',
            items: groups.map(g => {
                return {
                    label: g.name,
                    fontAwesomeName: "user-group",
                    path: `/g/${NormalizeToUrlName(g.name)}`,
                    uniqueId : g.id
                } as NavItem
            }),
            muiIcon: GroupsIcon
        };
    };

    const myExchangesMenuOptions = (): NavItem => {
        return {
            label: v["menuExchanges"],
            iconClass: "",
            disabled: false,
            visible: true,
            fontAwesomeName: "arrow-right-arrow-left",
            color: '#3b82b1',
            items: [
                {
                    label: `${v["myDrafts"]} (${exchanges.filter(e => e.state === ExchangeState.Draft).length})`,
                    fontAwesomeName: "pen",
                    color: '#284682',
                    visible: true,
                    disabled: false,
                    path: "/drafts"
                },
                {
                    label: `${v["myOffers"]} (${exchanges.filter(e => e.state === ExchangeState.Published && e.exchangeType === "offer").length})`,
                    visible: true,
                    fontAwesomeName: "hand-holding-heart",
                    color: '#284682',
                    disabled: false,
                    path: "/exchanges"
                },
                {
                    label: `${v["myDemands"]} (${exchanges.filter(e => e.state === ExchangeState.Published && e.exchangeType === "demande").length})`,
                    visible: true,
                    fontAwesomeName: "hand-point-up",
                    color: '#284682',
                    disabled: false,
                    path: "/demandes"
                },
                /* {
                     label: `${v["mySubscriptions"]}`,
                     visible: true,
                     fontAwesomeName: "bell",
                     color: "D81A59",
                     disabled: false,
                     path: "/subscriptions"
                 },*/
                {

                    label: `${v["myFavotires"]} (${favorites.length})`,
                    visible: true,
                    fontAwesomeName: "star",
                    color: "#F1931B",
                    disabled: false,
                    path: "/favorites"
                }
            ]
        }
    };

    const moderatorMenuOptions = (): NavItem | null => {

        const menuItems: NavItem[] = groups
            .filter(g => HasPrivilege(g.privilege, GroupPrivileges.Facilitator))
            .map(g => {
                return {
                    label: g.name,
                    fontAwesomeName: "user-group",
                    path: `/group/manage/${g.id}`,
                    disabled: false,
                    visible: true
                }
            });

        if (menuItems.length > 0) {
            return {
                label: "Gestion",
                iconClass: "ico-bolt-16",
                disabled: false,
                visible: true,
                items: menuItems
            }
        }
        else return null;
    };

    const devMenuOptions = (): NavItem => {
        const logs: NavItem = {
            label: "Logs",
            fontAwesomeName: "list-check",
            color: "#2f688d",
            path: "/dev/logs",
            disabled: false,
            visible: true
        };

        const debugs: NavItem = {
            label: "Debug User",
            fontAwesomeName: "person-circle-exclamation",
            color: "#f4581a",
            path: "/dev/userprofile",
            disabled: false,
            visible: true
        };

        const tests: NavItem = {
            label: "Tests",
            fontAwesomeName: "check",
            color: "#38b70e",
            path: "/dev/test",
            disabled: false,
            visible: true
        };


        return {
            label: "Developer",
            fontAwesomeName: "code",
            color: "#2f688d",
            disabled: false,
            visible: true,
            items: [logs, debugs, tests]
        }
    };

    const adminMenuOptions = (): NavItem => {


        let adminMenu: NavItem = {
            label: "Administration",
            fontAwesomeName: "list-check",
            color: "#2f688d",
            visible: true,
            disabled: false,
            path: "",
            items: []
        };

        const userManageItem: NavItem = {
            fontAwesomeName: "lock",
            color: "#2f688d",
            label: "Accès",
            path: "/admin/users",
            visible: true,
            disabled: false
        };

        const userInviteManageItem: NavItem = {
            fontAwesomeName: "envelope",
            color: "#2f688d",
            label: v["invited"],
            path: "/admin/invitations",
            visible: true,
            disabled: false
        };

        const groupManageItem: NavItem = {
            fontAwesomeName: "user-group",
            color: "#2f688d",
            label: v["menuGroups"],
            path: "/admin/groups",
            visible: true,
            disabled: false
        };

        const categoryManage: NavItem = {
            label: v["menuCategories"],
            fontAwesomeName: "filter",
            color: "#2f688d",
            path: "/admin/categories",
            disabled: false,
            visible: true
        };
        const vManage: NavItem = {
            label: v["menuVocabulary"],
            fontAwesomeName: "file-lines",
            color: "#2f688d",
            path: "/admin/vocabulary",
            disabled: false,
            visible: true
        };

        if (HasPrivilege(person.role, Role.Developper)) {
            adminMenu.items.push(userManageItem);
            adminMenu.items.push(userInviteManageItem);
            adminMenu.items.push(groupManageItem);
            adminMenu.items.push(categoryManage);
            adminMenu.items.push(vManage);
        }
        else {
            if (HasPrivilege(person.role, Role.UserManagement)) {
                adminMenu.items.push(userManageItem);
                adminMenu.items.push(userInviteManageItem);
            }
            if (HasPrivilege(person.role, Role.GroupManagement)) {

                adminMenu.items.push(groupManageItem);
            }
            if (HasPrivilege(person.role, Role.CategoryManagement)) {

                adminMenu.items.push(categoryManage);
            }
            if (HasPrivilege(person.role, Role.VocabularyManagement)) {

                adminMenu.items.push(vManage);
            }
        }

        return adminMenu;
    }

    return {
        HomeMenuOptions: homeMenuOptions,
        MyGroupsOptions: myGroupsOptions,
        MyExchangesMenuOptions: myExchangesMenuOptions,
        ModeratorMenuOptions: moderatorMenuOptions,
        DevMenuOptions: devMenuOptions,
        AdminMenuOptions: adminMenuOptions
    };
}